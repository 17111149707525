<template>
  <div class="home">
    <!-- 头部 -->
    <div class="home-head" :class="calssStatus()">
      <div class="home-head-box" v-show="margeShow">
        <div class="home-head-box-left home-head-box-left-margeShow">
          <div class="home-head-box-left-margeShow-title">
            <div>选择需要合并的单据</div>
            <div>
              <i class="el-icon-warning" style=" color:rgb(255, 153, 0)"></i>单据合并后不支持撤销！
            </div>
          </div>
        </div>
        <div class="home-head-box-right home-head-box-right-margeShow">
          <button @click="margeShowS">取消</button>
          <button @click="margeSuccess">确认</button>
        </div>
      </div>
      <div class="home-head-box" v-show="!margeShow">
        <div class="home-head-box-left">
          <div class="home-head-box-left-l">
            <el-select v-model="value" placeholder="请选择" @change="change1">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div class="home-head-box-left-c" v-if="0">
            <el-select v-model="value2" placeholder="请选择" @change="change2">
              <el-option
                v-for="item in options2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div class="home-head-box-left-r" @click="boillShow">
            <span>员工/职称:{{ billData.name ? billData.name : "请选择" }}</span>
            <img
              src="https://static.bokao2o.com/wisdomDesk/images/Def_Icon_ArrowUp.png"
              :class="img_type ? '' : 'home-head-box-left-r-img'"
            />
          </div>
        </div>
        <div class="home-head-box-right">
          <div class="home-head-box-right-search">
            <el-input
              placeholder="手牌号信息查询"
              prefix-icon="el-icon-search"
              v-model="searchValue"
              clearable
              @change="searchs"
              @input="searchs"
              @clear="searchs"
            ></el-input>
          </div>
          <div class="home-head-box-right-search-btnl">
            <el-tooltip
              class="item"
              effect="dark"
              content="快捷键：Alt/Command + Z"
              placement="bottom-end"
            >
              <el-button @click="showKeys"></el-button>
            </el-tooltip>
          </div>
          <div class="home-head-box-right-search-btnc" v-if="0">
            <el-button @click="margeShowS"></el-button>
          </div>
          <div class="home-head-box-right-search-btnr">
            <el-tooltip
              class="item"
              effect="dark"
              content="快捷键：Alt/Command + S"
              placement="bottom-end"
            >
              <el-button @click="billShowS">开单</el-button>
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>
    <!-- 头部 -->

    <!-- 选择单据人员 -->
    <div class="bill" :class="img_type ? 'bill-active' : ''">
      <div class="bill-box">
        <div class="bill-box-menu">
          <div
            class="bill-box-menu-item"
            :class="billIndex == index ? 'bill-box-menu-item-active' : ''"
            v-for="(item, index) in billArr"
            :key="index"
            @click="billTab(index)"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="bill-box-remove">
          <button @click="billRemoves" class="btn-other-s btn-auto">
            重置/查看全部
          </button>
        </div>
        <div class="bill-box-cont" v-if="billArr.length">
          <div v-if="billArr[billIndex].data.length">
            <div
              class="bill-box-cont-item"
              v-for="(item, index) in billArr[billIndex].data"
              :key="index"
              @click="billDataActive(item)"
            >
              <div class="bill-box-cont-item-img">
                <img
                  :src="item.photo || 'https://static.bokao2o.com/images/bg200.png?imageView2/1/w/200/h/200/interlace/1/q/100'"
                  :alt="item.name"
                />
              </div>
              <div class="bill-box-cont-item-info">
                <div class="bill-box-cont-item-info-num">
                  {{ item.name }}
                </div>
                <div class="bill-box-cont-item-info-name">
                  工号:{{ item.workID }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bill-box-cont" v-else>
          <p style="color: #8088a8;font-size: 16px;">该分类下没有员工数据~</p>
        </div>
      </div>
    </div>
    <!-- 选择单据人员 -->

    <!-- 订单列表 -->
    <div class="cont" v-if="!orderArr.length">
      <div style="margin-top: 100px;">
        <img
          style="width: 554px;height: 372px;"
          src="../assets/none-order.png"
          alt=""
        />
        <div style="color: #8088a8;font-size: 16px;">
          暂未有挂单订单，需要努力加油哟~
        </div>
      </div>
    </div>
    <div class="cont" v-if="orderArr.length">
      <div
        class="cont-item"
        v-for="(item, index) in orderArr"
        :key="index"
        @click="car_edit(item)"
      >
        <div class="cont-item-box">
          <div class="cont-item-box-head">
            <!-- 图片 -->
          </div>
          <div class="cont-item-box-del" @click.stop="del_order(item)">
            <i class="el-icon-error" slot="reference"></i>
          </div>
          <div class="cont-item-box-title">
            <div class="cont-item-box-title-text">
              <p v-if="item.member">
                <span>{{ item.member.name }}</span>
                <img style="margin-left:5px;" src="../assets/vip1.png" alt="" />
              </p>
              <p v-else>
                <span>散客</span>
              </p>
              <p>挂单时间:{{ replacesDate(item.date) }}</p>
            </div>
            <div class="cont-item-box-title-code">
              <span>{{ item.code }}</span>
            </div>
          </div>
          <div class="cont-item-box-item">
            <div v-if="item.list.length">
              <div class="cont-item-box-item-list">
                <div class="cont-item-box-item-list-info" v-if="item.list[0]">
                  <p>{{ item.list[0].name }}</p>
                  <p>￥{{ item.list[0].price }}</p>
                </div>
                <div class="cont-item-box-item-list-other" v-if="item.list[0]">
                  <p v-if="!item.list[0].staff.length">暂未选择员工</p>
                  <p v-if="item.list[0].staff.length == 1">
                    {{ selectStaff(item.list[0].staff[0]) }}
                  </p>
                  <p v-if="item.list[0].staff.length > 1">
                    {{ selectStaff(item.list[0].staff[0]) }}等{{ item.list[0].staff.length }}个员工
                  </p>
                  <p>X{{ item.list[0].num }}</p>
                </div>
              </div>
              <div class="cont-item-box-item-list">
                <div class="cont-item-box-item-list-info" v-if="item.list[1]">
                  <p>{{ item.list[1].name }}</p>
                  <p>￥{{ item.list[1].price }}</p>
                </div>
                <div class="cont-item-box-item-list-other" v-if="item.list[1]">
                  <p v-if="!item.list[1].staff.length">暂未选择员工</p>
                  <p v-if="item.list[1].staff.length == 1">
                    {{ selectStaff(item.list[1].staff[0]) || "--" }}
                  </p>
                  <p v-if="item.list[1].staff.length > 1">
                    {{ selectStaff(item.list[1].staff[0]) }}等{{ item.list[1].staff.length }}个员工
                  </p>
                  <p>X{{ item.list[1].num }}</p>
                </div>
                <!-- <div v-else>1111</div> -->
              </div>
              <div
                class="cont-item-box-item-list"
                style="height:27px;margin:0;border:none;"
              >
                <div class="cont-item-box-item-list-info">
                  <p style="color: #8088a8;" v-if="item.list.length > 2">
                    共有{{ item.list.length }}个项目......
                  </p>
                  <p></p>
                </div>
              </div>
            </div>
            <!-- 没有数据占位 -->
            <div v-else>
              <div class="cont-item-box-item-list">
                <div class="cont-item-box-item-list-info">
                  <p></p>
                  <p></p>
                </div>
                <div class="cont-item-box-item-list-other">
                  <p></p>
                  <p></p>
                </div>
              </div>
              <div class="cont-item-box-item-list">
                <div class="cont-item-box-item-list-info">
                  <p></p>
                  <p></p>
                </div>
                <div class="cont-item-box-item-list-other">
                  <p></p>
                  <p></p>
                </div>
              </div>
              <div
                class="cont-item-box-item-list"
                style="height:27px;margin:0;border:none;"
              >
                <div class="cont-item-box-item-list-info">
                  <p style="color: #8088a8;"></p>
                  <p></p>
                </div>
              </div>
            </div>
            <div
              class="cont-item-box-item-list"
              style="height:48px;margin:0;border:none;display: flex;align-items: center"
            >
              <div
                class="cont-item-box-item-list-info"
                style="align-items: center"
              >
                <p style="color: #8088a8;width:45px;">备注:</p>
                <p style="justify-content: flex-start;">{{item.remark || ''}}</p>
              </div>
            </div>
          </div>
          <div class="cont-item-box-bottom">
            <div class="cont-item-box-bottom-num">
              <p>合计：</p>
              <p>￥{{ allPrices(item.list) }}</p>
            </div>
            <!-- <div class="cont-item-box-bottom-btn">
              <div class="cont-item-box-bottom-btn-btn">编辑/结账</div>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <!-- 备用 -->
    <div v-if="0">
      <div class="cont" v-if="orderArr.length">
        <div
          class="cont-item"
          v-for="(item, index) in orderArr"
          :key="index"
          @click="car_edit(item)"
        >
          <div class="cont-item-box">
            <!-- 主单 -->
            <!-- <div
              v-if="margeShow"
              class="cont-item-box-active"
              @click.self="gergeStatus(index)"
            >
              <div
                class="cont-item-box-active-item"
                @click.self="gergeStatus(index)"
                v-if="item.gergrStatus"
              >
                <el-switch
                  v-model="item.gerge"
                  active-color="#13ce66"
                  inactive-color="#DCDFE6"
                  @change="gerges(index)"
                ></el-switch>
              </div>
              <div
                class="cont-item-box-active-item"
                @click.self="gergeStatus(index)"
                v-if="item.gergrStatus"
              >
                主单
                <div class="cont-item-box-active-item-icon">
                  <i
                    class="el-icon-success"
                    style="color:#0fc368;font-size:25px;"
                  ></i>
                </div>
              </div>
            </div> -->
            <!-- 主单 -->
            <div class="cont-item-box-head"></div>
            <div
              class="cont-item-box-status cont-item-box-status-loading"
              v-if="item.status == 1"
            ></div>
            <div
              class="cont-item-box-status cont-item-box-status-success"
              v-else-if="item.status == 2"
            ></div>
            <div
              class="cont-item-box-status cont-item-box-status-loadingPay"
              v-else-if="item.status == 3"
            ></div>
            <div
              class="cont-item-box-status cont-item-box-status-fail"
              v-else
            ></div>
            <div class="cont-item-box-cont">
              <div class="cont-item-box-cont-head">
                <div class="cont-item-box-cont-head-info" v-if="item.member">
                  <div
                    class="cont-item-box-cont-head-info-name"
                    :class="item.member.name ? 'cont-item-box-cont-head-info-name-vip' : ''"
                  >
                    {{ item.member.name ? item.member.name : "散客" }}
                  </div>
                </div>
                <div class="cont-item-box-cont-head-info" v-else>
                  <div class="cont-item-box-cont-head-info-name">
                    <span>散客</span>
                  </div>
                </div>
                <div
                  class="cont-item-box-cont-head-time"
                  style="display: block;"
                >
                  <img
                    src="https://static.bokao2o.com/wisdomDesk/images/Def_Order_Time.png"
                    alt="会员001"
                  />
                  <i class="el-icon-bell"></i>
                  <span style="margin-left: 5px;">{{ item.code }}</span>
                </div>
              </div>
              <div class="cont-item-box-cont-box" v-if="item.list">
                <div class="cont-item-box-cont-box-item">
                  <div class="cont-item-box-cont-box-item-list">
                    <span v-if="item.list[0]">{{ item.list[0].name }}</span>
                  </div>
                  <div
                    class="cont-item-box-cont-box-item-list cont-item-box-cont-box-item-list-color"
                    style="padding:0"
                    v-if="!item.list[0]"
                  ></div>
                  <div
                    class="cont-item-box-cont-box-item-list cont-item-box-cont-box-item-list-color"
                    style="padding:0"
                    v-else
                  >
                    <i class="el-icon-edit-outline"></i>
                    <span
                      v-for="(datas, indexs) in item.list[0].staff"
                      :key="indexs"
                    >
                      {{ selectStaff(datas) }} x {{ item.list[0].num }}<i v-if="indexs != item.list[0].staff.length - 1">、</i>
                    </span>
                  </div>
                  <div
                    class="cont-item-box-cont-box-item-price"
                    v-if="item.list[0]"
                  >
                    ￥{{ (item.list[0].price * item.list[0].num).toFixed(2) }}
                  </div>
                  <div
                    class="cont-item-box-cont-box-item-price"
                    v-if="item.list[0] && !item.list[0].price"
                    style="color:#58D8B9"
                  >
                    品项
                  </div>
                </div>
                <div class="cont-item-box-cont-box-item">
                  <div class="cont-item-box-cont-box-item-list">
                    <span v-if="item.list[1]">{{ item.list[1].name }}</span>
                  </div>
                  <div
                    class="cont-item-box-cont-box-item-list cont-item-box-cont-box-item-list-color"
                    style="padding:0"
                    v-if="!item.list[1]"
                  ></div>
                  <div
                    class="cont-item-box-cont-box-item-list cont-item-box-cont-box-item-list-color"
                    style="padding:0"
                    v-else
                  >
                    <i class="el-icon-edit-outline"></i>
                    <span
                      v-for="(datas, indexs) in item.list[1].staff"
                      :key="indexs"
                    >
                      {{ selectStaff(datas) }} x {{ item.list[1].num }}<i v-if="indexs != item.list[1].staff.length - 1">、</i>
                    </span>
                  </div>
                  <div
                    class="cont-item-box-cont-box-item-price"
                    v-if="item.list[1]"
                  >
                    ￥{{ (item.list[1].price * item.list[1].num).toFixed(2) }}
                  </div>
                  <div
                    class="cont-item-box-cont-box-item-price"
                    v-if="item.list[1] && !item.list[1].price"
                    style="color:#58D8B9"
                  >
                    品项
                  </div>
                </div>
                <div class="cont-item-box-cont-box-item">
                  <div class="cont-item-box-cont-box-item-list">
                    <span v-if="item.list[2]">{{ item.list[2].name }}</span>
                  </div>
                  <div
                    class="cont-item-box-cont-box-item-list cont-item-box-cont-box-item-list-color"
                    style="padding: 0;"
                    v-if="!item.list[2]"
                  ></div>
                  <div
                    class="cont-item-box-cont-box-item-list cont-item-box-cont-box-item-list-color"
                    style="padding: 0;"
                    v-else
                  >
                    <i class="el-icon-edit-outline"></i>
                    <span
                      v-for="(datas, indexs) in item.list[2].staff"
                      :key="indexs"
                    >
                      {{ selectStaff(datas) }} x {{ item.list[2].num }}<i v-if="indexs != item.list[2].staff.length - 1">、</i>
                    </span>
                  </div>
                  <div
                    class="cont-item-box-cont-box-item-price"
                    v-if="item.list[2]"
                  >
                    ￥{{ (item.list[2].price * item.list[2].num).toFixed(2) }}
                  </div>
                  <div
                    class="cont-item-box-cont-box-item-price"
                    v-if="item.list[2] && !item.list[2].price"
                    style="color:#58D8B9"
                  >
                    品项
                  </div>
                </div>
                <div
                  class="cont-item-box-cont-box-last-item "
                  v-if="item.list.length > 3"
                >
                  ...
                </div>
              </div>
              <!-- 没服务项目  避免报错-->
              <div class="cont-item-box-cont-box" v-else>
                <div class="cont-item-box-cont-box-item">
                  <div class="cont-item-box-cont-box-item-list"></div>
                  <div
                    class="cont-item-box-cont-box-item-list cont-item-box-cont-box-item-list-color"
                    style="padding: 0;"
                  ></div>
                  <div
                    class="cont-item-box-cont-box-item-list cont-item-box-cont-box-item-list-color"
                    style="padding: 0;"
                  ></div>
                  <div class="cont-item-box-cont-box-item-price"></div>
                  <div
                    class="cont-item-box-cont-box-item-price"
                    style="color: #58D8B9;"
                  ></div>
                </div>
                <div class="cont-item-box-cont-box-item">
                  <div class="cont-item-box-cont-box-item-list"></div>
                  <div
                    class="cont-item-box-cont-box-item-list cont-item-box-cont-box-item-list-color"
                    style="padding: 0;"
                  ></div>
                  <div
                    class="cont-item-box-cont-box-item-list cont-item-box-cont-box-item-list-color"
                    style="padding: 0;"
                  ></div>
                  <div class="cont-item-box-cont-box-item-price"></div>
                  <div
                    class="cont-item-box-cont-box-item-price"
                    style="color: #58D8B9;"
                  ></div>
                </div>
                <div class="cont-item-box-cont-box-item">
                  <div class="cont-item-box-cont-box-item-list"></div>
                  <div
                    class="cont-item-box-cont-box-item-list cont-item-box-cont-box-item-list-color"
                    style="padding: 0;"
                  ></div>
                  <div
                    class="cont-item-box-cont-box-item-list cont-item-box-cont-box-item-list-color"
                    style="padding: 0;"
                  ></div>
                  <div class="cont-item-box-cont-box-item-price"></div>
                  <div
                    class="cont-item-box-cont-box-item-price"
                    style="color: #58D8B9;"
                  ></div>
                </div>
              </div>
              <!-- 没服务项目  避免报错-->
              <div class="cont-item-box-cont-price">
                <div class="cont-item-box-cont-price-item">合计：</div>
                <div class="cont-item-box-cont-price-item">
                  <span>￥</span>
                  <span>{{ allPrice(item) }}</span>
                </div>
              </div>
              <div class="cont-item-box-cont-other">
                <i class="el-icon-edit-outline"></i>
                <span :style="{ color: item.other ? 'red' : '' }">
                  {{ item.other || "无备注" }}
                </span>
              </div>
              <div class="cont-item-box-cont-time">
                <div class="cont-item-box-cont-time-list">
                  <el-popconfirm
                    title="确定要取消计时吗？"
                    @onConfirm="overTime(index)"
                  >
                    <el-button
                      slot="reference"
                      v-if="item.time_type"
                      size="small"
                    >
                      结束计时
                    </el-button>
                  </el-popconfirm>
                </div>
                <div class="cont-item-box-cont-time-list">
                  <span>挂单时间：</span>
                  <span>{{ item.date }}</span>
                  <timer
                    v-if="0"
                    :timers="item.start_time - 0"
                    :timersEen="item.end_time - 0"
                    :status="item.time_type"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 订单列表 -->

    <!-- 底部状态 -->
    <div class="status" v-if="0">
      <div class="status-left">
        <div
          class="status-left-item"
          v-for="(item, index) in statusArr"
          :key="index"
        >
          <span></span>
          {{ item.name }}({{ item.num > 99 ? "99+" : item.num }})
        </div>
      </div>
      <div class="status-line"></div>
      <div
        class="status-right"
        @click="statusRefresh"
        :class="statusImg ? 'status-right-active' : ''"
      ></div>
    </div>
    <!-- 底部状态 -->

    <!-- 数字键盘 -->
    <keys />
    <!-- 数字键盘 -->

    <!-- 开单 -->
    <bills :key="temKey" />
    <!-- 开单 -->
  </div>
</template>

<script>
import timer from "../components/home/time.vue";
import keys from "../components/home/keys.vue";
import bills from "../components/home/billing.vue";
export default {
  components: { timer, keys, bills },
  data() {
    return {
      temKey: new Date().getTime(),
      options: [
        {
          value: "0",
          label: "全部收银",
        },
        /* {
          value: "1",
          label: "今日收银",
        },
        {
          value: "2",
          label: "昨日收银",
        },
        {
          value: "3",
          label: "前日收银",
        }, */
      ],
      value: "0",
      options2: [
        {
          value: "-1",
          label: "全部",
        },
        {
          value: "1",
          label: "服务中",
        },
        {
          value: "3",
          label: "待结账",
        },
        {
          value: "2",
          label: "已结账",
        },
        {
          value: "0",
          label: "已作废",
        },
      ],
      value2: "-1",
      img_type: false, // 单据人员图片切换
      searchValue: "", // 搜索值
      statusArr: [
        { id: 0, name: "服务中", num: 9999 },
        { id: 1, name: "待结账", num: 0 },
        { id: 2, name: "已结账", num: 0 },
        { id: 3, name: "已作废", num: 0 },
      ], // 底部状态
      statusImg: false, // 底部状态刷新
      billArr: [], // 单据人员菜单
      billIndex: 0, // 单据人员菜单选中下标，默认第一个
      billData: {}, // 选中的单据人员
      orderArr: [], // 订单
      margeShow: false, // 显示合并单据
      margeIndex: "", // 主单下标
      margeArr: [], // 准备合并的订单（当前选中的订单）
      keyShow: false, // 显示数字键盘
    };
  },
  methods: {
    /**
     * 计算价格
     */
    allPrices(obj) {
      if (!obj.length) {
        return "--";
      }
      let num = 0;
      let len = obj.length;
      for (let i = 0; i < len; i++) {
        num += (obj[i].num - 0) * (obj[i].price - 0);
      }
      return num.toFixed(2);
    },
    /**
     * 格式化时间
     */
    replacesDate(obj) {
      if (!obj) {
        return "--";
      } else {
        let dates = obj.split(" ")[1];
        return dates;
      }
    },
    calssStatus() {
      if (this.tabStatuss && this.navStatuss) {
        return "home-head-w";
      }
      if (!this.tabStatuss && this.navStatuss) {
        return "home-head-w home-head-h";
      }

      if (!this.tabStatuss && !this.navStatuss) {
        return "home-head-hs";
      }
    },
    /**
     * 今日收银
     */
    change1(obj) {
      console.log(obj);
      this.value = obj;
    },
    /**
     * 服务状态
     */
    change2(obj) {
      // console.log(this.value2, obj);
      this.value2 = obj;
      this.searchs(this.searchValue);
    },
    /**
     * 搜索
     */
    searchs(obj) {
      // 如果选择了单据人员则按选中的来搜索 
      console.log(obj);
      this.searchValue = obj;
      if (this.billData.name) {
        obj = this.billData.name;
      }
      let data = this.$store.state.order.orderArr;
      let objs = {};
      let len = data.length;
      let arr = [];
      // 状态切换，不存在关键字个开单人员
      if (this.billData.name) {
        for (let i = 0; i < len; i++) {
          for (let k = 0; k < data[i].list.length; k++) {
            for (let l = 0; l < data[i].list[k].staff.length; l++) {
              if (
                this.selectStaff(data[i].list[k].staff[l]) == this.billData.name
              ) {
                arr.push(data[i]);
              }
            }
          }
        }
        this.orderArr = arr.reduce((cur, next) => {
          objs[next.code] ? "" : (objs[next.code] = true && cur.push(next));
          return cur;
        }, []);
        return false;
      }
      // 切换到全部，不存在关键字个开单人员
      if (!obj && !this.billData.name) {
        this.orderArr = data;
        return false;
      }

      // 关键字存在，切换状态
      if (obj - 0 >= 0 && !this.billData.name) {
        for (let i = 0; i < len; i++) {
          if (data[i].member) {
            if (
              data[i].code.includes(obj) ||
              data[i].member.phone.includes(obj)
            ) {
              arr.push(data[i]);
            }
          } else {
            if (data[i].code.includes(obj)) {
              arr.push(data[i]);
            }
          }
        }
        this.orderArr = arr.reduce((cur, next) => {
          objs[next.code] ? "" : (objs[next.code] = true && cur.push(next));
          return cur;
        }, []);
        return false;
      }
      if (obj && !this.billData.name) {
        for (let i = 0; i < len; i++) {
          for (let k = 0; k < data[i].list.length; k++) {
            for (let l = 0; l < data[i].list[k].staff.length; l++) {
              if (this.selectStaff(data[i].list[k].staff[l]).includes(obj)) {
                arr.push(data[i]);
              }
            }
          }
        }
        this.orderArr = arr.reduce((cur, next) => {
          objs[next.code] ? "" : (objs[next.code] = true && cur.push(next));
          return cur;
        }, []);
        return false;
      }
      if (!obj) {
        this.orderArr = data;
      } else {
        this.orderArr = arr.reduce((cur, next) => {
          objs[next.code] ? "" : (objs[next.code] = true && cur.push(next));
          return cur;
        }, []);
      }
    },
    /**
     * 底部刷新
     */
    statusRefresh() {
      let _this = this;
      if (!this.statusImg) {
        this.statusImg = !this.statusImg;
        setTimeout(() => {
          _this.statusImg = !_this.statusImg;
        }, 700);
      }
    },
    /**
     * 显示单据人员
     */
    boillShow() {
      this.img_type = !this.img_type;
    },
    /**
     * 单据人员菜单切换
     */
    billTab(index) {
      this.billIndex = index;
    },
    /**
     * 重置单据人员
     */
    billRemoves() {
      this.boillShow();
      this.billIndex = 0;
      this.billData = {};
      this.searchs();
    },
    /**
     * 选中单据人员
     */
    billDataActive(data) {
      // console.log(data)
      this.billData = data;
      this.boillShow();
      this.searchs();
    },
    /**
     * 结束计时
     */
    overTime(index) {
      console.log(index);
      this.$store.commit("undateTimeStatus", index);
    },
    /**
     * 获取时间格式
     */
    getTime(index) {
      let data = this.$store.state.order.orderArr[index];
      let num = Math.floor(data.start_time - 0 / 1000);
      let t = Math.floor((num / 3600) % 24);
      let m = Math.floor((num / 60) % 60);
      // console.log(this.$store.state.order.orderArr[index],index)
      return `${t < 10 ? "0" + t : t}:${m < 10 ? "0" + m : m}`;
    },
    /**
     * 选中主单
     */
    gerges(index) {
      let d = this.orderArr;
      let len = d.length;
      for (let i = 0; i < len; i++) {
        d[i].gerge = false;
      }
      d[index].gerge = !d[index].gerge;
      this.margeIndex = d[index].gerge ? index : false;
    },
    /**
     * 选择订单
     */
    gergeStatus(index) {
      this.orderArr[index].gergrStatus = !this.orderArr[index].gergrStatus;
      let d = this.orderArr;
      let len = d.length;
      let arr = [];
      for (let i = 0; i < len; i++) {
        if (d[i].gergrStatus) {
          arr.push(d[i]);
        }
      }
      this.margeArr = arr;
    },
    /**
     * 取消显示合拼单 显示合并单据，并去除已支付订单
     */
    margeShowS() {
      this.margeShow = !this.margeShow;
      let len = this.$store.state.order.orderArr.length;
      let data = this.$store.state.order.orderArr;
      let arr = [];
      // 保留服务中和待结账订单，其他的清除
      if (this.margeShow) {
        for (let i = 0; i < len; i++) {
          if (data[i].status !== 2 && data[i].status !== 0) {
            arr.push(data[i]);
          }
        }
      }
      if (!this.margeShow) {
        for (let i = 0; i < len; i++) {
          data[i].gergrStatus = false;
          data[i].gerge = false;
        }
        arr = data;
      }
      this.orderArr = arr;
      console.log(arr, this.margeShow);
    },
    /**
     * 合并主单
     */
    margeSuccess() {
      if (this.margeArr.length < 2) {
        this.$message({
          message: "请选择最少两张需要合并的单据",
          type: "warning",
        });
        return false;
      }
      if (!this.margeIndex) {
        this.$message({
          message: "请指定合并的主单",
          type: "warning",
        });
        return false;
      }
      let dl = this.margeArr;
      let lenl = dl.length;
      let orders1 = "";
      let orders2 = "";
      for (let l = 0; l < lenl; l++) {
        if (dl[l].gerge) {
          orders1 = dl[l].order;
          console.log(dl[l].order);
        } else {
          orders2 += dl[l].order + ",";
        }
      }
      this.$confirm(`确定要将${orders2}合并到${orders1}吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let d = this.$store.state.order.orderArr;
          let len = d.length;
          let d1 = this.margeArr;
          let len1 = d1.length;
          // 计算价格
          for (let i = 0; i < len; i++) {
            if (d[i].gerge) {
              d[i].pirce -= 0;
              for (let k = 0; k < len1; k++) {
                if (d[i].id != d1[k].id) {
                  d[i].data.push.apply(d[i].data, d1[k].data); // 合并data数组
                  d[i].other += d1[k].other + ",";
                  d[i].pirce += d1[k].pirce - 0; // 计算合并的价格
                }
              }
            }
          }
          // 去除合并之后的订单，保留主单
          for (let i = 0; i < len; i++) {
            for (let l = 0; l < len1; l++) {
              if (d[i]) {
                if (d1[l].id == d[i].id) {
                  if (!d1[l].gerge) {
                    d.splice(i, 1);
                  }
                }
              }
            }
          }
          this.$store.state.order.orderArr = d;
          this.margeShowS();
        })
        .catch(() => {
          return false;
        });
    },
    /**
     * 显示数字键盘
     */
    showKeys() {
      let v = !this.$store.state.order.keyShow;
      this.$store.commit("keyShowS", v);
    },
    /**
     * 显示开单
     */
    billShowS() {
      let v = !this.$store.state.order.bill;
      let d = this.$store.state.order.loadorder;
      d.edit = false; // 编辑标识
      this.$store.commit("SetLoadOrder", d);
      this.$store.commit("billShowS", v);
    },
    /**
     * 获取挂单列表
     */
    getLIst() {
      let _this = this;
      this.$axios({
        href: "/api/app/meiye/payment/car_store",
      }).then((res) => {
        console.log('挂单列表：', res);
        let len = res.data.length;
        let d = res.data;
        for (let i = 0; i < len; i++) {
          // d[i].date = d[i].date.split(" ")[1]; //处理时间格式不要年月日
          d[i].list = [];
          // d[i].list = d[i].products.concat(d[i].projects); //合并商品和项目（两个是一样的）
          for (let l = 0; l < d[i].products.length; l++) {
            d[i].products[l].oldpirce = d[i].products[l].price;
            d[i].products[l].type = 3;
            if (d[i].products[l].treat_id) {
              d[i].products[l].type = -1;
            }
            d[i].list.push(d[i].products[l]);
            // console.log(111,_this.selectStaff((d[i].products[l].staff.id)));
          }
          for (let l = 0; l < d[i].projects.length; l++) {
            d[i].projects[l].projects = d[i].projects[l].price;
            d[i].projects[l].type = 2;

            if (d[i].projects[l].treat_id) {
              d[i].projects[l].type = -1;
            }
            d[i].list.push(d[i].projects[l]);
            // console.log(2222);
          }
          /*  for(let l = 0; l < d[i].cards.length;l++){
              d[i].projects[l].type = -1;
              d[i].list.push(d[i].cards[l]);
               console.log(3333)
          } */
        }
        this.orderArr = d;
        // console.log(d,111111111111111111);
        _this.$store.commit("setDataOrder", d);
      });
    },
    /**
     * 获取工种(分类)
     */
    getworktype() {
      let d = this.$store.state.staff;
      let len = d.length;
      let arr = [{ id: 0, name: "全部", sort: 2, data: [] }];
      this.$axios({
        href: "/api/app/meiye/worktype/store",
        loading: true,
      }).then((res) => {
        console.log('工种', res);
        for (let i = 0; i < res.data.length; i++) {
          res.data[i].data = [];
          arr.push(res.data[i]);
          for (let k = 0; k < len; k++) {
            if (i < 1) {
              arr[0].data.push(d[k]);
            }
            if (d[k].worktype_id == res.data[i].id) {
              res.data[i].data.push(d[k]);
            }
          }
        }
        sessionStorage.setItem("Staff", JSON.stringify(res));
      });
      console.log('工种列表', arr);
      this.billArr = arr;
    },
    /**
     * 获取工作人员
     */
    getStaff() {
      let _this = this;
      this.$axios({
        href: "/api/app/meiye/user/store",
        data: { status: 1 },
        loading: true,
      }).then((res) => {
        console.log('工作人员列表：', res);
        _this.$store.commit("setStaff", res.data);
        this.getworktype();
      });
    },
    /**
     * 选中工作人员
     */
    selectStaff(id, type) {
      let d = this.$store.state.staff;
      let len = d.length;
      for (let i = 0; i < len; i++) {
        if (id - 0 == d[i].id - 0) {
          if (type) {
            return d[i];
          } else {
            // console.log(d[i].name)
            return d[i].name;
          }
        }
      }
    },
    /**
     * 计算总价
     */
    allPrice(item) {
      let d = item.list;
      let len = d.length;
      let num = 0;
      for (let i = 0; i < len; i++) {
        num += d[i].num * (d[i].price - 0);
      }
      return num.toFixed(2);
    },
    /**
     * 修改编辑订单
     */
    car_edit(obj) {
      let _this = this;
      let Staff = sessionStorage.getItem("Staff")
        ? JSON.parse(sessionStorage.getItem("Staff")).data
        : [];
      let d = JSON.parse(JSON.stringify(_this.$store.state.order.loadorder));
      let objects = [];
      let datas = obj.list;
      let len = datas.length;

      console.log(obj);

      function getType(index) {
        console.log(Staff, "srfsdfsdfsdfsdf");
        for (let i = 0; i < Staff.length; i++) {
          for (let l = 0; l < Staff[i].data.length; l++) {
            if (Staff[i].data[l].id == index) {
              let obj = {
                id: Staff[i].data[l].id,
                photo: Staff[i].data[l].photo,
                workID: Staff[i].data[l].workID,
                name: Staff[i].data[l].name,
                type: "指定",
              };
              // console.log(_this.billArr[i].data[l].id , index)
              // arrs.push(obj);
              return obj;
            }
          }
        }
        /* console.log(Staff, "srfsdfsdfsdfsdf");
        for (let i = 0; i < Staff.length; i++) {
          for (let l = 0; l < Staff[i].data.length; l++) {
            if (Staff[i].data[l].id == index) {
              let obj = {
                id: Staff[i].data[l].id,
                name: Staff[i].name,
                peopel: Staff[i].data[l].name,
                type: "指定",
              };
              // console.log(_this.billArr[i].data[l].id , index)
              // arrs.push(obj);
              return obj;
            }
          }
        } */
      }
      for (let i = 0; i < len; i++) {
        let arr = {
          Newpirce: "",
          category_id: datas[i].id,
          discount: "",
          id: datas[i].id,
          name: datas[i].name,
          num: datas[i].num,
          oldpirce: datas[i].price,
          other: "",
          people: [],
          picture: datas[i].picture,
          member_price_status: datas[i].member_price_status, //会员价格
          member_price: datas[i].member_price, //会员价格
          pirce: datas[i].price,
          price: datas[i].price,
          type: datas[i].type,
        };
        for (let k = 0; k < datas[i].staff.length; k++) {
          console.log(datas[i].staff[0], 22222222222222222);
          arr.people.push(getType(datas[i].staff[k] - 0));
        }
        objects.push(arr);
      }
      // arr.data = arrs;
      d.id = obj.id;
      d.data = objects;
      d.name = "";
      d.vip = [];
      d.member_id = obj.member_id;
      d.edit = true;

      console.log(d, objects, obj);

      // 同步会员信息
      // console.log(obj)
      if (obj.member) {
        this.$axios({
          href: "/api/app/meiye/member/index",
          data: { id: obj.member.id },
          loading: true,
        }).then((res) => {
          let user = res.data.member;
          user.vip = [];
          res.data.name = res.data.member.name;
          d.name = res.data.member.name;
          for (let i = 0; i < res.data.card.length; i++) {
            for (let k = 0; k < res.data.card[i].treats.length; k++) {
              res.data.card[i].treats[k].type = res.data.card[i].name;
              res.data.card[i].treats[k].vip = true;
              res.data.card[i].treats[k].project =
                res.data.card[i].treats[k].project_id;
              res.data.card[i].treats[k].treat_id =
                res.data.card[i].treats[k].id;
              if (res.data.card[i].treats[k].balance_count - 0) {
                user.vip.push(res.data.card[i].treats[k]);
              }
            }
          }
          for (let l = 0; l < res.data.treat.length; l++) {
            res.data.treat[l].vip = true;
            res.data.treat[l].treat_id = res.data.treat[l].id;
            if (res.data.treat[l].balance_count - 0) {
              user.vip.push(res.data.treat[l]);
            }
          }
          user.membertype = res.data.member.membertype;

          // console.log(res.data.member, 1111111111111111);

          if (user.membertype) {
            user.membertype.product_disc = user.membertype.product_disc / 100;
            user.membertype.project_disc = user.membertype.project_disc / 100;
          } else {
            user.membertype = { id: 0, name: "大众会员" };
            user.membertype.product_disc = 1;
            user.membertype.project_disc = 1;
          }

          /* user.membertype.product_disc = res.data.member.membertype.product_disc / 100;
          user.membertype.project_disc = res.data.member.membertype.project_disc / 100; */
          console.log(user);

          _this.$store.commit("setUser", user);
        });
      }
      console.log(obj, d);

      this.$store.commit("SetLoadOrder", d);
      this.$store.commit("billShowS", true);
    },
    /* 删除订单 */
    del_order(obj) {
      console.log(obj);
      this.$axios({
        href: "/api/app/meiye/payment/car_del",
        data: obj,
      }).then(() => {
        this.$message({
          message: "删除挂单记录成功",
          type: "success",
        });
        this.getLIst();
      });
    },
  },
  mounted() {
    this.getStaff();
    this.$isToken();
    if (!this.$store.state.order.orderArr.length) {
      this.getLIst();
    } else {
      this.orderArr = this.$store.state.order.orderArr;
    }
    let _this = this;
    document.addEventListener("keydown", function(e) {
      // 显示数字键盘
      if (e.keyCode == 90 && (navigator.platform.match("Mac") ? e.metaKey : e.altKey)) {
        e.preventDefault();
        _this.showKeys();
        return false;
      }
      // 显示开单
      if (e.keyCode == 83 && (navigator.platform.match("Mac") ? e.metaKey : e.altKey)) {
        e.preventDefault();
        _this.billShowS();
        return false;
      }
    });
    // 显示开单界面
    if (this.$route.query.type == true || this.$route.query.type == "true") {
      _this.billShowS();
    }
  },
  created() {
    // console.log(this.$route.query.type)
  },
  computed: {
    tabStatuss() {
      return this.$store.state.tabStatus;
    },
    navStatuss() {
      return this.$store.state.navStatus;
    },
    orderArrs() {
      return this.$store.state.order.orderArr;
    },
    /* billShow_flag(){
      return this.$store.state.order.bill;
    } */
  },
  watch: {
    orderArrs(val) {
      console.log('挂单列表内容发生改变');
      // this.getLIst();
      if (val) {
        this.orderArr = val;
      }
    },
  },
};
// a:65 z：90
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100vh;
  // background: #f4f4f4;
  &-head {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 85px;
    padding: 35px 20px 10px 20px;
    background: #fff;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.01);
    box-sizing: border-box;
    transition: all 0.5s;

    &-box {
      display: flex;
      width: 100%;
      height: 100%;

      &-left-margeShow {
        position: relative;
        // display: block;

        &-title {
          div {
            display: flex;
            justify-content: flex-start;
            width: 100%;
          }

          div:first-child {
            align-items: flex-end;
            height: 60%;
            font-size: 18px;
            color: #28282d;
          }

          div:last-child {
            align-items: center;
            height: 40%;
            font-size: 12px;
            color: #5a5a5a;

            i {
              margin-right: 5px;
            }
          }
        }
      }

      &-left {
        display: flex;
        overflow: hidden;
        width: 60%;
        height: 100%;
        color: #28282d;
        // background: brown;

        &-l {
          width: 132px;
          height: 100%;
          margin-right: 25px;
        }

        &-c {
          height: 40px;
          width: 80px;
          margin-right: 25px;
        }

        &-r {
          position: relative;
          height: 40px;
          padding-right: 32px;
          line-height: 40px;
          font-size: 15px;
          cursor: pointer;

          img {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 28px;
            height: 28px;
            margin: auto;
            transition: all 0.5s;
            -webkit-transition: -webkit-transform 0.5s;
            // transform: rotate(0deg);
          }

          &-img {
            transform-origin: center center;
            transform: rotate(180deg);
          }
        }
      }
      &-right-margeShow {
        button {
          width: 110px;
          margin-right: 12px;
          padding: 0;
          background: transparent;
          border: none;
          outline: none;
          box-sizing: border-box;
          cursor: pointer;
        }

        button:first-child {
          height: 40px;
          padding: 0 10px;
          background: #f8f8f8;
          line-height: 40px;
          font-size: 15px;
          color: #5a5a5a;
          border-radius: 5px;
        }

        button:last-child {
          height: 40px;
          padding: 0 10px;
          background: #28282d;
          line-height: 40px;
          font-size: 15px;
          color: #fff;
          border-radius: 5px;
        }
      }

      &-right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 40%;
        height: 100%;
        // padding-right: 2%;
        // background: red;
        text-align: right;
        box-sizing: border-box;

        &-search {
          position: relative;
          display: inline-block;
          width: 240px;
          height: 40px;
          background: #f4f4f4;
          overflow: hidden;

          &-btnl {
            button {
              display: inline-block;
              width: 40px;
              height: 40px;
              margin-left: 15px;
              border: none;
              background: transparent url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Cash.png) center / 26px no-repeat;
            }
          }

          &-btnc {
            button {
              display: inline-block;
              width: 40px;
              height: 40px;
              margin-left: 5px;
              color: #fff;
              border: none;
              background: transparent url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Merge.png) center / 28px no-repeat;
            }
          }

          &-btnr {
            button {
              display: inline-block;
              width: 110px;
              height: 40px;
              margin-left: 20px;
              border: none;
              background: #28282d;
              // line-height: 40px;
              font-family: PingFangSC-Medium;
              font-size: 16px;
              color: #fff;
              vertical-align: top;
              border-radius: 6px;
            }
          }
        }
      }

      @media screen and (max-width: 1667px) {
        &-right {
          padding-right: 2%;
          box-sizing: border-box;
        }
      }

      @media screen and (max-width: 1458px) {
        &-right {
          padding-right: 5%;
          box-sizing: border-box;
        }
      }

      @media screen and (max-width: 1206px) {
        &-right {
          padding-right: 10%;
          box-sizing: border-box;
        }
      }

      @media screen and (max-width: 960px) {
        &-right {
          padding-right: 15%;
          box-sizing: border-box;
        }
      }
    }
  }

  /* &-head-w {
    top: 89px;
    left: 60px !important;
    width: 96%;
    height: 85px;
  }

  &-head-h {
    top: 47px;
  }

  &-head-hs {
    top: 44px;
    width: 88.9%;
  } */

  &-head-w {
    top: 0px;
    left: 0px;
    // top: 89px;
    // left: 60px !important;
    width: 100%;
    height: 85px;
  }

  &-head-h {
    top: 0px;
    // top: 47px;
  }

  &-head-hs {
    top: 0px;
    width: 100%;
  }
}
</style>

<style lang="scss" scoped>
.status {
  position: fixed;
  right: 15px;
  bottom: 30px;
  display: flex;
  overflow: hidden;
  width: 400px;
  height: 32px;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  border-radius: 5px;

  &-left {
    display: flex;
    flex: 1;
    padding-left: 12px;

    &-item {
      flex: 1;
      position: relative;
      padding-left: 5px;
      line-height: 32px;
      font-size: 12px;

      span {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        display: inline-block;
        width: 12px;
        height: 12px;
        margin: auto;
        border-radius: 50%;
      }
    }

    &-item:nth-of-type(1) span {
      background: rgb(237, 179, 57);
    }

    &-item:nth-of-type(2) span {
      background: rgb(244, 78, 78);
    }

    &-item:nth-of-type(3) span {
      background: rgb(71, 191, 124);
    }

    &-item:nth-of-type(4) span {
      background: rgb(221, 221, 221);
    }
  }

  &-line {
    position: absolute;
    top: 6px;
    right: 36px;
    content: "";
    width: 1px;
    height: 20px;
    background: #fff;
  }

  &-right {
    width: 36px;
    height: 32px;
    background: transparent url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Refresh_White.png) center / 26px no-repeat;
  }

  &-right-active {
    transform: rotate(360deg);
    transition: all 0.7s;
  }
}
</style>

<style lang="scss" scoped>
.bill {
  position: absolute;
  top: -10%;
  right: 0;
  left: 0;
  z-index: -1;
  width: auto;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
  opacity: 0;
  transition: all 0.5s;

  &-box {
    position: relative;
    height: auto;
    margin-top: 85px;
    background: #fff;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);

    &-remove {
      position: absolute;
      top: 5px;
      right: 0;
      width: 130px;
      height: 68px;
      // background: rgb(172, 35, 35);
      text-align: center;

      button {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 72px;
        height: 36px;
        margin: auto;
        // border: 1px solid #28282d;
        font-size: 14px;
        border-radius: 5px;
      }

      .el-button {
        padding: 0;
        color: #28282d;
      }
    }

    &-menu {
      position: relative;
      flex: 1;
      overflow-x: auto;
      overflow-y: hidden;
      display: flex;
      height: 68px;
      padding: 14px 130px 0px 24px;
      border-top: 0.5px solid rgba(220, 220, 220, 0.7);
      line-height: 40px;
      white-space: nowrap;

      &-item {
        position: relative;
        min-width: 60px;
        height: 60%;
        padding: 0 15px;
        font-size: 16px;
        color: #28282d;
        text-align: center;
        cursor: pointer;
        box-sizing: border-box;
      }

      &-item-active {
        position: relative;
        color: #4791ff;
        cursor: pointer;
      }

      &-item-active::after {
        position: absolute;
        right: 0;
        bottom: 6px;
        left: 0;
        content: "";
        width: 24px;
        height: 2px;
        margin: auto;
        background: #4791ff;
        border-radius: 1px;
      }
    }

    &-cont {
      overflow-x: hidden;
      overflow-y: auto;
      min-height: 460px;
      max-height: 663px;
      padding: 12px 24px 20px 20px;
      // background: red;
      text-align: left;
      transition: transform 0.7s, height 0.7s, font-size 0.7s;
      -webkit-transition: -webkit-transform 0.7s, height 0.7s, font-size 0.7s;

      &-item {
        display: inline-block;
        overflow: hidden;
        width: 180px;
        height: 247px;
        margin-right: 15px;
        margin-bottom: 15px;
        background: #fff;
        border: 1px solid #f2f2f2;
        border-radius: 3px;
        // box-shadow: 0 0 3px 0#f2f2f2;
        cursor: pointer;
        transition: all 0.5s;

        &-img {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 160px;
          width: 160px;
          margin-top: 10px;
          margin-left: 10px;
          cursor: pointer;

          img {
            width: 100%;
            height: 100%;
          }
        }

        &-info {
          padding: 5px;
          line-height: 20px;
          font-family: DINAlternate-Bold;
          color: #28282d;
          text-align: center;
          cursor: pointer;

          &-num {
            overflow: hidden;
            height: 35px;
            margin-top: 10px;
            font-size: 16px;
            font-weight: Bold;
            color: #000000;
            text-align: left;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          &-name {
            overflow: hidden;
            height: 35px;
            font-size: 14px;
            color: #000000;
            text-align: left;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }

      &-item:hover {
        // margin-top:-2px;
        border: 1px solid #55bfff;
      }
    }
  }
}

.bill-active {
  top: 58px;
  z-index: 9;
  opacity: 1;
}
</style>

<style lang="scss" scoped>
.cont {
  overflow: hidden;
  // width: 100%;
  height: auto;
  margin-top: 85px;
  padding: 10px 0 30px 12px;
  // background: red;

  &-item {
    position: relative;
    float: left;
    width: 320px;
    height: 360px;
    // overflow: hidden;
    // width: 300px;
    // height: 352px;
    // margin: 0 9px 9px 0;
    // padding: 5px;
    // background: #8a8a8a;
    margin: 30px 9px;
    background: #ffffff;
    border: 1px solid #f2f2f2;
    // line-height: 20px;
    font-family: Microsoft YaHei, Microsoft YaHei-Normal;
    font-size: 14px;
    font-weight: Normal;
    color: #777777;
    // text-align: center;
    border-radius: 6px;
    cursor: pointer;
    
    &-box {
      height: 332px;
      // padding: 10px;
      background: #fff;

      &-active {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
        color: #fff;

        &-item {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 50%;

          &-icon {
            position: absolute;
            right: 10px;
            bottom: 10px;
            // z-index: 10;
            width: 40px;
            height: 40px;
            // background: red;
          }
        }

        &-item:first-child {
          align-items: flex-end;
        }

        &-item:last-child {
          position: relative;
          align-items: flex-start;
          padding-top: 10px;
        }
      }

      &-head {
        /* position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 140px;
        height: 17px;
        margin: auto;
        background: transparent url("../assets/jiazi.png") center top / 140px 17px no-repeat; */
        position: absolute;
        top: -25px;
        right: 0;
        left: 0;
        width: 60px;
        height: 40px;
        margin: auto;
        background: transparent url("../assets/jiazi.png") no-repeat;
      }

      &-del {
        position: absolute;
        top: 2px;
        right: -290px;
        left: 0;
        width: 15px;
        height: 15px;
        margin: auto;
        // background: red;
        transition: all 0.5s;
      }

      &-del:hover {
        color: #fff;
      }

      &-title {
        display: flex;
        width: 100%;
        height: 60px;
        padding: 10px 21px;
        background: #8088a8;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        box-sizing: border-box;

        &-text {
          width: 65%;
          height: 100%;

          p {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            height: 50%;
            margin: 0;
            padding: 0;
          }

          p:first-child {
            line-height: 6px;
            font-family: Microsoft YaHei, Microsoft YaHei-Normal;
            font-size: 14px;
            font-weight: Normal;
            color: #ffffff;
            text-align: left;
          }

          p:last-child {
            line-height: 6px;
            font-family: Microsoft YaHei, Microsoft YaHei-Normal;
            font-size: 14px;
            font-weight: Normal;
            color: #dddddd;
            text-align: left;
          }
        }

        &-code {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          width: 35%;
          height: 100%;
          font-family: PingFang SC, PingFang SC-Bold;
          font-size: 26px;
          font-weight: Bold;
          color: #ffcc57;
          letter-spacing: 2px;
        }
      }

      &-item {
        width: 100%;
        height: 240px;
        padding: 10px 21px;
        // background: red;
        box-sizing: border-box;

        &-list {
          width: 100%;
          height: 64px;
          margin-bottom: 12px;
          border-bottom: 1px solid #eee;

          &-info {
            display: flex;
            width: 100%;
            height: 50%;

            p {
              display: flex;
              overflow: hidden;
              width: 50%;
              height: 100%;
              margin: 0;
              padding: 0;
              font-family: Microsoft YaHei, Microsoft YaHei-Normal;
              font-size: 14px;
              color: #333333;
              align-items: center;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            p:first-child {
              justify-content: flex-start;
            }

            p:last-child {
              justify-content: flex-end;
            }
          }

          &-other {
            display: flex;
            width: 100%;
            height: 50%;

            p {
              display: flex;
              align-items: center;
              width: 50%;
              height: 100%;
              margin: 0;
              padding: 0;
              font-family: Microsoft YaHei, Microsoft YaHei-Normal;
              font-size: 14px;
              color: #8088a8;
            }

            p:first-child {
              justify-content: flex-start;
            }

            p:last-child {
              justify-content: flex-end;
            }
          }
        }
      }

      &-bottom {
        display: flex;
        width: 100%;
        height: 60px;
        border-top: 1px solid #eee;
        // background: blue;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;

        &-num {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          padding-left: 21px;
          box-sizing: border-box;

          p {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            height: 30%;
            margin: 0;
            padding: 0;
          }

          p:last-child {
            justify-content: flex-end;
            height: 60%;
            padding-right: 10px;
            font-family: Microsoft YaHei, Microsoft YaHei-Bold;
            font-size: 18px;
            font-weight: Bold;
            color: #6f7799;
            box-sizing: border-box;
          }
        }

        &-btn {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          width: 50%;
          height: 100%;
          padding: 0 10px;
          box-sizing: border-box;

          &-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100px;
            height: 46px;
            border: 1px solid #eee;
            // background: #8088a8;
            font-family: Microsoft YaHei, Microsoft YaHei-Normal;
            font-size: 14px;
            color: #000;
            border-radius: 5px;
          }
        }
      }

      &-status {
        position: absolute;
        top: 22px;
        left: 5px;
        width: 10px;
        height: 20px;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }

      &-status-loading {
        background: rgb(237, 179, 57);
      }

      &-status-success {
        background: rgb(71, 191, 124);
      }

      &-status-loadingPay {
        background: rgb(244, 78, 78);
      }

      &-status-fail {
        background: rgb(221, 221, 221);
      }

      &-cont {
        width: 100%;
        height: 100%;

        &-head {
          display: flex;
          height: 20px;
          margin-top: 7px;
          line-height: 20px;
          // background: red;

          &-info {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 100%;
            height: 100%;

            &-name {
              overflow: hidden;
              width: 120px;
              height: 100%;
              padding-right: 20px;
              // padding-left: 26px;
              background: transparent url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_CustomerNormal.png) left center / 24px no-repeat;
              // background: blue;
              color: #28282d;
              white-space: nowrap;
              box-sizing: border-box;
              text-overflow: ellipsis;
            }

            &-name-vip {
              background: transparent url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_OrderItemVipFlag.png) left center / 24px no-repeat;
            }
          }

          &-time {
            // flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 90px;
            height: 100%;
            // background: palegreen;
            font-family: DINAlternate-Bold;
            font-size: 12px;
            color: #8a8a8a;

            img {
              width: 20px;
              height: 20px;
              // margin-top: -2px;
              // vertical-align: middle;
            }
          }
        }

        &-box {
          position: relative;
          overflow: hidden;
          height: 142px;
          margin-top: 20px;
          border: 0.5px solid #ddd;
          border-radius: 6px;

          &-item:last-child {
            border: none;
          }

          &-last-item {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 18px;
            margin-top: -5px;
            padding-left: 10px;
            border-bottom: none;
            line-height: 10px;
            font-size: 14px;
            color: #28282d;
          }

          &-item {
            display: flex;
            height: 40px;
            border-bottom: 0.5px solid #ddd;

            &-list {
              overflow: hidden;
              width: 40%;
              padding-left: 10px;
              line-height: 40px;
              font-size: 14px;
              color: #28282d;
              text-align: left;
              text-overflow: ellipsis;
              white-space: nowrap;
              box-sizing: border-box;

              div {
                width: 40%;
                // color: brown;
                text-overflow: ellipsis;
                white-space: nowrap;
                box-sizing: border-box;

                span {
                  width: 40%;
                  // color: brown;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  box-sizing: border-box;
                }
              }
            }

            &-list-color {
              color: #8a8a8a;
            }

            &-price-color {
              color: #58d8b9;
            }

            &-price {
              display: flex;
              width: 30%;
              max-width: 66px;
              // min-width: 66px;
              // padding-right: 8px;
              line-height: 40px;
              // background: blueviolet;
              font-size: 12px;
              color: #28282d;
              text-align: right;
            }
          }
        }

        &-box::after {
          position: absolute;
          top: 0;
          right: 66px;
          content: "";
          width: 0.5px;
          height: 142px;
          background: #ddd;
        }

        &-price {
          position: relative;
          display: flex;
          height: 37px;
          padding-bottom: 7px;
          line-height: 30px;
          font-size: 13px;

          &-item {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
          }

          &-item:first-child {
            justify-content: flex-start;
            color: #8a8a8a;
          }

          &-item:last-child {
            justify-content: flex-end;
            font-family: DINAlternate-Bold;
            color: #28282d;

            span:first-child {
              margin-top: 5px;
            }

            span:last-child {
              font-size: 20px;
            }
          }
        }

        &-other {
          overflow: hidden;
          height: 36px;
          margin-top: -3px;
          padding-right: 10px;
          padding-left: 10px;
          background: #f4f4f4;
          line-height: 36px;
          font-size: 13px;
          color: #8a8a8a;
          text-align: left;
          white-space: nowrap;
          border-radius: 5px;
          text-overflow: ellipsis;
          cursor: pointer;
        }

        &-time {
          display: flex;
          overflow: hidden;
          height: 28px;
          margin-top: 12px;

          &-list {
            display: flex;
            align-items: center;
            height: 100%;

            button {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 62px;
              height: 28px;
              border: 0.5px solid #ddd;
              // line-height: 28px;
              font-family: PingFangSC-Medium;
              font-size: 12px;
              color: #28282d;
              // text-align: center;
              border-radius: 3px;
            }
          }

          &-list:first-child {
            justify-content: flex-start;
            width: 25%;
          }

          &-list:last-child {
            justify-content: flex-end;
            width: 75%;
            line-height: 28px;
            font-size: 12px;
            color: #8a8a8a;

            span:last-child {
              font-weight: 800;
            }
          }
        }
      }
    }
  }
}
</style>

<style>
.home-head-box-left-l .el-input__inner {
  width: 100%;
  padding: 0 15px 0 0;
  border: none;
  line-height: 40px;
  font-family: PingFangSC-Semibold;
  font-size: 24px;
  color: #28282d !important;
  text-align: left;
  -webkit-user-select: none;
}

.home-head-box-left-l .el-select .el-input .el-select__caret {
  font-size: 20px;
  color: #28282d;
}

.home-head-box-left-c .el-input__inner {
  width: 80px;
  padding: 0 15px 0 0;
  border: none;
  line-height: 40px;
  font-size: 15px;
  color: #28282d;
  text-align: left;
  -webkit-user-select: none;
}

.home-head-box-left-c .el-select .el-input .el-select__caret {
  font-size: 14.5px;
  font-weight: 800;
  color: #28282d;
}
</style>

<style lang="scss" scoped>
@media screen and (max-width: 1200px) {
  .home-head {
    // left:190px;
    height: 150px;
    transition: all 0.5s;
  }

  .home-head-box {
    display: block;
    height: 50%;
  }

  .home-head-box-left {
    width: 100%;
  }

  .home-head-box-right {
    width: 100%;
  }

  .cont {
    margin-top: 160px;
  }

  .bill-active {
    top: 58px;
  }

  .bill-box {
    margin-top: 92px;
  }
}

@media screen and (min-width: 1200px) {
  .bill-active {
    top: -7px;
  }

  .bill-box {
    margin-top: 92px;
  }
}

@media screen and (max-width: 990px) {
  /* .home-head {
    left:180px;
  } */
}
</style>

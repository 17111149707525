<template>
  <div class="keys">
    <!-- 数字键盘 -->
    <el-dialog
      title="快捷收款"
      :visible.sync="ShowAlert"
      @close="hide"
      :close-on-click-modal="false"
    >
      <div class="keys-box">
        <div class="keys-box-input">
          <div
            class="keys-box-input-item"
            @click="active = 1"
            :class="active == 1 ? 'keys-box-input-active' : ''"
          >
            <span :style="{ color: value.length ? 'color' : '' }">￥</span>
            <span v-show="!value.length">请输入收款金额</span>
            <div v-show="value.length" class="keys-box-input-item-val">
              {{ value }}
            </div>
          </div>
          <div
            class="keys-box-input-item"
            @click="active = 2"
            :class="active == 2 ? 'keys-box-input-active' : ''"
          >
            <span></span>
            <span v-show="!value1.length">
              扫描付款码或手输付款码按回车键收款
            </span>
            <div v-show="value1.length" class="keys-box-input-item-val">
              {{ value1 }}
            </div>
          </div>
        </div>

        <div class="key">
          <div class="key-left">
            <div
              class="key-left-item"
              :class="indexs == index ? 'key-left-item-active' : ''"
              @click="nums(item, index)"
              v-for="(item, index) in keyData"
              :key="index"
            >
              <img
                src="https://static.bokao2o.com/wisdomDesk/images/PriceKey_Del.png"
                alt=""
                v-if="index > 10"
              />
              <span v-else>{{ item }}</span>
            </div>
            <div
              class="key-left-item"
              :class="indexs == 11 ? 'key-left-item-active' : ''"
              @click="delNum(11)"
            >
              <img
                src="https://static.bokao2o.com/wisdomDesk/images/PriceKey_Del.png"
                alt=""
              />
            </div>
          </div>
          <div class="key-right">
            <button class="key-right" @click="removes">重输</button>
            <button class="key-right" @click="pay">收款</button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "keys",
  data() {
    return {
      Show: false,
      keyData: ["1", "2", "3", "4", "5", "6", "7", "8", "9", ".", "0"],
      value: "",
      value1: "",
      active: 0,
      indexs: -1,
    };
  },
  methods: {
    hide() {
      this.$store.commit("keyShowS", false);
      this.value = "";
      this.value1 = "";
    },
    nums(val, i) {
      this.indexs = i;
      let index = this.active;
      /* 收款金额 */
      if (index == 1) {
        /* 第一位不能为零或点 */
        // if (!this.value.length) {
        //   if (val != "0" || val != ".") {
        //     this.value += val;
        //   }
        //   return false;
        // }

        if (!this.value.length) {
          if (val == ".") {
            return false;
          }

          this.value += val;
          // this.discount();
          return false;
        } else {
          if (
           this.value[0] == "0" &&
            val != "." &&
            this.value.length < 2
          ) {
            this.value = val;
            return false;
          }
        }
        /* 只允许存在一个点 */
        if (this.value.length) {
          if (this.value.includes(".") && val == ".") {
            return false;
          }

          if (
            this.value[0] == "." &&
            val == "." &&
            this.value.length == 0
          ) {
            return false;
          }
          if (
            this.value[0] == "0" &&
            this.value.length == 1
          ) {
            this.value += val;
            return false;
          }

          this.value += val;
          /* 最多支持20位 */
          if (this.value - 0 >= 99999) {
            this.$message({
              message: "最大不能超过99999~",
              type: "warning",
            });
            this.value = "99999";
            return false;
          }
          if (this.value.length >= 20) {
            this.$message({
              message: "最大不能超过20个数字哦~",
              type: "warning",
            });
            this.value = (this.value-0).toFixed(2)
            return false;
          }
          return false;
        }
      }

      /* 微信收款码 */
      if (index == 2) {
        if (val != ".") {
          if (this.value1.length < 32) {
            this.value1 += val;
          }
        }
      }
    },
    removes() {
      /* 清空 */
      if (this.active == 1) {
        this.value = "";
        return false;
      }
      if (this.active == 2) {
        this.value1 = "";
        return false;
      }
    },
    delNum(index) {
      /* 退格 */
      this.indexs = index;
      console.log(this.index);
      if (this.active == 1) {
        this.value = this.value.substring(0, this.value.length - 1);
        // console.log(2222)
        return false;
      }
      if (this.active == 2) {
        this.value1 = this.value1.substring(0, this.value1.length - 1);
        return false;
      }
    },

    pay() {
      if (!this.value) {
        this.$message({
          message: "请输入收款金额",
          type: "warning",
        });
        return false;
      }
      if (!this.value1) {
        this.$message({
          message: "请输入收款金额付款码或付款码",
          type: "warning",
        });
        return false;
      }
      
      this.$message({
        message: "收款成功~",
        type: "success",
      });
      this.hide();
    },
  },
  computed: {
    ShowAlert: {
      get() {
        return this.$store.state.order.keyShow;
      },
      set() {
        return this.$store.state.order.keyShow;
      },
    },
  },
  created() {
    let _this = this;
    document.addEventListener("keydown", function(e) {
      /* 回车支付 */
      if (_this.$store.state.order.keyShow && e.keyCode == 13) {
        e.preventDefault();
        _this.pay();
      }
      /* 退格键 */
      if (_this.$store.state.order.keyShow && e.keyCode == 8) {
        e.preventDefault();
        _this.delNum();
      }

      /* tab切换输入框 */
      if (_this.$store.state.order.keyShow && e.keyCode == 9) {
        e.preventDefault();
        if (_this.active == 0 || _this.active == 2) {
          _this.active = 1;
        } else {
          _this.active = 2;
        }
      }

      /* 手动输入 */
      if (_this.$store.state.order.keyShow && ((e.key - 0 > -1) || e.key == ".")) {
        // console.log(1111)
        _this.nums(e.key);
        e.preventDefault();
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.el-dialog {
  top: 8%;
  width: 620px;
  max-height: 80%;
  border-radius: 6px;
  background: rgb(255, 255, 255);
  overflow: hidden;
  box-shadow: rgb(51, 51, 51) 0px 0px 20px 0px;
}

.keys-box {
  position: relative;
  left: 0;
  right: 0;
  margin: auto;
  width: 500px;
  height: 50vh;
  padding: 30px 0 25px 0;
  &-input-active {
    border: 1px solid #47bf7c !important;
    box-shadow: 0 0 3px #47bf7c !important;
  }
  &-input {
    position: relative;
    left: 0;
    right: 0;
    margin: auto;
    width: 410px;
    // padding: 30px 0 25px 0;
    &-item {
      width: 100%;
      height: 68px;
      border: 1px solid rgba(220, 220, 220, 0.7);
      box-shadow: 0 0 3px rgba(220, 220, 220, 0.7);
      border-radius: 6px;
      margin-bottom: 15px;
      padding: 14px 12px;
      box-sizing: border-box;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      span {
        font-family: PingFangSC-Medium;
        color: #8a8a8a;
      }
      span:first-child {
        font-size: 20px;
      }
      span:last-child {
        font-size: 15px;
        margin-left: 5px;
      }
      &-val {
        font-size: 20px;
        font-family: PingFangSC-Medium;
        color: #28282d;
      }
    }
  }
}

.key {
  position: relative;
  margin: 5px auto auto auto;
  display: flex;
  width: 462px;
  height: 320px;
  padding-bottom: 25px;
  //   background: red;
  &-left {
    flex: 1;
    overflow: hidden;
    text-align: center;
    padding: 20px 7px 20px 20px;
    // background: coral;
    &-item-active {
      background: #f4f4f4 !important;
    }
    &-item {
      position: relative;
      float: left;
      cursor: pointer;
      width: 92px;
      height: 58px;
      overflow: hidden;
      border-radius: 6px;
      box-shadow: 0 0 3px rgba(220, 220, 220, 0.568);
      margin-right: 12px;
      margin-bottom: 12px;
      z-index: 10;
      background: #ffffff;
      color: #000;
      left: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: PingFangSC-Medium;
      font-size: 40px;
      img {
        height: 60%;
        width: 45%;
      }
    }
  }
  &-right {
    width: 97px;
    padding: 20px 20px 0 5px;
    // background: darkblue;
    button {
      position: relative;
      width: 72px;
      height: 128px;
      overflow: hidden;
      border-radius: 6px;
      box-shadow: 0 0 3px rgba(220, 220, 220, 0.7);
      font-size: 18px;
      padding: 0;
      color: #28282d;
      margin-bottom: 12px;
      // background: transparent;
      border: none;
      outline: none;
      box-sizing: border-box;
      cursor: pointer;
      padding: 0;
    }
    button:first-child {
      background: #ffffff;
      color: #28282d;
    }
    button:last-child {
      background: #28282d;
      color: #fff;
    }
  }
}
</style>

<style>
.keys .el-dialog {
  /* top: 8%; */
  width: 620px;
  max-height: 80%;
  border-radius: 6px;
  background: rgb(255, 255, 255);
  overflow: hidden;
  box-shadow: rgb(51, 51, 51) 0px 0px 20px 0px;
}
</style>

<template>
  <div class="keys">
    <el-dialog
      title="备注"
      :visible.sync="ShowAlert"
      :append-to-body="ShowAlert"
      @close="hide"
      :close-on-click-modal="false"
    >
      <div class="keys-box">
        <div class="keys-box-type">
          <button
            class="keys-box-type-btn"
            :class="indexs == 0 ? 'keys-box-btns' : ''"
            @click="btns(0)"
          >
            新客
          </button>
          <button
            class="keys-box-type-btn"
            :class="indexs == 1 ? 'keys-box-btns' : ''"
            @click="btns(1)"
          >
            老客
          </button>
        </div>
        <div class="keys-box-types">
          <div class="keys-box-types-list">
            <span>客户来源:</span>
            <el-select
              v-model="value"
              placeholder="请选择"
              style="width:70%"
              @change="changes"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="keys-box-types-list">
            <button
              class="keys-box-types-btn"
              :class="index1 == 2 ? 'keys-box-btns' : ''"
              @click="btns(2)"
            >
              不计客数
            </button>
          </div>
        </div>
        <div class="keys-box-input">
          <el-input
            type="textarea"
            :rows="7"
            placeholder="请输入内容"
            v-model="textarea"
            @input="inputs"
          >
          </el-input>
        </div>
        <div class="keys-box-btn"><button @click="hide">保存</button></div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "others",
  props: ["orderDatasIndex"],
  data() {
    return {
      options: [
        {
          value: "0",
          label: "默认",
        },
        {
          value: "1",
          label: "朋友介绍",
        },
        {
          value: "2",
          label: "广告传单",
        },
        {
          value: "3",
          label: "网上",
        },
        {
          value: "4",
          label: "口碑",
        },
      ],
      value: "默认",
      textarea: "",
      indexs: -1,
      index1: 0,
      status: false,
    };
  },
  methods: {
    hide() {
      this.$store.commit("othersStatus", false);
      let d = this.getOrderData;
      this.$store.commit("SetLoadOrder", d);
      this.status = false;
    },
    btns(i) {
      this.status = true;
      let d = this.getOrderData;
      if (i < 2) {
        this.indexs = i;
        d.customer = i;
      }
      if (i == 2) {
        this.index1 = this.index1 == 2 ? 0 : i;
        d.customerNum = i == 2 ? 1 : 0;
      }
    },
    changes(val) {
      this.status = true;
      let d = this.getOrderData;
      d.source = val;
    },
    inputs(val) {
      this.status = true;
      let d = this.getOrderData;
      d.other = val;
    },
  },
  computed: {
    ShowAlert: {
      get() {
        return this.$store.state.order.others;
      },
      set() {
        return this.$store.state.order.others;
      },
    },
    getOrderData: {
      get() {
        return this.$store.state.order.loadorder;
      },
      set() {
        return this.$store.state.order.loadorder;
      },
    },
  },
  updated() {
    if (!this.status) {
      let d = this.getOrderData;
      let i = d.source - 0;
      this.value = this.options[i].label;
      this.textarea = d.other;
      this.indexs = d.customer - 0;
      this.index1 = d.customerNum - 0 ? 2 : 0;
      console.log(1111);
    }
  },
};
</script>

<style lang="scss" scoped>
.key {
  position: relative;
  margin: 5px auto auto auto;
  display: flex;
  width: 450px;
  min-height: 320px;
  padding-bottom: 25px;
}

.keys-box {
  position: relative;
  left: 0;
  right: 0;
  margin: auto;
  width: 500px;
  height: 30vh;
  padding: 0px 0 25px 0;
  &-type {
    padding: 0 30px;
    &-btn {
      width: 80px;
      height: 40px;
      padding-left: 30px;
      font-size: 14px;
      color: #28282d;
      text-align: left;
      background: #fff
        url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Select_N.png)
        0 center / 24px no-repeat;
    }
  }
  &-types {
    height: 52px;
    padding: 0 30px 12px 30px;
    font-size: 14px;
    line-height: 40px;
    display: flex;
    &-btn {
      width: 100px;
      height: 40px;
      padding-left: 30px;
      font-size: 14px;
      color: #28282d;
      text-align: left;
      background: #fff
        url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Select_N.png)
        0 center / 24px no-repeat;
    }
    &-list {
      width: 30%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      // background: red;
    }
    &-list:first-child {
      width: 70%;
      height: 100%;
      display: center;
      align-items: flex-end;
      justify-content: flex-start;
      span {
        margin-right: 10px;
      }
      // background: red;
    }
  }
  &-input {
    padding: 0 30px;
  }
  &-btn {
    padding: 20px;
    text-align: center;
    button {
      width: 100px;
      line-height: 44px;
      height: 44px;
      background: #28282d;
      font-size: 16px;
      color: #fff;
      border-radius: 6px;
      border: none;
      outline: none;
      box-sizing: border-box;
      cursor: pointer;
      padding: 0;
    }
  }
  button {
    border: none;
    outline: none;
    box-sizing: border-box;
    cursor: pointer;
    // padding: 0;
  }
  &-btns {
    background: #fff
      url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Select_S.png) 0
      center / 24px no-repeat;
  }
}
</style>

<style  module>
.el-dialog {
  top: 8%;
  width: 550px !important;
  max-height: 80%;
  border-radius: 6px !important;
  background: rgb(255, 255, 255);
  overflow: hidden;
  box-shadow: rgb(51, 51, 51) 0px 0px 20px 0px !important;
  /* transition: all 1s !important; */
}
/* .el-dialog {
  top: 8%;
  width: 620px;
  max-height: 80%;
  border-radius: 6px;
  background: rgb(255, 255, 255);
  overflow: hidden;
  box-shadow: rgb(51, 51, 51) 0px 0px 20px 0px;
} */
</style>

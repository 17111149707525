<template>
  <div class="pays">
    <div class="pays-box" :class="shows ? 'pays-box-active' : ''">
      <div class="pays-box-head" :style="{ display: shows ? 'flex' : 'none' }">
        <div class="pays-box-head-back">
          <button @click="hide"></button>
        </div>
        <div class="pays-box-head-title">支付</div>
      </div>

      <div
        class="pays-box-info"
        :class="userInfo ? 'pays-box-info-active' : ''"
        v-if="users.name"
      >
        <div class="pays-box-info-head" v-if="users">
          <div class="pays-box-info-head-name">
            <el-tooltip
              class="item"
              effect="dark"
              :content="users.membertype.name"
              placement="top-end"
            >
              <span>{{ users.name }}</span>
            </el-tooltip>

            <span>{{ users.phone || '--' }}</span>
            <button @click="userInfo = !userInfo">收起</button>
          </div>
          <div class="pays-box-info-head-other">
            <el-input
              type="textarea"
              placeholder="请输入内容"
              v-model="users.remark"
              maxlength="200"
              show-word-limit
            >
            </el-input>
          </div>
          <div class="pays-box-info-head-btn">
            <button @click="psyOrder">交易历史</button>
            <button @click="payShow">充值</button>
          </div>
        </div>
        <div class="pays-box-info-text" v-if="users">
          <div class="pays-box-info-text-title">账户</div>
          <div class="pays-box-info-text-text">
            <span>储值账户</span>
            <span>余额：￥{{ users.amount }}</span>
          </div>
        </div>
        <div class="pays-box-info-text" v-if="users">
          <div class="pays-box-info-text-title">品项</div>
          <div v-if="users.card[0]">
            <div v-if="users.card[0].source_obj">
              <div
                class="pays-box-info-text-text"
                v-for="(item, index) in users.card[0].source_obj.products"
                :key="index"
              >
                <span>{{ item.name }}</span>
                <span>剩余：{{ item.num }} 次</span>
              </div>
            </div>
            <div v-if="users.card[0].source_obj">
              <div
                class="pays-box-info-text-text"
                v-for="(item, index) in users.card[0].source_obj.projects[0]"
                :key="index"
              >
                <span>{{ item.name }}</span>
                <span>剩余：{{ item.num }} 次</span>
              </div>
            </div>
            <div v-if="users.card[0].treats">
              <div
                class="pays-box-info-text-text"
                v-for="(item, index) in users.card[0].treats"
                :key="index"
              >
                <span>{{ item.name }}({{ item.type }})</span>
                <span>剩余：{{ item.balance_count }} 次</span>
              </div>
            </div>
          </div>
          <div v-if="users.vip">
            <div
              class="pays-box-info-text-text"
              v-for="(item, index) in users.vip"
              :key="index"
            >
              <span>{{ item.name }}</span>
              <span>剩余：{{ item.balance_count }} 次</span>
            </div>
          </div>
        </div>
      </div>

      <div
        class="pays-box-cont-box"
        :style="{ paddingLeft: !userInfo ? '0' : '320px' }"
      >
        <div class="pays-box-cont">
          <div class="pays-box-cont-title">订单流水号：</div>
          <div
            class="pays-box-cont-info-right"
            style="width:100%;padding:0 15px 0 15px;box-sizing: border-box;"
          >
            <el-input
              @input="inputs"
              placeholder="请输入订单流水号..."
              :value="code"
            >
            </el-input>
          </div>
          <div class="pays-box-cont-title">消费明细</div>
          <div class="pays-box-cont-list">
            <div
              class="pays-box-cont-list-item"
              v-for="(item, index) in goods.list"
              :key="index"
            >
              <button
                class="pays-box-cont-list-item-button"
                :class="item.status ? 'btn-active1' : ''"
                @click="actives(index, 0)"
              ></button>
              <div class=" pays-box-cont-list-item-img">
                <img :src="item.picture" :alt="item.name" v-if="item.picture" />
                <img
                  src="../../assets/none.png"
                  :alt="item.name"
                  v-if="!item.picture"
                />
              </div>
              <div class="pays-box-cont-list-item-text" style="display:block;">
                <p class="pays-box-cont-list-item-text-p" :title="item.name">
                  {{ item.name }}
                </p>
                <p
                  class="pays-box-cont-list-item-text-p"
                  v-if="!item.staff.length"
                >
                  员工：--
                </p>
                <p
                  class="pays-box-cont-list-item-text-p"
                  v-if="item.staff.length == 1"
                >
                  员工：{{ selectStaff(item.staff) }}
                </p>
                <p
                  class="pays-box-cont-list-item-text-p"
                  v-if="item.staff.length > 1"
                >
                  包含{{ selectStaff(item.staff) }}等{{
                    item.staff.length
                  }}个员工
                </p>
              </div>
              <div class="pays-box-cont-list-item-text">x {{ item.num }}</div>

              <div
                class="pays-box-cont-list-item-text"
                style="flex: 2;"
                @click="showUpdatePrice(index, item)"
              >
                <!-- 修改价格之前显示的折扣价格 -->
                <div v-if="users.name && !item.member_price_status">
                  <div v-if="item.pirce == item.price">
                    <span
                      v-if="
                        users.membertype.product_disc &&
                          item.pirce &&
                          item.type > 0
                      "
                      style="font-size: 12px;color: #ccc;text-decoration: line-through;"
                      >￥{{ (item.num * item.pirce).toFixed(2) }}</span
                    >
                    <span v-if="item.pirce - 0 && item.type == 3"
                      >￥{{
                        (
                          item.num *
                          item.pirce *
                          users.membertype.product_disc
                        ).toFixed(2)
                      }}</span
                    >

                    <span v-if="item.pirce - 0 && item.type == 2"
                      >￥{{
                        (
                          item.num *
                          item.pirce *
                          users.membertype.project_disc
                        ).toFixed(2)
                      }}</span
                    >
                    <i
                      class="el-icon-edit"
                      v-if="item.pirce - 0 && item.type != -1"
                    ></i>
                    <span
                      v-if="
                        users.membertype.product_disc!=1 &&
                          item.pirce &&
                          item.type == 3
                      "
                      style="margin-left: 5px;line-height: 16px;color: #fff;font-size: 14px;padding: 2px 6px;border-radius: 3px;background: #f57665;"
                    >
                      {{ users.membertype.product_disc * 100 }} 折
                    </span>
                    <span
                      v-if="
                        users.membertype.project_disc!=1 &&
                          item.pirce &&
                          item.type == 2
                      "
                      style="margin-left: 5px;line-height: 16px;color: #fff;font-size: 14px;padding: 2px 6px;border-radius: 3px;background:#f57665;"
                    >
                      {{ users.membertype.project_disc * 100 }} 折
                    </span>
                  </div>
                  <!-- 修改价格之前显示的折扣价格 -->
                  <!-- 修改价格之后折扣 -->
                  <div
                    style="display: inline-block;"
                    v-if="item.pirce != item.price"
                  >
                    <span
                      style="font-size: 12px;color: #ccc;text-decoration: line-through;"
                      >￥{{ (item.num * item.price).toFixed(2) }}</span
                    >
                    <span>￥{{ (item.num * item.pirce).toFixed(2) }}</span>

                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="注意：折扣只显示两位小数点"
                      placement="right-start"
                      v-if="item.pirce < item.price"
                    >
                      <span
                        style="margin-left: 5px;line-height: 16px;color: #fff;font-size: 14px;padding: 2px 6px;border-radius: 3px;background:#f57665;"
                      >
                        {{ toFixeds((item.pirce * 100) / item.price, 2) }}折
                      </span>
                    </el-tooltip>
                  </div>
                  <!-- 修改价格之后折扣 -->
                </div>
                <!-- 会员价格 -->
                <div v-if="users.name && item.member_price_status">
                  <!-- 没有修改价格显示 -->
                  <div
                    style="display: inline-block;"
                    v-if="item.pirce == item.price"
                  >
                    <span v-if="item.pirce - 0"
                      >会员价：￥{{ (item.num * item.pirce).toFixed(2) }}</span
                    >
                    <i
                      class="el-icon-edit"
                      v-if="item.pirce - 0 || item.type != -1"
                    ></i>
                  </div>
                  <!-- 没有修改价格显示 -->
                  <!-- 修改价格之后折扣 -->
                  <div
                    style="display: inline-block;"
                    v-if="item.pirce != item.price"
                  >
                    <span
                      style="font-size: 12px;color: #ccc;text-decoration: line-through;"
                      >￥{{ (item.num * item.price).toFixed(2) }}</span
                    >
                    <span>￥{{ (item.num * item.pirce).toFixed(2) }}</span>
                    <i
                      class="el-icon-edit"
                      v-if="item.pirce - 0 || item.type != -1"
                    ></i>

                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="注意：折扣只显示两位小数点"
                      placement="right-start"
                      v-if="item.pirce < item.price"
                    >
                      <span
                        style="margin-left: 5px;line-height: 16px;color: #fff;font-size: 14px;padding: 2px 6px;border-radius: 3px;background:#f57665;"
                      >
                        {{ toFixeds((item.pirce * 100) / item.price, 2) }}折
                      </span>
                    </el-tooltip>
                  </div>
                  <!-- 修改价格之后折扣 -->
                </div>
                <!-- 会员价格 -->
                <div v-if="!users.name">
                  <!-- 没有修改价格显示 -->
                  <div
                    style="display: inline-block;"
                    v-if="item.pirce == item.price"
                  >
                    <span v-if="item.pirce - 0"
                      >￥{{ (item.num * item.pirce).toFixed(2) }}</span
                    >
                    <i
                      class="el-icon-edit"
                      v-if="item.pirce - 0 || item.type != -1"
                    ></i>
                  </div>
                  <!-- 没有修改价格显示 -->
                  <!-- 修改价格之后折扣 -->
                  <div
                    style="display: inline-block;"
                    v-if="item.pirce != item.price"
                  >
                    <span
                      style="font-size: 12px;color: #ccc;text-decoration: line-through;"
                      >￥{{ (item.num * item.price).toFixed(2) }}</span
                    >
                    <span>￥{{ (item.num * item.pirce).toFixed(2) }}</span>
                    <i
                      class="el-icon-edit"
                      v-if="item.pirce - 0 || item.type != -1"
                    ></i>

                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="注意：折扣只显示两位小数点"
                      placement="right-start"
                      v-if="item.pirce < item.price"
                    >
                      <span
                        style="margin-left: 5px;line-height: 16px;color: #fff;font-size: 14px;padding: 2px 6px;border-radius: 3px;background:#f57665;"
                      >
                        {{ toFixeds((item.pirce * 100) / item.price, 2) }}折
                      </span>
                    </el-tooltip>
                  </div>
                  <!-- 修改价格之后折扣 -->
                </div>
                <div v-if="!item.pirce - 0 || item.type == -1">
                  <span style="color:#47BF7C;">品项抵扣</span>
                </div>
              </div>
              <div class="pays-box-cont-list-item-text">
                <div class="pays-box-cont-list-item-text-btns" @click="Coupon">
                  优惠券
                </div>
              </div>
              <div
                class="pays-box-cont-list-item-text pays-box-cont-list-item-texts-last"
              >
                <span v-if="payIndex < 0">支付方式</span>
                <span v-if="payIndex >= 0">{{ payArr[payIndex].name }}</span>
              </div>
            </div>
          </div>
          <div class="pays-box-cont-info">
            <div class="pays-box-cont-info-left">
              <button @click="all" :class="alls ? 'btn-active' : ''">
                全选
              </button>
            </div>
            <!-- 修改价格 -->
            <div class="pays-box-cont-info-right">
              <span>应付金额：￥{{ prices[1] }}</span>
              <span>实付金额：￥{{ prices[0] }}</span>
              <span>待付金额：<em>￥{{ prices[0] }}</em></span>
            </div>
          </div>
          <div class="pays-box-cont-other" v-if="0">
            <button>备注</button>
          </div>
        </div>

        <div class="pays-box-type">
          <div class="pays-box-type-title">支付方式:</div>
          <div class="pays-box-type-cont">
            <div
              class="pays-box-type-cont-item"
              @click="selectPay(index)"
              :class="payIndex == index ? 'pays-box-type-cont-item-active' : ''"
              v-for="(item, index) in payArr"
              :key="index"
            >
              <div class="pays-box-type-cont-item-left">
                <div class="pays-box-type-cont-item-left-img" v-if="item.img">
                  <img
                    :src="item.img"
                    :alt="item.name"
                    v-if="payIndex != index"
                  />
                  <img
                    :src="item.activeImg"
                    :alt="item.name"
                    v-if="payIndex == index"
                  />
                </div>
                <div class="pays-box-type-cont-item-left-text" v-else>
                  {{ item.name[0] }}
                </div>
              </div>
              <div class="pays-box-type-cont-item-right">{{ item.name }}</div>
            </div>
          </div>
          <button class="pays-box-type-pay" @click="payOrder">
            结账
          </button>
        </div>
      </div>

      <div
        class="pays-box-userShow"
        :class="!userInfo && users.name ? 'pays-box-userShow-active' : ''"
        @click="userInfo = !userInfo"
      >
        <span>会员信息</span>
        <i class="el-icon-s-unfold"></i>
      </div>
    </div>

    <el-dialog
      title="经理授权验证"
      :visible.sync="dialogFormVisible"
      :append-to-body="dialogFormVisible"
    >
      <el-input placeholder="请输入授权者工号" v-model="input1">
        <template slot="prepend">授权工号</template>
      </el-input>
      <el-input
        placeholder="请输入6位授权密码"
        v-model="input2"
        type="password"
        style="margin-top:10px;"
      >
        <template slot="prepend">授权密码</template>
      </el-input>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="codes">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="请填写优惠券"
      :visible.sync="dialogFormVisible1"
      :append-to-body="dialogFormVisible1"
      center
    >
      <el-tabs type="card">
        <el-tab-pane label="微店券">
          <div class="quan">
            <span>券号：</span>
            <div class="quan-input">
              <el-input placeholder="请输入内容" v-model="input2">
                <template slot="append">查询</template>
              </el-input>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="手工券">
          <div class="quan">
            <span>券号：</span>
            <div class="quan-input">
              <el-input type="number" placeholder="请输入内容" v-model="input2">
              </el-input>
            </div>
          </div>
          <div class="quan">
            <span>金额：</span>
            <div class="quan-input">
              <el-input-number
                placeholder="请输入内容"
                v-model="input2"
                controls-position="right"
                :min="1"
                :max="10"
              ></el-input-number>
            </div>
          </div>
          <div class="quan">
            <span>使用金额：</span>
            <div class="quan-input">
              <!-- <el-input placeholder="请输入内容" v-model="input2"> </el-input> -->
              <el-input-number
                placeholder="请输入内容"
                v-model="input2"
                controls-position="right"
                :min="1"
                :max="10"
              ></el-input-number>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <div class="quan-btn">
        <el-button type="primary">主要按钮</el-button>
      </div>
    </el-dialog>

    <!-- 充值界面 -->
    <el-dialog
      :visible.sync="dialogVisible1"
      :modal-append-to-body="false"
      width="100%"
      class="Pay-box"
    >
      <div class="Pay">
        <div class="Pay-l">
          <div class="Pay-l-item">
            <div class="Pay-l-item-title">
              <span class="Pay-l-item-title-name">储值账户</span>
            </div>

            <div class="Pay-l-item-title">
              <span class="Pay-l-item-title-name">储值</span>
              <div class="Pay-l-item-title-price">
                <el-input-number
                  v-model="newPrice"
                  @change="userPriceNum"
                  :precision="2"
                  :step="0.1"
                  :min="0"
                  label="描述文字"
                ></el-input-number>
              </div>
            </div>
            <div class="Pay-l-item-title">
              <span class="Pay-l-item-title-name">应收</span>
              <div class="Pay-l-item-title-price">
                <el-input-number
                  v-model="oldPrice"
                  @change="userPriceNum"
                  :precision="2"
                  :step="0.1"
                  :min="0"
                  label="描述文字"
                ></el-input-number>
              </div>
            </div>
          </div>
          <div class="Pay-l-item" v-if="0">
            <div class="Pay-l-item-title">
              <span class="Pay-l-item-title-name">套餐</span>
              <span class="Pay-l-item-title-price" v-if="cardListActive.length"
                >共有 {{ cardListActive.length }} 个套餐</span
              >
              <!-- <span class="Pay-l-item-title-price">3</span> -->
            </div>
            <div class="Pay-l-item-list" v-if="cardListActive.length">
              <div
                class="Pay-l-item-list-item"
                v-for="(item, index) in cardListActive"
                :key="index"
              >
                <div>
                  <button @click="cardListActives(item.id)"></button>
                </div>
                <div>{{ item.name }} x {{ item.num }}</div>
                <div>￥{{ (item.price * item.num).toFixed(2) }}</div>
              </div>
            </div>
            <div class="Pay-l-item-add">
              <span @click="card">添加</span>
            </div>
          </div>
          <div class="Pay-l-item">
            <div class="Pay-l-item-title">
              <span class="Pay-l-item-title-name">计次卡</span>
              <span class="Pay-l-item-title-price" v-if="projectsData.length"
                >共有 {{ projectsData.length }} 个次卡</span
              >
              <!-- <span class="Pay-l-item-title-price">3</span> -->
            </div>
            <div class="Pay-l-item-list" v-if="projectsData.length">
              <div
                class="Pay-l-item-list-item"
                v-for="(item, index) in projectsData"
                :key="index"
              >
                <div>
                  <button @click="cardActive(item)"></button>
                </div>
                <div>{{ item.name }} x {{ item.num }}</div>
                <div>￥{{ (item.price * item.num).toFixed(2) }}</div>
              </div>
            </div>
            <div class="Pay-l-item-add">
              <span @click="cardNum">添加</span>
            </div>
          </div>
        </div>
        <div class="Pay-r">
          <div class="Pay-r-title">其他信息</div>
          <div class="Pay-r-box">
            <div class="Pay-r-box-item">
              <div>销售人员</div>
              <div style="background:none;padding:0">
                <el-select
                  style="background:none;padding:0"
                  v-model="worksData"
                  multiple
                  collapse-tags
                  filterable
                  placeholder="请输入关键字..."
                >
                  <el-option
                    v-for="item in works"
                    :key="item.name"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="Pay-r-box-item">
              <div>卡升级</div>
              <div style="background:none;padding:0">
                <el-select
                  v-model="userListData"
                  filterable
                  placeholder="请选择"
                  style="background:none;padding:0"
                >
                  <el-option
                    v-for="item in userList"
                    :key="item.name"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="Pay-r-box-item" v-if="userListData != ''">
              <div>更换卡号</div>
              <div style="background:none;padding:0">
                <el-input
                  style="background:none;padding:0"
                  v-model="number"
                  placeholder="更换卡号，不更换不需要填写。"
                ></el-input>
              </div>
            </div>
            <div class="Pay-r-box-item">
              <div>有效期</div>
              <div>
                <el-date-picker
                  style="background:none;padding:0"
                  v-model="times"
                  type="date"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </div>
            </div>
            <div class="Pay-r-box-item">
              <div>会员备注</div>
              <div style="background:none;padding:0">
                <el-input
                  style="background:none;padding:0"
                  type="textarea"
                  placeholder="请输入会员备注..."
                  v-model="other"
                  maxlength="300"
                  show-word-limit
                >
                </el-input>
              </div>
            </div>
          </div>
          <div class="Pay-footer">
            <div>
              <span>总价：</span>
              <span style="color: #FF5E56;">￥</span>
              <span style="color: #FF5E56;font-size: 20px;">{{
                projectsPrice
              }}</span>
            </div>
            <div @click="payCard">
              <span>支付</span>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 充值界面 -->

    <!-- 购买套餐卡 -->
    <el-dialog
      title="购买套餐卡"
      :visible.sync="dialogVisible"
      :modal-append-to-body="false"
      width="80%"
    >
      <div class="card">
        <div
          class="card-item"
          :style="{ width: cardListActive.length ? '50%' : '100%' }"
        >
          <el-collapse v-model="activeNames">
            <el-collapse-item v-for="(item, index) in cardList" :key="index">
              <template slot="title">
                <div
                  style="  display: flex;align-items: center;justify-content: center;"
                  @click.stop="cardListActives(item.id)"
                >
                  <button
                    class="card-btn"
                    :class="item.status ? 'card-btn-active' : ''"
                  ></button>
                </div>
                <div style="flex:1;text-align:left;padding-left:10px;">
                  {{ item.name }}({{ item.detail }})
                  <span
                    style="flex:2;text-align:right;color: #28282D;font-size: 16px;margin-left:10px;"
                    >￥{{ item.price }}</span
                  >
                </div>
              </template>
              <el-table :data="item.data">
                <el-table-column property="id" label="编号"></el-table-column>
                <el-table-column property="name" label="名称"></el-table-column>
                <el-table-column property="type" label="类型"></el-table-column>
                <el-table-column
                  property="price"
                  label="价格"
                ></el-table-column>
              </el-table>
            </el-collapse-item>
          </el-collapse>
        </div>
        <div
          class="card-item"
          :style="{ width: cardListActive.length ? '50%' : '0%' }"
        >
          <div
            class="card-item-list"
            v-for="(item, index) in cardListActive"
            :key="index"
          >
            <div class="card-item-list-btn">
              <button @click="cardListActives(item.id)"></button>
            </div>
            <div class="card-item-list-name">
              <div>{{ item.name }}</div>
              <div>单价：￥{{ item.price }}</div>
            </div>
            <div class="card-item-list-num">
              <el-input-number
                v-model="item.num"
                :min="1"
                :max="9999"
                label="描述文字"
                style="width:100%"
              ></el-input-number>
            </div>
            <!-- <div class="card-item-list-select">
              <el-select
                v-model="value"
                multiple
                collapse-tags
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div> -->
          </div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="cardSuccess">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 购买套餐卡 -->

    <!-- 计次卡充值 -->
    <el-dialog
      title="计次卡充值"
      :visible.sync="dialogVisible2"
      :modal-append-to-body="false"
      width="1200px"
    >
      <div class="card">
        <div
          class="card-item"
          :style="{ width: projectsData.length ? '50%' : '100%' }"
        >
          <el-tabs :tab-position="'left'" style="height: 100%;">
            <el-tab-pane
              :label="item.name"
              v-for="(item, index) in projects"
              :key="index"
            >
              <el-tabs>
                <el-tab-pane
                  :label="items.name"
                  v-for="(items, indexs) in item.projects"
                  :key="indexs"
                >
                  <div
                    style="display:flex;width:100%;height:60px;display: flex;align-items: center;justify-content: flex-start;cursor: pointer;"
                    @click="cardActive(items)"
                  >
                    <button
                      class="card-btn"
                      :class="items.status ? 'card-btn-active' : ''"
                    ></button>
                    <span style="flex:2">{{ items.name }}</span>

                    <div @click.stop="">
                      <!-- <span>单价：</span> -->
                      <span
                        v-if="items.price < items.newPrice"
                        style="color: rgb(204, 204, 204);text-decoration: line-through;"
                        >￥{{ items.newPrice }}</span
                      >
                      <el-input-number
                        style="width:180px"
                        v-model="items.price"
                        @change="cardActive(items, true)"
                        :precision="2"
                        :step="0.1"
                      ></el-input-number>
                    </div>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div
          class="card-item"
          :style="{ width: projectsData.length ? '50%' : '0%' }"
        >
          <div
            v-for="(item, index) in projectsData"
            :key="index"
            style="display:flex;width:100%;height:60px;display: flex;align-items: center;justify-content: flex-start;"
          >
            <div class="card-item-list-btn" @click="cardActive(item)">
              <button></button>
            </div>
            <span style="flex:2">{{ item.name }} X {{ item.num }}</span>
            <span style="flex:1"
              >￥{{ (item.price * item.num).toFixed(2) }}</span
            >
            <div style="width:180px;">
              <el-input-number
                v-model="item.num"
                @change="cardActive(item, true)"
                :min="1"
                label="描述文字"
              ></el-input-number>
            </div>
          </div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible2 = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 计次卡充值 -->

    <!-- 付款方式 -->
    <el-dialog
      title="支付方式"
      :visible.sync="payShows"
      :modal-append-to-body="false"
      width="80%"
    >
      <div class="pays-box-type" style="height:auto;">
        <!-- <div class="card-item" style="width:50%;margin-right:50px;">
          <div
            v-for="(item, index) in projectsData"
            :key="index"
            style="display:flex;width:100%;height:40px;display: flex;align-items: center;justify-content: flex-start;background:#fff;margin-bottom:10px;padding:10px;"
          >
            <span>{{ item.name }} X {{ item.num }}</span>

            <div style="flex:2">数量：{{ item.num }}</div>
            <span>￥{{ (item.price * item.num).toFixed(2) }}</span>
          </div>
        </div> -->
        <div class="pays-box-type-cont">
          <div
            class="pays-box-type-cont-item"
            @click="selectPay(index)"
            :class="payIndex == index ? 'pays-box-type-cont-item-active' : ''"
            v-for="(item, index) in payArr"
            :key="index"
          >
            <div class="pays-box-type-cont-item-left">
              <div class="pays-box-type-cont-item-left-img" v-if="item.img">
                <img
                  :src="item.img"
                  :alt="item.name"
                  v-if="payIndex != index"
                />
                <img
                  :src="item.activeImg"
                  :alt="item.name"
                  v-if="payIndex == index"
                />
              </div>
              <div class="pays-box-type-cont-item-left-text" v-if="!item.img">
                {{ item.name[0] }}
              </div>
            </div>
            <div class="pays-box-type-cont-item-right">{{ item.name }}</div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="payShows = false">取 消</el-button>
        <el-button type="primary" @click="payShows = false"
          >确 定(￥{{ projectsPrice }})</el-button
        >
      </span>
    </el-dialog>
    <!-- 付款方式 -->

    <!-- 修改价格 -->
    <el-dialog
      title="修改商品价格"
      :visible.sync="dialogVisible3"
      :append-to-body="true"
      width="45%"
      :before-close="hideUpdatePrice"
    >
      <div v-if="dialogVisible3" style="margin-bottom:10px;">
        <!-- {{goods.list[price_index].pirce}} -->
        <span>商品名：</span>
        <span>{{ goods.list[price_index].name }}</span>
      </div>
      <div v-if="dialogVisible3" style="margin-bottom:10px;">
        <!-- {{goods.list[price_index].pirce}} -->
        <span>商品原价：</span>
        <span>{{ goods.list[price_index].price }}</span>
      </div>
      <div v-if="dialogVisible3">
        <!-- {{goods.list[price_index].pirce}} -->
        <span>修改价格：</span>
        <el-input-number
          v-model="goods.list[price_index].pirce"
          :precision="2"
          :step="0.1"
          :max="99999"
          :min="0.1"
        ></el-input-number>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="hideUpdatePrice">取 消</el-button>
        <el-button type="primary" @click="updatePrice">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改价格 -->
  </div>
</template>

<script>
export default {
  name: "pays",
  props: ["orders", "removeVip"],
  data() {
    return {
      dialogFormVisible: false,
      dialogFormVisible1: false,
      dialogVisible: false, //套餐卡
      dialogVisible1: false, //充值
      dialogVisible2: false, //计次卡
      dialogVisible3: false, //修改价格
      payShows: false, //付款方式
      input1: "",
      input2: "",
      payArr: [
        {
          img: require("../../assets/ye.png"),
          activeImg: require("../../assets/ye-a.png"),
          name: "现金",
        },
        {
          img: require("../../assets/wx.png"),
          activeImg: require("../../assets/wx-a.png"),
          name: "微信",
        },
        {
          img: require("../../assets/zfb.png"),
          activeImg: require("../../assets/zfb-a.png"),
          name: "支付宝",
        },
        {
          img: require("../../assets/yhk.png"),
          activeImg: require("../../assets/yhk-a.png"),
          name: "银行卡",
        },
        {
          img: require("../../assets/mt.png"),
          activeImg: require("../../assets/mt-a.png"),
          name: "美团",
        },
        // { img: "", name: "储值卡(余额)" },
      ],
      payIndex: -1,
      goods: this.orders,
      price: 0,
      alls: true,
      code: "",
      userInfo: false, //展示会员信息
      cardList: [], //套餐卡列表
      activeNames: 0, //套餐卡下标
      cardListActive: [], //选中的套餐卡
      value: "",
      works: [], //员工数据
      worksData: [], //选中的会员
      number: "", //卡号
      times: "", //有效时间
      other: "", //会员备注
      userList: [], //会员类型
      userListData: "", //选中的会员类型
      newPrice: 0, //实付充值的金额
      oldPrice: 0, //充值金额原价
      projects: [], //项目
      projectsData: [], //选中的项目
      projectsPrice: 0, //项目总价
      price_index: null, //选中需要修改价格的下标
    };
  },
  methods: {
    /* 保留小数点 */
    toFixeds(num) {
      // let obj = num + "";

      let d = num / 100;

      // let n = d.toFixed(d, s);
      let m = d + "";

      if (m.includes(".")) {
        let arr = m.split(".");
        let t = arr[0] + ".";

        for (let i = 0; i < 5; i++) {
          if (arr[1][i]) {
            t += arr[1][i];
            console.log(arr[1][i], t);
          }
        }
        console.log(t, num);
        return t.length >= 5 ? ((t - 0) * 100).toFixed(2) : (t - 0) * 100;
      }
      console.log(m, num);
      return (m - 0) * 100;
    },
    /* 选中工作人员 */
    selectStaff(id) {
      if (!id.length && !id) {
        return "--";
      }
      let d = this.$store.state.staff;
      let len = d.length;
      for (let i = 0; i < len; i++) {
        if (id - 0 == d[i].id - 0) {
          return d[i].name;
        }
      }
    },
    /* 获取员工数据 */
    getworks() {
      if (!this.works.lenght) {
        this.$axios({
          href: "/api/app/meiye/user/store",
          data: { status: 1 },
          loading: true,
        }).then((res) => {
          this.works = res.data;
        });
      }
    },
    /* 获取会员 */
    getUsertype() {
      if (!this.userList.lenght) {
        this.$axios({
          href: "/api/app/meiye/membertype/store",
          loading: true,
        }).then((res) => {
          this.userList = res.data;
        });
      }
    },
    hide() {
      this.payIndex = -1;
      this.$store.commit("payStatus", false);
    },
    /* 自定义流水号 */
    inputs(val) {
      if (!val.length) {
        this.code = "";
        return false;
      }
      if (!/^\d{1,}$/.test(val)) {
        this.$message({
          message: "请输入数字哦~",
          type: "warning",
        });
        return false;
      }
      this.code = val;
    },
    /* 流水号 */
    numberCode() {
      let now = new Date();
      // 获取当前完整年份
      let getFullYear = now.getFullYear();
      // 获取当前月份
      let getMonth =
        now.getMonth() + 1 < 10
          ? "0" + (now.getMonth() + 1)
          : now.getMonth() + 1;
      // 获取当前日
      let getDate = now.getDate() < 10 ? "0" + now.getDate() : now.getDate();
      // 获取到当前小时：
      let getHours =
        now.getHours() < 10 ? "0" + now.getHours() : now.getHours();
      // 获取到当前分钟：
      let getMinutes =
        now.getMinutes() < 10 ? "0" + now.getMinutes() : now.getMinutes();
      // 获取到当前秒：
      let getSeconds =
        now.getSeconds() < 10 ? "0" + now.getSeconds() : now.getSeconds();
      // 获取到当前毫秒：
      // let getMilliseconds =
      //   now.getMilliseconds() < 100
      //     ? "0" + now.getMilliseconds()
      //     : now.getMilliseconds();

      let d =
        "" +
        getFullYear +
        getMonth +
        getDate +
        getHours +
        getMinutes +
        getSeconds +
        Math.ceil(Math.random() * 1000000);
      // console.log(this.users);

      if (d.length < 20) {
        d += 0;
      }
      this.code = d;
    },
    selectPay(i) {
      /* if (!this.users.name && i == 4) {
        this.$message({
          message: "请更换其他付款方式，散客不支持储蓄卡付款方式...",
          type: "warning",
        });
        return false;
      } */
      this.payIndex = i;
    },
    actives(index) {
      let d = this.goods.list;
      let len = d.length;
      let num = 0;
      d[index].status = !d[index].status;
      for (let i = 0; i < len; i++) {
        if (d[i].status) {
          num++;
        }
        if (i == len - 1 && num == len) {
          this.alls = true;
        } else {
          this.alls = false;
        }
      }
    },
    all() {
      let d = this.goods.list;
      let len = d.length;
      // let num = 0;
      this.alls = !this.alls;
      for (let i = 0; i < len; i++) {
        d[i].status = this.alls;
      }
    },
    Coupon() {
      this.$message({
        message: "功能升级维护中...",
        type: "warning",
      });
      return false;
      // this.dialogFormVisible1 = !this.dialogFormVisible1;
    },
    payOrder() {
      if (this.payIndex < 0) {
        this.$message({
          message: "请选择付款方式",
          type: "warning",
        });
        return false;
      }
      if (this.users.name) {
        if (this.payIndex < 5) {
          this.goods.pays = [
            { pay_type: this.payIndex + 1, pay_amount: this.prices[0] },
          ];
        } else {
          this.goods.pays = [
            { pay_type: 11, pay_amount: this.prices[0] },
          ];
        }
      } else {
        if (this.payIndex < 4) {
          this.goods.pays = [
            { pay_type: this.payIndex + 1, pay_amount: this.prices[0] },
          ];
        } else {
          this.goods.pays = [
            { pay_type: 11, pay_amount: this.prices[0] },
          ];
        }
      }
      /* this.goods.pays = [
        { pay_type: this.payIndex + 1, pay_amount: this.prices[0] },
      ]; */
      
      this.goods.serial = this.code;

      // console.log(this.goods);

      let d = JSON.parse(JSON.stringify(this.goods.list));
      let len = d.length;
      let data = JSON.parse(JSON.stringify(this.goods));
      console.log(data, "水电费感受到恢复可接受的");
      data.products = [];
      data.projects = [];
      // data.cards = [];

      for (let i = 0; i < len; i++) {
        // d[i].price = d[i].pirce;
        if (d[i].type == 3 && d[i].status) {
          if (d[i].type == 3) {
            if (this.users.membertype) {
              if (d[i].pirce - 0 == d[i].price - 0) {
                /* 判断有没有会员价格 */
                if (!d[i].member_price_status) {
                  d[i].price = (
                    d[i].pirce * this.users.membertype.product_disc
                  ).toFixed(2);
                } else {
                  d[i].price = (d[i].pirce - 0).toFixed(2);
                }
              } else {
                d[i].price = (d[i].pirce - 0).toFixed(2);
              }
            } else {
              d[i].price = (d[i].pirce - 0).toFixed(2);
            }
            data.products.push(d[i]);
          }
        }
        if (d[i].status && d[i].type == 2) {
          if (d[i].type == 2) {
            if (this.users.membertype) {
              if (d[i].pirce - 0 == d[i].price - 0) {
                /* 判断有没有会员价格 */
                if (!d[i].member_price_status) {
                  d[i].price = (
                    d[i].pirce * this.users.membertype.project_disc
                  ).toFixed(2);
                } else {
                  d[i].price = (d[i].pirce - 0).toFixed(2);
                }
              } else {
                d[i].price = (d[i].pirce - 0).toFixed(2);
              }
            } else {
              d[i].price = (d[i].pirce - 0).toFixed(2);
            }

            data.projects.push(d[i]);
          }
        }

        if (d[i].status && d[i].type == -1) {
          if (d[i].project_id != d[i].treat_id && d[i].type == -1) {
            data.projects.push(d[i]);
          }
          if (d[i].product_id != d[i].treat_id && d[i].type == -1) {
            data.products.push(d[i]);
          }
        }

        // if (d[i].type == -1 && d[i].status) {
        //   if (d[i].project_id != d[i].treat_id) {
        //     d[i].price = (d[i].pirce * this.users.membertype.project_disc).toFixed(2);
        //     data.projects.push(d[i]);
        //   }
        //   if (d[i].product_id != d[i].treat_id) {
        //     d[i].price = (d[i].pirce * this.users.membertype.product_disc).toFixed(2);
        //     data.products.push(d[i]);
        //   }
        // }
      }

      console.log('data', data, 'users', this.users);
      if (!data.products.length && !data.projects.length) {
        this.$message({
          message: "请选择服务项目~",
          type: "warning",
        });
        return false;
      }
      const products = data.products;
      const projects = data.projects;
      const cards = data.cards;
      // console.log('products', products);
      // console.log('projects', projects);
      // console.log('cards', cards);
      if (products.length) {
        const productsLen = products.length;
        for (let i = 0; i < productsLen; i++) {
          if (!products[i].staff || products[i].staff.length === 0) {
            this.$message({
              message: "未选择员工~",
              type: "warning",
            });
            return false;
          }
        }
      }
      if (projects.length) {
        const projectsLen = projects.length;
        for (let i = 0; i < projectsLen; i++) {
          if (!projects[i].staff || projects[i].staff.length === 0) {
            this.$message({
              message: "未选择员工~",
              type: "warning",
            });
            return false;
          }
        }
      }
      if (cards.length) {
        const cardsLen = cards.length;
        for (let i = 0; i < cardsLen; i++) {
          if (!cards[i].staff || cards[i].staff.length === 0) {
            this.$message({
              message: "未选择员工~",
              type: "warning",
            });
            return false;
          }
        }
      }
      data.pays = JSON.stringify(data.pays);
      data.products = JSON.stringify(data.products);
      data.projects = JSON.stringify(data.projects);
      data.cards = JSON.stringify(data.cards);
      data.member_id = this.users.id || 0;
      // if (
      //   this.payArr[this.payIndex].name == "经理免单" &&
      //   !this.input1 &&
      //   !this.input2
      // ) {
      //   this.dialogFormVisible = !this.dialogFormVisible;
      //   return false;
      // }
      const that = this;
      this.$axios({
        href: "/api/app/meiye/payment/pay",
        data: data,
      }).then(() => {
        // console.log(res);
        that.$store.commit("payStatus", false);
        that.$store.commit("billShowS", false);
        that.$store.commit("SetLoadOrder", {
          id: "",
          order: "",
          name: "",
          data: [],
          pirce: "",
          other: "",
          time: "",
          start_time: "",
          end_time: "",
          time_type: 0,
          source: "",
          customer: "",
          customerNum: "",
          status: 1,
          gerge: false,
          gergrStatus: false,
        });
        that.getLIst();
        that.$store.commit("setUser", []);
        this.$message({
          message: "结账成功~",
          type: "success",
        });
      });
    },
    codes() {
      if (!this.input1) {
        this.$message.error("工号错误");
        return false;
      }
      if (!this.input2) {
        this.$message.error("授权码错误");
        return false;
      }
      this.dialogFormVisible = !this.dialogFormVisible;
    },
    /* 交易历史 */
    psyOrder() {
      this.$message.error("功能升级维护中...");
    },
    /* 显示充值界面 */
    payShow() {
      this.dialogVisible1 = !this.dialogVisible1;
      this.getworks();
      this.getUsertype();
      console.log(this.$store.state.tabData.tab);
      let d = JSON.parse(JSON.stringify(this.$store.state.tabData.tab[0].data));
      let len = d.length;
      // let d = this.projects;
      for (let i = 0; i < len; i++) {
        /* 去掉预约 这里去的是vuex的内容 */
        if (i < 2) {
          for (let k = 0; k < d[i].projects.length; k++) {
            d[i].projects[k].num = 1;
            d[i].projects[k].status = false;
            d[i].projects[k].newPrice = d[i].projects[k].price;
          }
        }
      }
      this.projects = d;
    },
    /* 显示套餐卡 */
    card() {
      this.dialogVisible = !this.dialogVisible;
      const that = this;
      if (!this.cardList.length) {
        this.$axios({
          href: "/api/app/meiye/card/store",
          data: { status: 1 },
        }).then((res) => {
          let len = res.data.length;
          for (let i = 0; i < len; i++) {
            res.data[i].list = [];
            res.data[i].data = [];
            res.data[i].num = 1;
            res.data[i].status = false;
            res.data[i].list = res.data[i].products.concat(
              res.data[i].projects
            );
          }

          for (let i = 0; i < len; i++) {
            for (let l = 0; l < res.data[i].list.length; l++) {
              if (res.data[i].list[l].project) {
                res.data[i].data.push({
                  id: res.data[i].list[l].project_id,
                  count: res.data[i].list[l].count,
                  card_id: res.data[i].list[l].card_id,
                  type: "项目",
                  name: res.data[i].list[l].project.name,
                  price: res.data[i].list[l].project.price,
                });
              }
              if (res.data[i].list[l].product) {
                res.data[i].data.push({
                  id: res.data[i].list[l].product_id,
                  count: res.data[i].list[l].count,
                  card_id: res.data[i].list[l].card_id,
                  type: "商品",
                  name: res.data[i].list[l].product.name,
                  price: res.data[i].list[l].product.price,
                });
              }
            }
          }

          console.log(res.data, 111111);

          that.cardList = res.data;
        });
      }
    },
    /* 选中套餐卡 */
    cardListActives(index) {
      let arr = [];

      let len = this.cardList.length;
      for (let i = 0; i < len; i++) {
        if (index == this.cardList[i].id) {
          this.cardList[i].status = !this.cardList[i].status;
        }
        if (this.cardList[i].status) {
          arr.push(this.cardList[i]);
        }
      }
      this.cardListActive = arr;
      console.log(index, this.cardListActive);
    },
    /* 套餐卡 */
    cardSuccess() {
      if (!this.worksData.length) {
        this.$message({
          message: "请选择销售人员...",
          type: "warning",
        });
        return false;
      }
      /*   
      时间可为空
      if (!this.times) {
        this.$message({
          message: "请选择有效时间...",
          type: "warning",
        });
        return false;
      }
 */
      let arr = [];
      let len = this.cardList.length;
      for (let i = 0; i < len; i++) {
        if (this.cardList[i].status) {
          arr.push({
            card_id: this.cardList[i].id,
            price: this.cardList[i].price,
            num: this.cardList[i].num,
            staff: this.worksData,
          });
        }
      }
      this.goods.cards = JSON.stringify(arr);
      this.dialogVisible = false;
      this.dialogVisible1 = false;
      console.log('goods', this.goods);
    },
    /* 计次卡 */
    cardNum() {
      this.dialogVisible2 = !this.dialogVisible2;
    },
    /* 选中项目 */
    cardActive(item, type) {
      console.log('item', item);
      let len = this.projects.length;
      let d = this.projects;
      let arr = [];
      this.projectsPrice = 0;
      for (let i = 0; i < len; i++) {
        for (let k = 0; k < d[i].projects.length; k++) {
          if (
            item.id == d[i].projects[k].id &&
            item.category_id == d[i].projects[k].category_id
          ) {
            if (!type) {
              d[i].projects[k].price = item.price;
              d[i].projects[k].num = 1;
              d[i].projects[k].status = !d[i].projects[k].status;
            }
          }
          if (d[i].projects[k].status) {
            if (i) {
              arr.push(d[i].projects[k]);
            }
            // this.projectsPrice +=
            //   (d[i].projects[k].price - 0) * (d[i].projects[k].num - 0);
          }
        }
      }

      for (let l = 0; l < arr.length; l++) {
        this.projectsPrice += (arr[l].price - 0) * (arr[l].num - 0);
      }
      console.log(arr, this.projectsPrice);

      this.projects = d;
      this.projectsData = arr;
      this.projectsPrice += this.oldPrice - 0;
      this.projectsPrice = this.projectsPrice.toFixed(2);
    },
    /* 计次卡充值 */
    treat_recharge() {
      const that = this;

      let arr = [];
      if (this.projectsData.length) {
        for (let i = 0; i < this.projectsData.length; i++) {
          arr.push({
            project_id: this.projectsData[i].id + "",
            num: this.projectsData[i].num + "",
            price: this.projectsData[i].price + "",
            exp: this.times,
            staff: this.worksData,
          });
        }
      } else {
        return false;
      }
      if (!this.worksData.length) {
        this.$message({
          message: "请选择销售人员...",
          type: "warning",
        });
        return false;
      }

      /* 时间不限制，可为空，为空永久 */
      if (!this.times) {
        // this.$message({
        //   message: "请选择有效时间...",
        //   type: "warning",
        // });
        // return false;
        this.times = "";
      }

      if (this.payIndex < 0) {
        this.payShows = !this.payShows;
        return false;
      }
      let index = this.payIndex + 1;
      console.log('users', this.users);

      this.$axios({
        href: "/api/app/meiye/payment/treat_recharge",
        data: {
          member_id: this.users.id,
          projects: JSON.stringify(arr),
          pays: JSON.stringify([
            { pay_type: index, pay_amount: this.projectsPrice },
          ]),
        },
      }).then((res) => {
        that.projectsData = [];
        console.log('购买结果：', res);
        that.$message({
          message: "购买成功~",
          type: "success",
        });
        that.payShows = false;
        this.projects = [];
        that.dialogVisible2 = false;
        that.dialogVisible1 = false;
      });

      // this.dialogVisible2
    },
    /* 修改储蓄卡价格 */
    userPriceNum() {
      this.cardActive({}, true);
    },
    /* 储蓄卡充值 */
    userPrice() {
      if (!this.worksData.length) {
        this.$message({
          message: "请选择销售人员...",
          type: "warning",
        });
        return false;
      }
      if (this.payIndex < 0) {
        this.payShows = !this.payShows;
        return false;
      }
      let index = this.payIndex + 1;
      const that = this;

      if (index >= 5) {
        this.$message({
          message: "充值储蓄卡不允许通过储蓄卡支付，请更换支付方式...",
          type: "warning",
        });
        this.payShows = !this.payShows;
        return false;
      }
      // console.log(111111111111);
      if (this.oldPrice > 0) {
        this.$axios({
          href: "/api/app/meiye/payment/recharge",
          data: {
            member_id: this.users.id,
            order_amount: this.newPrice,
            sale_amount: this.oldPrice + "",
            order_pay: JSON.stringify([
              { pay_type: index, pay_amount: this.projectsPrice },
            ]),
            staff_ids: JSON.stringify(this.worksData),
            remark: "",
            order_time: "",
          },
        }).then(() => {
          that.newPrice = 0;
          that.oldPrice = 0;
          that.$message({
            message: "充值成功~",
            type: "success",
          });
          that.cardActive({}, true);
          that.userActive();
          that.dialogVisible1 = !that.dialogVisible1;
        });
      }
    },
    /* 购买卡次和储蓄值 */
    payCard() {
      /* 计次卡 */
      if (this.projectsData.length) {
        this.treat_recharge();
        this.payShows = true;
      }
      if (this.oldPrice > 0) {
        /* 储蓄卡 */
        this.userPrice();
        this.payShows = true;
      }
      if (this.cardList.length) {
        /* 套餐卡 */
        this.cardSuccess();
        this.payShows = true;
      }
    },
    userActive() {
      this.$axios({
        href: "/api/app/meiye/member/index",
        data: { id: this.users.id },
        loading: true,
      }).then((res) => {
        console.log('userActive', res);
        let user = res.data.member;
        user.vip = [];
        res.data.name = res.data.member.name;

        for (let i = 0; i < res.data.card.length; i++) {
          for (let k = 0; k < res.data.card[i].treats.length; k++) {
            res.data.card[i].treats[k].type = res.data.card[i].name;
            res.data.card[i].treats[k].vip = true;
            res.data.card[i].treats[k].treat_id = res.data.card[i].treats[k].id;
            user.vip.push(res.data.card[i].treats[k]);
          }
        }
        for (let l = 0; l < res.data.treat.length; l++) {
          res.data.treat[l].vip = true;
          res.data.treat[l].treat_id = res.data.treat[l].id;
          user.vip.push(res.data.treat[l]);
        }

        user.membertype = res.data.member.membertype;

        user.membertype.product_disc =
          res.data.member.membertype.product_disc / 100;
        user.membertype.project_disc =
          res.data.member.membertype.project_disc / 100;
        // console.log(user);

        this.$store.commit("setUser", user);
      });
    },
    /* 获取挂单列表 */
    getLIst() {
      const that = this;
      this.$axios({
        href: "/api/app/meiye/payment/car_store",
      }).then((res) => {
        console.log('getLIstRes', res);
        let len = res.data.length;
        let d = res.data;
        for (let i = 0; i < len; i++) {
          d[i].list = [];
          // d[i].list = d[i].products.concat(d[i].projects); //合并商品和项目（两个是一样的）

          for (let l = 0; l < d[i].products.length; l++) {
            d[i].products[l].oldpirce = d[i].products[l].price;
            d[i].products[l].type = 3;

            if (d[i].products[l].treat_id) {
              d[i].products[l].type = -1;
            }
            d[i].list.push(d[i].products[l]);
            console.log(1111);
          }
          for (let l = 0; l < d[i].projects.length; l++) {
            d[i].projects[l].projects = d[i].projects[l].price;
            d[i].projects[l].type = 2;

            if (d[i].projects[l].treat_id) {
              d[i].projects[l].type = -1;
            }
            d[i].list.push(d[i].projects[l]);
            console.log(2222);
          }
        }
        that.$store.commit("setDataOrder", d);
      });
    },
    /* 显示修改价格 */
    showUpdatePrice(index, obj) {
      console.log(obj);
      if (obj.type == -1) {
        return false;
      }
      this.price_index = index;
      this.dialogVisible3 = true;
    },
    /* 关闭修改价格窗口 */
    hideUpdatePrice() {
      this.price_index = null;
      this.dialogVisible3 = false;
    },
    /* 修改价格 */
    updatePrice() {
      this.dialogVisible3 = false;
      console.log(this.goods, this.price_index);
    },
  },
  mounted() {
    const user = this.$store.state.order.user;
    if (user.name) {
      this.userInfo = true;
      this.payArr.splice(4, 0, { img: "", name: "储值卡(余额)" });
      this.payIndex = 4;
    }
  },
  beforeDestory() {
    console.log('beforeDestory');
  },
  computed: {
    shows: {
      get() {
        return this.$store.state.order.pay;
      },
      set() {
        return this.$store.state.order.pay;
      },
    },
    users: {
      get() {
        return this.$store.state.order.user;
      },
      set() {
        return this.$store.state.order.user;
      },
    },
    prices() {
      console.log(this.users, this.goods);
      let d = this.goods.list || [];
      let len = d.length;
      let p = 0;
      let p1 = 0;
      // this.users.membertype.product_disc =
      //   this.users.membertype.product_disc || 1;
      // this.users.membertype.project_disc =
      //   this.users.membertype.project_disc || 1;

      for (let i = 0; i < len; i++) {
        if (d[i].pirce == d[i].price) {
          if (d[i].status && d[i].type > 0 && !d[i].member_price_status) {
            p1 += d[i].num * d[i].pirce;
            if (d[i].type == 3) {
              p +=
                d[i].num *
                d[i].pirce *
                (this.users.name ? this.users.membertype.product_disc : 1);
            }
            if (d[i].type == 2) {
              p +=
                d[i].num *
                d[i].pirce *
                (this.users.name ? this.users.membertype.project_disc : 1);
            }
          }

          /* 会员价 */
          if (d[i].status && d[i].type > 0 && d[i].member_price_status) {
            p1 += d[i].num * d[i].price;
            if (d[i].type == 3) {
              p += d[i].num * d[i].pirce;
            }
            if (d[i].type == 2) {
              p += d[i].num * d[i].pirce;
            }
          }
        } else {
          if (d[i].status && d[i].type > 0) {
            p1 += d[i].num * d[i].price;
            if (d[i].type == 3) {
              p += d[i].num * d[i].pirce;
            }
            if (d[i].type == 2) {
              p += d[i].num * d[i].pirce;
            }
          }
        }
      }
      console.log(p.toFixed(2), p1.toFixed(2));
      return [p.toFixed(2), p1.toFixed(2)];
    },
  },
  watch: {
    shows() {
      console.log('users', this.users, 'orders', this.orders);
      this.numberCode();
      this.goods = JSON.parse(JSON.stringify(this.orders));
      this.goods.list = [];
      let objs = [];

      let len = this.goods.products.length;
      for (let i = 0; i < len; i++) {
        this.goods.products[i].type = true;
      }
      objs = objs.concat(this.goods.products);
      objs = objs.concat(this.goods.projects);
      objs = objs.concat(this.goods.cards);

      this.goods.list = objs;

      console.log('goods.list', this.goods.list, objs);
    },
    users(val) {
      console.log('usersVal', val);
      if (val.name) {
        this.userInfo = true;
        this.payArr.splice(4, 0, { img: "", name: "储值卡(余额)" });
        this.payIndex = 4;
      } else {
        this.userInfo = false;
        this.payArr.splice(4, 1);
        this.payIndex = -1;
      }
    },
    removeVip(val) {
      if (val) {
        this.payArr.splice(4, 1);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.pays {
  width: auto;
  height: auto;
  overflow-x: auto;
  background: rgba(0, 0, 0, 0.5);
}
.pays-box {
  position: fixed;
  top: 150%;
  left: 0;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  background: #fff;
  opacity: 0;
  transition: all 0.5s;
  display: flex;
  &-head {
    width: 100%;
    height: 40px;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    background: #fff;
    padding: 25px 0 15px 0;
    text-align: center;
    display: flex;
    border-bottom: 0.5px solid rgba(220, 220, 220, 0.7);
    &-title {
      width: 100%;
      height: 100%;
      font-size: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-back {
      width: 20%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: flex-start;
      padding-left: 10px;
      align-items: center;
      button {
        width: 40px;
        height: 40px;
        background: transparent;
        background-image: url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_NavBack.png);
        background-position: left center;
        background-size: 28px;
        background-repeat: no-repeat;
      }
    }
  }

  &-info {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 0;
    height: 100%;
    background: #f4f4f4;
    overflow: hidden;
    box-sizing: border-box;
    padding-top: 80px;
    box-shadow: 0 0 2px 0 #c3c2c2;
    transition: all 0.5s;
    &-head {
      width: 320px;
      padding: 0 15px 15px 15px;
      // background: red;
      background: #fff;
      box-sizing: border-box;
      &-name {
        position: relative;
        padding: 15px 52px 13px 28px;
        line-height: 28px;
        background: #fff
          url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_OrderItemVipFlag.png)
          left center / 24px no-repeat;
        font-size: 14px;
        color: #604e2a;
        box-sizing: border-box;
        display: flex;
        text-align: left;
        span {
          display: inline-block;
          // background: red;
          width: 95px;
          height: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        button {
          position: absolute;
          top: 15px;
          right: 5px;
          width: 52px;
          height: 28px;
          border: 0.5px solid #47bf7c !important;
          background: #fff;
          border-radius: 4px;
          color: #47bf7c;
          font-size: 14px;
          font-family: PingFangSC-Medium;
          cursor: pointer;
          outline: none;
          box-sizing: border-box;
          padding: 0;
        }
      }
      &-other {
        background: #f4f4f4;
        padding: 10px 0;
        border-radius: 6px;
      }
      &-btn {
        display: flex;
        margin-top: 12px;
        button {
          flex: 1;
          margin-right: 10px;
          height: 34px;
          border: 0.5px solid #ddd !important;
          background: #fff;
          font-size: 14px;
          color: #28282d;
          border-radius: 6px;
          cursor: pointer;
          outline: none;
          box-sizing: border-box;
          padding: 0;
        }
      }
    }
    &-text {
      background: #fff;
      border-radius: 6px;
      margin-bottom: 10px;
      padding: 15px;
      padding-bottom: 5px;
      margin-top: 10px;
      &-title {
        position: relative;
        line-height: 40px;
        border-bottom: 0.5px solid rgba(220, 220, 220, 0.7);
        font-size: 14px;
        color: #8a8a8a;
        margin-bottom: 5px;
        display: flex;
        padding: 0 10px;
      }
      &-text {
        line-height: 30px;
        font-size: 13px;
        color: #28282d;
        display: flex;
        padding: 0 10px;
        cursor: pointer;
        span {
          flex: 3;
          line-height: 30px;
          font-size: 13px;
          text-align: left;
          color: #28282d;
        }
        span:last-child {
          flex: 2;
          text-align: right;
        }
      }
    }
  }

  &-info-active {
    width: 319px;
    opacity: 1;
    overflow-x: hidden;
    overflow-y: auto;
  }
  &-userShow {
    cursor: pointer;
    position: absolute;
    display: flex;
    top: 93px;
    left: -100%;
    width: 120px;
    height: 28px;
    border: 1px solid #d6c361;
    background: #d6c361;
    line-height: 28px;
    text-align: center;
    border-radius: 0 5px 5px 0;
    overflow: hidden;
    z-index: 3000;
    font-size: 13px;
    transition: all 0.5s;
    i {
      display: inline-block;
      height: 100%;
      width: 35px;
      font-size: 16px;
      line-height: 28px;
      cursor: pointer;
      margin-left: 33px;
    }
  }
  &-userShow-active {
    left: 0;
  }

  &-cont {
    width: 100%;
    height: auto;
    padding: 10px;
    box-sizing: border-box;
    padding-top: 90px;
    &-title {
      line-height: 40px;
      font-size: 14px;
      color: #5a5a5a;
      text-align: left;
    }
    &-list {
      padding: 0 15px 25px 15px;
      min-height: 68px;
      // background: rgb(94, 31, 31);
      margin-top: 15px;
      &-item {
        width: 100%;
        background: #f4f4f4;
        position: relative;
        display: flex;
        padding: 10px;
        box-sizing: border-box;
        margin-bottom: 10px;
        border-radius: 6px;
        height: 90px;
        overflow: hidden;
        cursor: pointer;
        &-text {
          flex: 1;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          &-p {
            width: 150px;
            height: 20px;
            margin-left: 10px;
            // line-height: 50px;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          &-btns {
            width: 50px;
            height: 28px;
            border: 1px dashed #409eff;
            border-radius: 0px;
            font-size: 14px;
            font-family: Microsoft YaHei, Microsoft YaHei-Normal;
            font-weight: Normal;
            display: flex;
            border-radius: 5px;
            align-items: center;
            justify-content: center;
            color: #409eff;
            // line-height: 6px;

            // font-size: 13px;
            // color: #28282d;
            // background: #f4f4f4
            //   url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_DYQPay_N.png)
            //   center / 24px no-repeat;
          }
        }
        &-img {
          width: 70px;
          height: 70px;
          margin-left: 50px;
          img {
            border-radius: 5px;
            width: 100%;
            height: 100%;
          }
        }
        &-texts {
          flex: 2;
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }
        &-texts-last {
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
        &-button {
          position: absolute;
          left: 0;
          top: 0;
          width: 60px;
          height: 100%;
          background: transparent
            url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Select_N.png)
            center / 28px no-repeat;
        }
      }
    }

    &-info {
      line-height: 25px;
      text-align: right;
      padding: 15px 35px 45px 35px;
      display: flex;
      // background: red;
      &-left {
        width: 30%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        button {
          background: rgba(255, 255, 255, 0)
            url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Select_N.png)
            left center / 28px no-repeat;
          width: 90px;
          height: 32px;
        }
      }
      &-right {
        width: 70%;
        height: 100%;
        span {
          margin: 0 10px;
          font-size: 14px;
          color: #b0b0b0;
        }
        span:last-child {
          font-size: 16px;
          color: #000;
          em {
            font-style: normal;
            color: red;
          }
        }
      }
    }

    &-other {
      width: 100%;
      height: 30px;
      // background: red;
      position: relative;
      button {
        position: absolute;
        bottom: 10px;
        left: 0;
        width: 100%;
        font-size: 14px;
        color: #8a8a8a;
        line-height: 25px;
        padding: 0 35px 0 65px;
        text-align: left;
        background: #fff
          url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Edit_Green.png)
          35px center / 28px no-repeat;
      }
    }
  }

  &-type {
    width: 100%;
    height: 80px;
    padding: 15px 0 0 15px;
    background: #f4f4f4;
    position: relative;
    display: flex;
    &-title {
      text-align: left;
      line-height: 40px;
      font-size: 14px;
      width: 10%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #5a5a5a;
    }
    &-cont {
      // padding: 0 0 50px 8px;
      overflow-x: hidden;
      overflow-y: auto;
      width: 70%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      &-item {
        position: relative;
        display: inline-flex;
        padding: 9px;
        width: 40px;
        border-radius: 6px;
        background: #fff;
        margin: 0 7px 18px 7px;
        vertical-align: middle;
        border: 1px solid #fff;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        &-left {
          // margin-right: 10px;
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          &-text {
            display: inline-block;
            border-radius: 6px;
            width: 32px;
            height: 32px;
            line-height: 32px;
            font-size: 18px;
            font-family: PingFangSC-Medium;
            color: #fff;
            text-align: center;
            background: #5a5a5a;
          }
          &-img {
            width: 32x;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        &-right {
          display: flex;
          flex: 1;
          font-size: 15px;
          color: #28282d;
          line-height: 40px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      &-item-active {
        border: 1px solid #47bf7c;
      }
      &-item-active::after {
        position: absolute;
        content: "";
        right: 0;
        bottom: 0;
        width: 22px;
        height: 22px;
        background: transparent
          url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Payment_S.png)
          right bottom / 22px no-repeat;
      }
    }
    &-pay {
      position: absolute;
      top: 25px;
      right: 2%;
      // width: 50%;
      // height: 48px;
      // line-height: 48px;

      // font-size: 16px;
      // font-family: PingFangSC-Medium;
      // color: #fff;
      // background: #28282d;
      // border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 140px;
      height: 46px;
      background: #f57665;
      font-size: 14px;
      font-family: Microsoft YaHei, Microsoft YaHei-Normal;
      font-weight: Normal;
      text-align: center;
      color: #fff;
      line-height: 20px;
      border-radius: 5px;
      cursor: pointer;
    }
  }

  button {
    border: none;
    outline: none;
    box-sizing: border-box;
    cursor: pointer;
  }
}
.pays-box-active {
  top: 0;
  z-index: 2000;
  opacity: 1;
}
.btn-active {
  background: transparent
    url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Select_S.png) left
    center / 28px no-repeat !important;
}
.btn-active1 {
  background: transparent
    url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Select_S.png)
    center/28px no-repeat !important;
}

.quan {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  &-btn {
    width: 100%;
    height: 120px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    button {
      width: 80%;
      height: 50px;
      background: #000;
      border: none;
    }
  }
  &-input {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 87%;
    span {
      width: 20px !important;
    }
  }
}

.quan span {
  display: inline-block;
  width: 13%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 10px;
}
.pays-box-cont-box {
  width: 100%;
  height: 100%;
  padding-left: 320px;
  box-sizing: border-box;
  overflow: auto;
  transition: all 0.5s;
}

.card {
  width: 100%;
  height: 560px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  &-item {
    width: 55%;
    height: 100%;
    transition: all 0.5s;
    &-list {
      width: 100%;
      height: 50px;
      // background: red;
      display: flex;
      align-items: center;
      justify-content: center;
      &-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        height: 100%;
        margin-left: 10px;
        button {
          cursor: pointer;
          width: 25px;
          height: 60px;
          margin: auto;
          background: #fff
            url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Del_Red.png)
            left center / 24px no-repeat;
          border: none;
          outline: none;
          box-sizing: border-box;
          padding: 0;
        }
      }
      &-name {
        width: 100%;
        height: 100%;
        display: flex;
        div {
          width: 50%;
          height: 100%;
          display: flex;
          align-items: center;
          padding: 0 10px;
          box-sizing: border-box;
          justify-content: flex-start;
        }
        div:last-child {
          justify-content: flex-end;
        }
      }
      &-num {
        width: 180px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &-select {
        width: 160px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  &-item:first-child {
    width: 45%;
    // background: red;
  }

  &-btn {
    width: 30px;
    height: 35px;
    cursor: pointer;
    padding: 0;
    border: none;
    outline: none;
    background: #fff
      url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Select_N.png)
      center / 28px no-repeat;
  }
  &-btn-active {
    background: #fff
      url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Select_S.png)
      center / 28px no-repeat;
  }
}

.Pay {
  width: 100%;
  height: 87.8vh;
  display: flex;
  &-l {
    width: 70%;
    height: 100%;
    &-item {
      background: #f4f4f4;
      border-radius: 6px;
      min-height: 44px;
      margin-bottom: 10px;
      overflow: hidden;
      padding: 0 15px;
      box-sizing: border-box;
      &-title {
        border-bottom: 0.5px solid rgba(220, 220, 220, 0.7);
        display: flex;
        line-height: 30px;
        padding: 12px 0 12px 0;
        font-size: 14px;
        color: #28282d;
        box-sizing: border-box;
        &-name {
          color: #28282d;
          width: 68px;
          font-size: 15px;
        }
        &-price {
          flex: 1;
          text-align: right;
          margin-right: 15px;
          color: #28282d;
        }
      }
      &-list {
        border-bottom: 0.5px solid rgba(220, 220, 220, 0.7);
        // display: flex;
        line-height: 30px;
        padding: 12px 0 12px 0;
        box-sizing: border-box;
        font-size: 14px;
        color: #28282d;
        &-item {
          width: 100%;
          height: auto;
          // padding: 12px 15px 12px 40px;
          line-height: 20px;
          font-size: 14px;
          color: #8a8a8a;
          box-sizing: border-box;
          display: flex;
          button {
            width: 40px;
            height: 44px;
            border: none;
            outline: none;
            box-sizing: border-box;
            cursor: pointer;
            padding: 0;
            background: transparent
              url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Del_Gray.png)
              center / 28px no-repeat;
          }
          div:nth-child(3) {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;
          }
          div:nth-child(2) {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex: 2;
          }
        }
      }
      &-add {
        display: block;
        text-align: right;
        padding: 0;
        height: 50px;
        overflow: hidden;
        line-height: 50px;
        font-size: 15px;
        color: #47bf7c;
        margin-right: 15px;
        cursor: pointer;
      }
    }
  }
  &-r {
    width: 30%;
    height: 100%;
    position: relative;
    &-title {
      line-height: 40px;
      font-size: 20px;
      font-family: PingFangSC-Semibold;
      box-sizing: border-box;
      color: #28282d;
    }
    &-box {
      width: 100%;
      height: 80vh;
      padding: 10px;
      box-sizing: border-box;
      // background: red;
      &-item {
        background: #f4f4f4;
        border-radius: 6px;
        min-height: 44px;
        margin-bottom: 10px;
        padding-left: 10px;
        box-sizing: border-box;
        overflow: hidden;
        display: flex;
        cursor: pointer;
        div {
          color: #28282d;
          width: 68px;
          font-size: 15px;
          font-family: PingFangSC-Medium;
          // height: 100%;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }
        div:last-child {
          flex: 1;
          justify-content: flex-end;
          padding-right: 28px;
          margin-right: 5px;
          background: transparent
            url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Right.png)
            right center / 28px no-repeat;
        }
      }
    }
  }
  &-footer {
    width: 100%;
    height: 43px;
    padding: 10px 0;
    display: flex;
    // background: blue;
    div {
      width: 100%;
      height: 100%;
      padding: 10px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    div:last-child {
      cursor: pointer;
      width: 110px;
      font-size: 16px;
      color: #fff;
      border-radius: 6px;
      background: #f44e4e;
      justify-content: center;
      margin-right: 10px;
    }
  }
}
</style>

<style moudle>
.el-tabs--border-card {
  min-height: 200px;
}
.pays-box-info-head-other textarea {
  background-color: transparent;
  border: none;
}
.pays-box-info-head-other .el-input__count {
  background-color: transparent !important;
}
.Pay-box .el-dialog {
  margin: 0 !important;
}
</style>

<template>
  <div
    @click.self="hide"
    class="vipInfo"
    :class="shows ? 'vipInfo-active' : ''"
  >
    <div class="vipInfo-box" :class="shows ? 'vipInfo-box-active' : ''">
      <div class="vipInfo-box-title">详情</div>

      <div class="vipInfo-box-cont">
        <div class="vipInfo-box-cont-list">
          <span class="vipInfo-box-cont-list-left">品项名称：</span>
          <span class="vipInfo-box-cont-list-right">{{vip.name}}</span>
        </div>
        <div class="vipInfo-box-cont-list">
          <div>
            <span class="vipInfo-box-cont-list-left">品项储值：</span>
            <span class="vipInfo-box-cont-list-right">￥ {{vip.price}}</span>
          </div>
          <div>
            <span class="vipInfo-box-cont-list-left">购买门店：</span>
            <span class="vipInfo-box-cont-list-right">{{vip.superior}}</span>
          </div>
        </div>
        <div class="vipInfo-box-cont-list">
          <span class="vipInfo-box-cont-list-left">品项实收：</span>
          <span class="vipInfo-box-cont-list-right">￥ {{vip.price}}</span>
        </div>
        <div class="vipInfo-box-cont-list vipInfo-box-cont-list-margin">
          <div>
            <span class="vipInfo-box-cont-list-left">购买次数：</span>
            <span class="vipInfo-box-cont-list-right">{{vip.allnum}}</span>
          </div>
          <div>
            <span class="vipInfo-box-cont-list-left">赠送次数：</span>
            <span class="vipInfo-box-cont-list-right">{{vip.num}}</span>
          </div>
        </div>
        <div class="vipInfo-box-cont-list">
          <div>
            <span class="vipInfo-box-cont-list-left">使用次数：</span>
            <span class="vipInfo-box-cont-list-right">{{vip.num}}</span>
          </div>
          <div>
            <span class="vipInfo-box-cont-list-left">剩余次数：</span>
            <span class="vipInfo-box-cont-list-right">{{vip.allnum - vip.num}}</span>
          </div>
        </div>
        <div class="vipInfo-box-cont-list vipInfo-box-cont-list-margin">
          <div>
            <span class="vipInfo-box-cont-list-left">购买日期：</span>
            <span class="vipInfo-box-cont-list-right">{{vip.sratrtime}}</span>
          </div>
          <div>
            <span class="vipInfo-box-cont-list-left">有效期：</span>
            <span class="vipInfo-box-cont-list-right">{{vip.endtime}}</span>
          </div>
        </div>
        <div class="vipInfo-box-cont-list  vipInfo-box-cont-list-margin">
          <span class="vipInfo-box-cont-list-left">备注：</span>
        </div>
        <div class="vipInfo-box-cont-other">
          {{vip.other || "暂无备注"}}
        </div>
      </div>

      <div class="vipInfo-box-foot">
        <button @click="hide()">确认</button>
      </div>
      <button class="vipInfo-box-close" @click="hide()"></button>
    </div>
  </div>
</template>

<script>
export default {
  name: "VipInfo",
  data() {
    return {

    };
  },
  methods: {
    hide() {
      this.$store.commit("vipInfos", false);
    },
  },
  computed: {
    shows: {
      get() {
        return this.$store.state.order.vipInfo;
      },
      set() {
        return this.$store.state.order.vipInfo;
      },
    },
    vip: {
      get() {
        return this.$store.state.order.vipInfoData;
      },
      set() {
        return this.$store.state.order.vipInfoData;
      },
    },
  }
};
</script>

<style lang="scss" scoped>
.vipInfo {
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.5);
  z-index: -1;
  opacity: 0;
  transition: opacity 0.5s;
  &-box {
    position: absolute;
    border-radius: 6px;
    left: 0;
    right: 0;
    top: -50%;
    margin: auto;
    min-height: 200px;
    transition: top 0.5s;
    &-title {
      line-height: 40px;
      font-family: PingFangSC-Semibold;
      font-size: 17px;
      color: #333333;
      text-align: center;
      padding: 10px 0 5px 0;
    }
    &-cont {
      max-height: 654px;
      border-top: 0.5px solid rgba(220, 220, 220, 0.7);
      padding: 10px 20px 25px 20px;
      overflow-x: hidden;
      overflow-y: auto;
      &-list {
        margin: 12px 0;
        width: 470px;
        height: 21px;
        flex: 1;
        display: flex;
        color: #28282d;
        line-height: 21px;
        &-left {
          text-align: left;
          width: 80px;
          font-size: 15px;
          font-family: PingFangSC-Medium;
          min-width: 80px;
        }
        &-right {
          font-size: 15px;
        }
        div {
          flex: 1;
          display: flex;
          color: #28282d;
          line-height: 21px;
        }
      }
      &-list-margin {
        margin-top: 30px;
      }
      &-other {
        display: block;
        width: 440px;
        height: 120px;
        background: #f4f4f4;
        min-height: 120px;
        max-height: 150px;
        padding: 15px;
        line-height: 20px;
        font-size: 14px;
        text-align: left;
        color: #28282d;
        overflow-x: hidden;
        overflow-y: auto;
      }
    }
    &-close {
      cursor: pointer;
      position: absolute;
      top: 10px;
      right: 5px;
      width: 40px;
      height: 40px;
      background: url(https://static.bokao2o.com/wisdomCashier/images/Icon_X_Gray.png)
        center / 28px 28px no-repeat;
      border: none;
      outline: none;
      box-sizing: border-box;
    }
    &-foot {
      width: 100%;
      border-radius: 0;
      button {
        cursor: pointer;
        width: 100%;
        padding: 0 10px;
        line-height: 40px;
        height: 40px;
        background: #28282d;
        // border-radius: 5px;
        font-size: 15px;
        color: #fff;
        border: none;
        outline: none;
        box-sizing: border-box;
      }
    }
  }
  &-box-active {
    top: 12%;
    width: 510px;
    max-height: 80%;
    border-radius: 6px;
    background: rgb(255, 255, 255);
    overflow: auto;
    box-shadow: rgb(51, 51, 51) 0px 0px 20px 0px;
  }
}
.vipInfo-active {
  position: fixed;
  width: 100vw;
  height: 100vh;
  opacity: 1;
  z-index: 3000;
  overflow: auto;
}
</style>

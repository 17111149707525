<template>
  <div class="keys">
    <!-- 数字键盘 -->
    <el-dialog
      title="单价修改"
      :visible.sync="ShowAlert"
      :append-to-body="ShowAlert"
      @close="hide(1)"
      :close-on-click-modal="false"
    >
      <div class="keys-box" v-if="getOrderData.data[orderDatasIndex]">
        <div class="keys-box-input">
          <div class="keys-box-input-item">
            <span>原价</span>
            <div class="keys-box-input-item-val">
              {{ getOrderData.data[orderDatasIndex].oldpirce }}
            </div>
          </div>
          <div
            class="keys-box-input-item"
            @click="active = 1"
            :class="active == 1 ? 'keys-box-input-active' : ''"
          >
            <span>
              折扣价
            </span>
            <div class="keys-box-input-item-val" v-if="val[0].num">
              {{ val[0].num }}
            </div>
            <!-- <div
              class="keys-box-input-item-val"
              v-else-if="getOrderData.data[orderDatasIndex].oldpirce"
            >
              {{ getOrderData.data[orderDatasIndex].oldpirce }}
            </div> -->
            <div class="keys-box-input-item-val" v-else>
              {{ getOrderData.data[orderDatasIndex].pirce }}
            </div>
          </div>
        </div>
        <div class="keys-box-input" v-if="false">
          <div
            class="keys-box-input-item"
            @click="active = 2"
            :class="active == 2 ? 'keys-box-input-active' : ''"
          >
            <span>折扣</span>
            <div class="keys-box-input-item-val" v-if="val[1].num">
              {{ val[1].num }}
            </div>
            <div
              class="keys-box-input-item-val"
              v-else-if="getOrderData.data[orderDatasIndex].discount"
            >
              {{ getOrderData.data[orderDatasIndex].discount }}
            </div>
            <div class="keys-box-input-item-val" v-else-if="getUser.name">
              <label v-if="getOrderData.data[orderDatasIndex].type == 2">{{
                getUser.membertype.project_disc
              }}</label>
              <label v-if="getOrderData.data[orderDatasIndex].type == 3">{{
                getUser.membertype.product_disc
              }}</label>
            </div>
            <div class="keys-box-input-item-val" v-else>
              1
            </div>
          </div>
          <div
            class="keys-box-input-item"
            @click="active = 3"
            :class="active == 3 ? 'keys-box-input-active' : ''"
          >
            <span>
              折后价
            </span>
            <div class="keys-box-input-item-val" v-if="val[2].num">
              {{ val[2].num }}
            </div>
            <div
              class="keys-box-input-item-val"
              v-else-if="getOrderData.data[orderDatasIndex].Newpirce"
            >
              {{ getOrderData.data[orderDatasIndex].Newpirce }}
            </div>
            <div class="keys-box-input-item-val" v-else>
              {{ getOrderData.data[orderDatasIndex].pirce }}
            </div>
          </div>
        </div>

        <div class="key">
          <div class="key-left">
            <div
              class="key-left-item"
              :class="indexs == index ? 'key-left-item-active' : ''"
              @click="nums(item, index)"
              v-for="(item, index) in keyData"
              :key="index"
            >
              <img
                src="https://static.bokao2o.com/wisdomDesk/images/PriceKey_Del.png"
                alt=""
                v-if="index > 10"
              />
              <span v-else>{{ item }}</span>
            </div>
            <div
              class="key-left-item"
              :class="indexs == 11 ? 'key-left-item-active' : ''"
              @click="delNum(11)"
            >
              <img
                src="https://static.bokao2o.com/wisdomDesk/images/PriceKey_Del.png"
                alt=""
              />
            </div>
          </div>
          <div class="key-right">
            <button class="key-right" @click="removes">重输</button>
            <button class="key-right" @click="pay">确定</button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "pricekeys",
  props: ["orderDatasIndex"],
  data() {
    return {
      Show: false,
      keyData: ["1", "2", "3", "4", "5", "6", "7", "8", "9", ".", "0"],
      val: [
        { id: 0, num: "" },
        { id: 1, num: "" },
        { id: 2, num: "" },
      ],
      active: 0,
      indexs: -1,
      updateStatus: false,
    };
  },
  methods: {
    hide(obj) {
      let val = JSON.parse(JSON.stringify(this.val));
      this.$store.commit("priceKeyStatus", false);
      let d = this.getOrderData;
      d.data[this.orderDatasIndex].Newpirce =val[2].num;
      // d.data[this.orderDatasIndex].oldpirce = 88;
      d.data[this.orderDatasIndex].discount = val[1].num - 0;
      // getOrderData.data[orderDatasIndex].oldpirce

      // console.log(JSON.parse(JSON.stringify(d.data[this.orderDatasIndex].pirce)))

      if(!obj){
        d.data[this.orderDatasIndex].pirce = val[0].num;
      }

      this.updateStatus = false;
      this.$store.commit("SetLoadOrder", d);
      let _this = this;
      setTimeout(() => {
        _this.val = [
          { id: 0, num: "" },
          { id: 1, num: "" },
          { id: 2, num: "" },
        ];
      }, 500);
    },
    nums(val, i) {
      this.indexs = i;
      let index = this.active;
      console.log(this.active);
      if (index == "") {
        return false;
      }
      /* 收款金额 */
      //   /* 第一位不能为零或点 */
      if (!this.val[index - 1].num.length) {
        if (val == ".") {
          return false;
        }

        this.val[index - 1].num += val;
        this.discount();
        return false;
      } else {
        if (
          this.val[index - 1].num[0] == "0" &&
          val != "." &&
          this.val[index - 1].num.length < 2
        ) {
          this.val[index - 1].num = val;
          return false;
        }
      }
      /* 只允许存在一个点 */
      if (this.val[index - 1].num.length) {
        if (this.val[index - 1].num.includes(".") && val == ".") {
          return false;
        }

        if (
          this.val[index - 1].num[0] == "." &&
          val == "." &&
          this.val[index - 1].num.length == 0
        ) {
          return false;
        }
        if (
          this.val[index - 1].num[0] == "0" &&
          this.val[index - 1].num.length == 1
        ) {
          this.val[index - 1].num += val;
          return false;
        }

        this.val[index - 1].num += val;
        this.discount();
        /* 最多支持20位 */
        if (this.val[index - 1].num - 0 >= 99999) {
          this.$message({
            message: "最大不能超过99999~",
            type: "warning",
          });
          this.val[index - 1].num = "99999";
          this.discount();
          return false;
        }
        this.discount();
        return false;
      }
    },
    removes() {
      /* 清空 */
      console.log(1111);
      if (this.active == "") {
        return false;
      }

      if (this.active == 1) {
        this.val[this.active - 1].num = "";
        this.discount();
        return false;
      }

      if (this.active == 3) {
        this.val[this.active - 1].num = "";
        this.discount();
        return false;
      }

      if (this.active == 2) {
        this.val[1].num = "1";
        this.discount();
        return false;
      }
      // this.val[this.active - 1].num = "";
    },
    delNum(index) {
      /* 退格 */
      if (this.active == "") {
        return false;
      }
      this.indexs = index;
      // this.discount();
      // if (this.active == 2 && this.val[this.active - 1].num.length <= 1) {
      //   this.val[this.active - 1].num = "1";
      //   this.discount();
      //   return false;
      // }
      this.val[this.active - 1].num = this.val[this.active - 1].num.substring(
        0,
        this.val[this.active - 1].num.length - 1
      );
      this.discount();
    },

    pay() {
      // if (!this.val[0].num) {
      //   this.$message({
      //     message: "请输入折前价格",
      //     type: "warning",
      //   });
      //   return false;
      // }
      // if (!this.val[1].num) {
      //   this.$message({
      //     message: "请输入折扣",
      //     type: "warning",
      //   });
      //   return false;
      // }
      // if (!this.val[2].num) {
      //   this.$message({
      //     message: "请输入折后价格",
      //     type: "warning",
      //   });
      //   return false;
      // }
      this.hide();
      this.$message({
        message: "修改成功",
        type: "success",
      });
    },
    /* 计算折扣 */
    discount() {

      
      this.val[1].num =(this.val[0].num/ this.getOrderData.data[this.orderDatasIndex].oldpirce).toFixed(2);


      console.log(111111,this.val[1].num)
      // this.val[1].num = this.val[1].num || 1;
      // this.val[0].num =

      //     ? this.val[0].num
      //     : this.getOrderData.data[this.orderDatasIndex].oldpirce
      //     ? this.getOrderData.data[this.orderDatasIndex].oldpirce
      //     : this.getOrderData.data[this.orderDatasIndex].pirce;
      // if (this.getUser.name) {
      //   if (this.getOrderData.data[this.orderDatasIndex].type == 2) {
      //     this.getUser.membertype.project_disc = this.val[1].num - 0 * 100;
      //   }
      //   if (this.getOrderData.data[this.orderDatasIndex].type == 3) {
      //     this.getUser.membertype.product_disc = this.val[1].num - 0 * 100;
      //   }
      // }

      // if (
      //   this.getOrderData.data[this.orderDatasIndex].oldpirce &&
      //   !this.val[0].num.length
      // ) {
      //   this.val[0].num = this.getOrderData.data[this.orderDatasIndex].oldpirce;
      // } else if (
      //   this.getOrderData.data[this.orderDatasIndex].pirce &&
      //   !this.val[0].num.length
      // ) {
      //   this.val[0].num = this.getOrderData.data[this.orderDatasIndex].pirce;
      // }

      // if (
      //   this.getOrderData.data[this.orderDatasIndex].Newpirce &&
      //   !this.val[2].num.length
      // ) {
      //   this.val[2].num = this.getOrderData.data[this.orderDatasIndex].Newpirce;
      // } else if (
      //   this.getOrderData.data[this.orderDatasIndex].pirce &&
      //   !this.val[2].num.length
      // ) {
      //   this.val[2].num = this.getOrderData.data[this.orderDatasIndex].pirce;
      // }

      // if (
      //   !this.getOrderData.data[this.orderDatasIndex].discount &&
      //   !this.val[1].num.length
      // ) {
      //   this.val[1].num =
      //     (this.getOrderData.data[this.orderDatasIndex].pirce - 0) /
      //     (this.getOrderData.data[this.orderDatasIndex].pirce - 0);
      // }

      // if (this.active != 2) {
      //   this.val[1].num =
      //     this.val[0].num - 0
      //       ? (this.val[2].num - 0) / (this.val[0].num - 0)
      //       : 1;
      //   this.val[1].num = this.val[1].num.toFixed(2);
      // }

      if (this.active == 2) {
        this.val[2].num = (this.val[1].num - 0) * (this.val[0].num - 0);
        if (this.val[2].num > 99999) {
          this.val[2].num = 99999;
          this.val[1].num = 1;
        }
      }

      if (this.active == 1) {
        this.val[2].num = (this.val[0].num - 0) * (this.val[1].num - 0);
        if (this.val[2].num > 99999) {
          this.val[2].num = 99999;
          this.val[1].num = 1;
        }
      }
      // if (this.active == 3) {
      //   this.val[0].num = (this.val[2].num - 0) / (this.val[1].num - 0);
      //   console.log(this.val[1].num)
      // }

      // this.val[1].num =
      //     this.val[0].num - 0
      //       ? (this.val[2].num - 0) / (this.val[0].num - 0)
      //       : 1;
      //   this.val[1].num = this.val[1].num.toFixed(2);
    },
  },
  computed: {
    ShowAlert: {
      get() {
        return this.$store.state.order.priceKey;
      },
      set() {
        return this.$store.state.order.priceKey;
      },
    },
    getOrderData: {
      get() {
        return this.$store.state.order.loadorder;
      },
      set() {
        return this.$store.state.order.loadorder;
      },
    },
    getUser: {
      get() {
        return this.$store.state.order.user;
      },
      set() {
        return this.$store.state.order.user;
      },
    },
  },
  created() {
    let _this = this;
    document.addEventListener("keydown", function(e) {
      /* 回车支付 */
      if (_this.$store.state.order.priceKey && e.keyCode == 13) {
        e.preventDefault();
        _this.pay();
      }
      /* 退格键 */
      if (_this.$store.state.order.priceKey && e.keyCode == 8) {
        e.preventDefault();
        _this.delNum();
      }

      /* tab切换输入框 */
      if (_this.$store.state.order.priceKey && e.keyCode == 9) {
        e.preventDefault();
        _this.active = _this.active > 3 ? 1 : _this.active + 1;
      }
      let num = 0;
      /* 手动输入 */
      if (_this.$store.state.order.priceKey && ((e.key - 0 > -1)  || e.key == ".")) {
        e.preventDefault();
        num++;
        if (num < 2) {
          _this.nums(e.key);
        }
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.el-dialog {
  top: 8%;
  width: 620px;
  max-height: 80%;
  border-radius: 6px;
  background: rgb(255, 255, 255);
  overflow: hidden;
  box-shadow: rgb(51, 51, 51) 0px 0px 20px 0px;
}

.keys-box {
  position: relative;
  left: 0;
  right: 0;
  margin: auto;
  width: 500px;
  height: 50vh;
  padding: 30px 0 25px 0;
  &-input-active {
    border: 1px solid #47bf7c !important;
    box-shadow: 0 0 3px #47bf7c !important;
  }
  &-input {
    position: relative;
    left: 0;
    right: 0;
    margin: auto;
    width: 410px;
    display: flex;
    // padding: 30px 0 25px 0;
    &-item {
      width: 45%;
      height: 68px;
      border: 1px solid rgba(220, 220, 220, 0.7);
      box-shadow: 0 0 3px rgba(220, 220, 220, 0.7);
      border-radius: 6px;
      margin-bottom: 15px;
      padding: 14px 12px;
      box-sizing: border-box;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      span {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-family: PingFangSC-Medium;
        color: #8a8a8a;
      }
      span:first-child {
        width: 30%;
      }
      span:last-child {
        font-size: 15px;
        margin-left: 5px;
        text-align: right;
      }
      &-val {
        width: 70%;
        font-size: 20px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        font-family: PingFangSC-Medium;
        color: #28282d;
      }
    }
    &-item:nth-of-type(odd) {
      margin-right: 5%;
    }
  }
}

.key {
  position: relative;
  margin: 5px auto auto auto;
  display: flex;
  width: 462px;
  height: 320px;
  padding-bottom: 25px;
  //   background: red;
  &-left {
    flex: 1;
    overflow: hidden;
    text-align: center;
    padding: 20px 7px 20px 20px;
    // background: coral;
    &-item-active {
      background: #f4f4f4 !important;
    }
    &-item {
      position: relative;
      float: left;
      cursor: pointer;
      width: 92px;
      height: 58px;
      overflow: hidden;
      border-radius: 6px;
      box-shadow: 0 0 3px rgba(220, 220, 220, 0.568);
      margin-right: 12px;
      margin-bottom: 12px;
      z-index: 10;
      background: #ffffff;
      color: #000;
      left: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: PingFangSC-Medium;
      font-size: 40px;
      img {
        height: 60%;
        width: 45%;
      }
    }
  }
  &-right {
    width: 97px;
    padding: 20px 20px 0 5px;
    // background: darkblue;
    button {
      position: relative;
      width: 72px;
      height: 128px;
      overflow: hidden;
      border-radius: 6px;
      box-shadow: 0 0 3px rgba(220, 220, 220, 0.7);
      font-size: 18px;
      padding: 0;
      color: #28282d;
      margin-bottom: 12px;
      // background: transparent;
      border: none;
      outline: none;
      box-sizing: border-box;
      cursor: pointer;
      padding: 0;
    }
    button:first-child {
      background: #ffffff;
      color: #28282d;
    }
    button:last-child {
      background: #28282d;
      color: #fff;
    }
  }
}
</style>

<style>
.keys .el-dialog {
  /* top: 8%; */
  width: 620px;
  max-height: 80%;
  border-radius: 6px;
  background: rgb(255, 255, 255);
  overflow: hidden;
  box-shadow: rgb(51, 51, 51) 0px 0px 20px 0px;
}
</style>

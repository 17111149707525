<template>
  <div class="billing" :class="billShow ? 'billing-active' : ''">
    <div class="billing-left">
      <div class="billing-left-head">
        <div class="billing-left-head-l" @click="hide"></div>
        <div class="billing-left-head-c">
          <button
            @click="tab(-1)"
            v-if="user.name"
            :class="toptabIndexs == -1 ? 'billing-left-head-c-btn' : ''"
          >
            会员
          </button>
          <button
            @click="tab(0)"
            :class="
              toptabIndex == 0 && toptabIndexs != -1
                ? 'billing-left-head-c-btn'
                : ''
            "
          >
            {{ tabArr[0].name }}
          </button>
          <button
            @click="tab(1)"
            :class="toptabIndex == 1 ? 'billing-left-head-c-btn' : ''"
          >
            {{ tabArr[1].name }}
          </button>
          <button
            @click="tab(2)"
            :class="toptabIndex == 2 ? 'billing-left-head-c-btn' : ''"
          >
            {{ tabArr[2].name }}
          </button>
        </div>
        <div class="billing-left-head-r">
          <button
            @click="searchClear(0)"
            :class="searchStatus ? 'billing-left-head-r-btns' : ''"
          ></button>
          <button @click="searItemStatus = !searItemStatus"></button>
        </div>
      </div>

      <div class="billing-left-cont">
        <div
          class="billing-left-cont-head"
          v-show="!searchStatus"
          :style="{
            padding: tabArr[toptabIndex].data.length ? '0 15px 15px 15px' : '0',
          }"
        >
          <ul
            class="ul"
            :class="tabStatus ? 'ul-active' : ''"
            v-if="
              tabArr[toptabIndex].data.length &&
                toptabIndexs != -1 &&
                toptabIndexs != 2
            "
          >
            <li
              class="li"
              v-for="(item, index) in tabArr[toptabIndex].data"
              :key="index"
              @click="tabItem(index)"
              :class="tabIndex == index ? 'li-active' : ''"
            >
              {{ item.name }}
            </li>
            <button
              class="ul-button"
              @click="tabStatus = !tabStatus"
              :class="tabStatus ? 'ul-button-active' : ''"
            ></button>
          </ul>
          <ul
            class="ul"
            :class="tabStatus ? 'ul-active' : ''"
            v-if="toptabIndexs == 2"
          >
            <li class="li">{{ dates }}</li>
          </ul>
          <ul
            class="ul uls"
            :class="tabStatus ? 'ul-active' : ''"
            v-if="user.name && toptabIndexs == -1"
          >
            <li class="li lis">
              名称
            </li>
            <li class="li lis">
              类型
            </li>
            <li class="li lis">
              总次数
            </li>
            <li class="li lis">
              剩余次数
            </li>
          </ul>
        </div>

        <div class="billing-left-cont-head" v-show="searchStatus">
          <div class="billing-left-cont-head-search">
            <el-input
              placeholder="请输入名称查询"
              v-model="searchKey"
              clearable
              @clear="searchClear(1)"
            >
            </el-input>
            <button @click="search">搜索</button>
          </div>
        </div>

        <div
          class="billing-left-cont-box"
          v-if="
            tabArr[toptabIndex].data.length &&
              toptabIndexs != -1 &&
              toptabIndexs != 2
          "
        >
          <div
            class="billing-left-cont-box-item"
            v-for="(item, index) in tabArr[toptabIndex].data[tabIndex].projects"
            :key="index"
            :data-id="item.id"
            @click="addPeople(item, toptabIndexs)"
          >
            <div class="billing-left-cont-box-item-img">
              <img :src="item.picture" :alt="item.name" v-if="item.picture" />
              <img
                src="../../assets/none.png"
                :alt="item.name"
                v-if="!item.picture"
              />
            </div>
            <div class="billing-left-cont-box-item-num">{{ item.name }}</div>
            <div class="billing-left-cont-box-item-num">￥{{ item.price }}</div>
          </div>
        </div>

        <!-- 预约 -->
        <div
          class="billing-left-cont-box"
          v-if="tabArr[toptabIndex].data.length && toptabIndexs == 2"
        >
          <div
            class="billing-left-cont-box-item"
            style="width:360px;min-height:194px;height:auto;"
            v-for="(item, index) in tabArr[toptabIndex].data"
            :key="index"
            @click="setyue(item)"
          >
            <div
              v-if="yuyueID == item.id"
              style="background: url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Check_S.png) 10px center / 28px no-repeat rgb(248 248 248);width: 50px;height: 50px;position: absolute;top: 0;eft: 0;"
            ></div>
            <!-- {{item}} -->
            <div class="billing-left-cont-box-item-title">
              <p>
                预约项目：{{
                  item.project_name.replace(/\|/g, ",").name
                    ? 111
                    : item.project_name.replace(/\|/g, ",")
                }}
              </p>
            </div>

            <div class="billing-left-cont-box-item-num" style="font-size:15px;">
              <p
                style="background: #f4f4f4;margin:0;text-align: left;padding-left:10px;"
              >
                顾客姓名：{{ item.name }}
              </p>
              <p
                style="background: #f4f4f4;margin:0;text-align: left;padding-left:10px;"
              >
                顾客电话：{{ item.phone }}
              </p>
              <p
                style="background: #f4f4f4;margin:0;text-align: left;padding-left:10px;"
              >
                预约时长：{{ $minutes(item.time_begin, item.time_end) }}分钟
              </p>
            </div>
          </div>
        </div>
        <!-- 会员项目 -->
        <div
          class="billing-left-cont-box billing-left-cont-box-none"
          v-if="!tabArr[toptabIndex].data.length && toptabIndexs != -1"
        >
          啥也没有~
        </div>

        <div
          class="billing-left-cont-box"
          v-if="user.name && toptabIndexs == -1"
        >
          <div class="billing-left-cont-box-vip">
            <div
              class="billing-left-cont-box-vip-list"
              v-for="(item, index) in user.vip"
              :key="index"
              @click="addPeople(item, -1)"
            >
              <div>{{ item.name }}</div>
              <div>{{ item.type || "计次卡" }}</div>
              <div>{{ item.total_count }} 次</div>
              <div>{{ item.balance_count }} 次</div>
              <button @click.stop="showVipInfo(index)"></button>
            </div>
          </div>
        </div>

        <!-- 根据价格 字母搜索 -->
        <div
          class="billing-search-box"
          :class="searItemStatus ? 'billing-search-box-active' : ''"
          @click.self="searchTabSuccess"
        >
          <div class="billing-search-box-cont">
            <div class="billing-search-box-cont-item">
              <div class="billing-search-box-cont-item-title">
                价格区间（元）
              </div>
              <div class="billing-search-box-cont-item-box">
                <div
                  class="billing-search-box-cont-item-box-list"
                  :class="
                    priceArrIndex == index
                      ? 'billing-search-box-cont-item-box-list-active'
                      : ''
                  "
                  @click="searchTab(index, 0)"
                  v-for="(item, index) in priceArr"
                  :key="index"
                >
                  {{ item }}
                </div>
              </div>
            </div>

            <div class="billing-search-box-cont-item" v-if="0">
              <div class="billing-search-box-cont-item-title">
                拼音首字母
              </div>
              <div class="billing-search-box-cont-item-box">
                <div
                  class="billing-search-box-cont-item-box-list"
                  :class="
                    nameArrIndex == index
                      ? 'billing-search-box-cont-item-box-list-active'
                      : ''
                  "
                  @click="searchTab(index, 1)"
                  style="width: 56px;margin-right: 9px;"
                  v-for="(item, index) in nameArr"
                  :key="index"
                >
                  {{ item }}
                </div>
              </div>
            </div>

            <div
              class="billing-search-box-cont-item"
              style="height:50px;margin-top: 30px;"
            >
              <el-button @click="searchTabRemove">清空</el-button>
              <el-button type="primary" @click="searchTabSuccess"
                >确认</el-button
              >
            </div>
          </div>
        </div>
        <!-- 根据价格 字母搜索 -->
      </div>

      <!-- 显示详细信息（价格） -->

      <div class="price-info" :class="priceInfo ? 'price-info-active' : ''">
        <div v-if="priceInfo">
          <div class="price-info-head">
            {{ getOrderData.data[orderDatasIndex].name }}
            <button @click="priceInfo = !priceInfo"></button>
          </div>
          <div class="price-info-cont">
            <div class="price-info-cont-item">
              <span>数量</span>
              <div class="price-info-cont-item-num">
                <el-input-number
                  v-model="getOrderData.data[orderDatasIndex].num"
                  @change="priceInfoNum"
                  :min="1"
                  label="描述文字"
                ></el-input-number>
              </div>
            </div>
            <div class="price-info-cont-text">
              注：折后价修改后会受账户折扣影响。
            </div>
            <div class="price-info-cont-list">
              <div class="price-info-cont-list-item">
                <span>原价</span>
                <div class="price-info-cont-list-item-cont" style="color:#ccc">
                  ￥{{ getOrderData.data[orderDatasIndex].oldpirce }}
                </div>
              </div>
              <div class="price-info-cont-list-item" @click="priceKey">
                <span>折前价</span>
                <div
                  class="price-info-cont-list-item-cont price-info-cont-list-item-cont-next"
                >
                  ￥{{
                    (getOrderData.data[orderDatasIndex].pirce - 0).toFixed(2)
                  }}
                </div>
              </div>
              <!-- <div class="price-info-cont-list-item" @click="priceKey" v-if="user.name">
                <span>折扣</span>
                <div
                  class="price-info-cont-list-item-cont price-info-cont-list-item-cont-next"
                >
                  <span v-if="getOrderData.data[orderDatasIndex].type == 2">{{ user.membertype.project_disc || 1 }}</span>
                  <span v-if="getOrderData.data[orderDatasIndex].type == 3">{{ user.membertype.product_disc || 1 }}</span>
                </div>
              </div>
              <div class="price-info-cont-list-item" @click="priceKey" v-if="!user.name">
                <span>折扣</span>
                <div
                  class="price-info-cont-list-item-cont price-info-cont-list-item-cont-next"
                >
                  {{ getOrderData.data[orderDatasIndex].discount || 1 }}
                </div>
              </div> -->

              <div
                class="price-info-cont-list-item"
                @click="priceKey"
                v-if="false"
              >
                <span>折后价</span>
                <div
                  class="price-info-cont-list-item-cont price-info-cont-list-item-cont-next"
                >
                  ￥
                  <span v-if="getOrderData.data[orderDatasIndex].type == 2">{{
                    (
                      user.membertype.project_disc *
                      getOrderData.data[orderDatasIndex].pirce
                    ).toFixed(2)
                  }}</span>
                  <span v-if="getOrderData.data[orderDatasIndex].type == 3">{{
                    (
                      user.membertype.product_disc *
                      getOrderData.data[orderDatasIndex].pirce
                    ).toFixed(2)
                  }}</span>
                </div>
              </div>
              <div
                class="price-info-cont-list-item"
                @click="priceKey"
                v-if="false"
              >
                <span>折后价</span>
                <div
                  class="price-info-cont-list-item-cont price-info-cont-list-item-cont-next"
                >
                  ￥
                  <span v-if="getOrderData.data[orderDatasIndex].discount">{{
                    (
                      getOrderData.data[orderDatasIndex].pirce *
                      getOrderData.data[orderDatasIndex].discount
                    ).toFixed(2)
                  }}</span>
                  <span v-if="!getOrderData.data[orderDatasIndex].discount">{{
                    (getOrderData.data[orderDatasIndex].pirce * 1).toFixed(2)
                  }}</span>
                </div>
              </div>
            </div>

            <div class="price-info-cont-list" v-if="0">
              <div class="price-info-cont-list-item">
                <span>服务人员</span>
                <div
                  class="price-info-cont-list-item-cont price-info-cont-list-item-cont-next"
                  @click="selectItem(orderDatasIndex)"
                >
                  <span
                    v-for="(item, index) in getOrderData.data[orderDatasIndex]
                      .people"
                    :key="index"
                    >{{ item.peopel }};</span
                  >
                </div>
              </div>
            </div>
            <div class="price-info-cont-list">
              <div class="price-info-cont-list-item" style="height:auto">
                <span>备注</span>
                <div class="price-info-cont-list-item-cont">
                  <el-input
                    type="textarea"
                    v-model="getOrderData.data[orderDatasIndex].other"
                    :rows="6"
                    placeholder="请填写备注"
                  >
                  </el-input>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 显示详细信息（价格） -->
    </div>

    <div class="billing-right">
      <div class="billing-right-head">
        <div class="billing-right-head-title">单据</div>
        <div class="billing-right-head-vip" v-if="user.name">
          <div class="billing-right-head-vip-img">
            <img
              :src="
                user.head_picture
                  ? user.head_picture
                  : 'https://static.bokao2o.com/wisdomCashier/images/Icon_noLogo.jpg?imageView2/1/w/200/h/200/interlace/1/q/100'
              "
              alt=""
            />
          </div>
          <div class="billing-right-head-vip-name">{{ user.name }}</div>
          <button @click="removeUser"></button>
        </div>
        <div class="billing-right-head-sex" v-if="user.name">
          <button
            class="billing-right-head-sex-g"
            @click="user.sex = 0"
            :class="user.sex - 0 == 0 ? 'billing-right-head-sex-g-active' : ''"
          ></button>
          <button
            class="billing-right-head-sex-b"
            @click="user.sex = 1"
            :class="user.sex - 0 == 1 ? 'billing-right-head-sex-b-active' : ''"
          ></button>
        </div>
        <div class="billing-right-head-sex" v-if="!user.name">
          <button
            class="billing-right-head-sex-g"
            @click="userSex(0)"
            :class="sex == 0 ? 'billing-right-head-sex-g-active' : ''"
          ></button>
          <button
            class="billing-right-head-sex-b"
            @click="userSex(1)"
            :class="sex == 1 ? 'billing-right-head-sex-b-active' : ''"
          ></button>
        </div>
        <div class="billing-right-head-selectvip" v-if="!user.name">
          <button class="billing-right-head-selectvip-btn" @click="selectVip">
            选择会员
          </button>
        </div>
        <button class="billing-right-head-more" @click="weiShow"></button>
      </div>
      <div class="billing-right-cont" v-if="getOrderData.data.length">
        <div
          class="billing-right-cont-item"
          v-for="(item, index) in getOrderData.data"
          :key="index"
          @click="selectItem(index)"
        >
          <div class="billing-right-cont-item-l">
            <img :src="item.picture" :alt="item.name" v-if="item.picture" />
            <img
              src="../../assets/none.png"
              :alt="item.name"
              v-if="!item.picture"
            />
          </div>
          <div class="billing-right-cont-item-r">
            <div class="billing-right-cont-item-r-title">
              <p>{{ item.name }}</p>
              <div class="billing-right-cont-item-r-title-num">
                <span style="font-size:12px;color:#777777;margin-left:10px"
                  >x</span
                >
                {{ item.num || "1" }}
              </div>
            </div>
            <div class="billing-right-cont-item-r-price">
              <div
                class="billing-right-cont-item-title-right"
                @click.stop="orderDatasIndex = index"
              >
                <!-- priceInfo = !priceInfo;  不修改价格 -->
                <!-- {{ item }} -->
                <span
                  v-if="!item.member_price_status"
                  style="color:#777777;font-size:12px;text-decoration:line-through;margin-right:10px; "
                  >原价：￥{{ item.oldpirce * item.num }}</span
                >
                <div
                  v-if="user.name && !item.member_price_status"
                  style="display:inline-block;"
                >
                  <!-- <span v-if="item.type == -1 && item.vip">品项</span> -->
                  <span v-if="item.type == 2">{{
                    item.vip
                      ? "品项"
                      : "￥" +
                        (
                          item.pirce *
                          item.num *
                          user.membertype.project_disc
                        ).toFixed(2)
                  }}</span>
                  <span v-if="item.type == 3">{{
                    item.vip
                      ? "品项"
                      : "￥" +
                        (
                          item.pirce *
                          item.num *
                          user.membertype.product_disc
                        ).toFixed(2)
                  }}</span>
                  <!-- <span v-else>{{
                  "￥" +
                    (
                      item.pirce *
                      item.num *
                      user.membertype.product_disc
                    ).toFixed(2)
                }}</span> -->
                </div>
                <div v-if="!user.name" style="display:inline-block;">
                  <span v-if="item.discount"
                    >￥{{ (item.pirce * item.num).toFixed(2) }}</span
                  >

                  <span v-if="!item.discount"
                    >￥{{ (item.pirce * item.num).toFixed(2) }}</span
                  >
                </div>
                <!-- 会员价格 -->
                <div
                  v-if="user.name && item.member_price_status"
                  style="display:inline-block;"
                >
                  <span
                    >会员价：￥{{
                      (item.member_price * item.num).toFixed(2)
                    }}</span
                  >
                </div>
                <!-- 会员价格 -->

                <lable
                  v-if="user.name && !item.member_price_status"
                  style="margin-left:10px;"
                >
                  <em v-if="item.type == 2 && user.membertype.project_disc < 1"
                    >{{ user.membertype.project_disc * 100 }} 折</em
                  >
                  <em v-if="item.type == 2 && user.membertype.project_disc > 1"
                    >{{ user.membertype.project_disc * 100 }} 折</em
                  >
                  <em v-if="item.type == 3 && user.membertype.product_disc < 1"
                    >{{ user.membertype.product_disc * 100 }}折</em
                  >
                  <em v-if="item.type == 3 && user.membertype.product_disc > 1"
                    >{{ user.membertype.product_disc * 100 }} 折</em
                  >
                  <em v-if="item.type == -1">品项抵扣</em>
                </lable>

                <lable v-if="item.discount < 1 && item.discount && !user.name">
                  <em>{{ item.discount.toFixed(2) }}折</em>
                </lable>
                <lable v-if="item.discount > 1 && !user.name">
                  <em>{{ (item.discount * 10).toFixed(2) }}折</em>
                </lable>
              </div>
            </div>
            <div class="billing-right-cont-item-r-work">
              <div
                class="billing-right-cont-item-list"
                v-if="!item.people.length"
              >
                <div class="billing-right-cont-item-list-noneText">
                  未设置服务人员
                  <!-- <span><i class="el-icon-edit"></i></span> -->
                </div>
              </div>
              <div v-if="item.people.length == 1">
                <span>员工：</span>
                <span
                  >{{ item.people[0].name }} ({{ item.people[0].workID }})</span
                >
                <span><i class="el-icon-edit"></i></span>
              </div>
              <div v-if="item.people.length > 1">
                <span>员工：</span>
                <span
                  >包含{{ item.people[0].name }} ({{
                    item.people[0].workID
                  }})等{{ item.people.length }}名员工</span
                >
                <span><i class="el-icon-edit"></i></span>
              </div>
            </div>
          </div>
          <div v-if="0">
            <div class="billing-right-cont-item-title">
              <div
                class="billing-right-cont-item-title-left"
                @click.stop="
                  priceInfo = !priceInfo;
                  orderDatasIndex = index;
                "
              >
                {{ item.name }} X {{ item.num || "1" }}
              </div>
              <div
                class="billing-right-cont-item-title-right"
                @click.stop="
                  priceInfo = !priceInfo;
                  orderDatasIndex = index;
                "
              >
                <lable v-if="user.name">
                  <em v-if="item.type == 2 && user.membertype.project_disc < 1"
                    >{{ user.membertype.project_disc }} 折</em
                  >
                  <em v-if="item.type == 2 && user.membertype.project_disc > 1"
                    >{{ user.membertype.project_disc * 100 }} 折</em
                  >
                  <em v-if="item.type == 3 && user.membertype.product_disc < 1"
                    >{{ user.membertype.product_disc }} 折</em
                  >
                  <em v-if="item.type == 3 && user.membertype.product_disc > 1"
                    >{{ user.membertype.product_disc * 100 }} 折</em
                  >
                  <em v-if="item.type == -1">品项抵扣</em>
                </lable>

                <lable v-if="item.discount < 1 && item.discount && !user.name">
                  <em>{{ item.discount.toFixed(2) }}折</em>
                </lable>
                <lable v-if="item.discount > 1 && !user.name">
                  <em>{{ (item.discount * 10).toFixed(2) }}折</em>
                </lable>

                <!-- {{ item }} -->
                <div v-if="user.name" style="display:inline-block;">
                  <!-- <span v-if="item.type == -1 && item.vip">品项</span> -->
                  <span v-if="item.type == 2">{{
                    item.vip
                      ? "品项"
                      : "￥" +
                        (
                          item.pirce *
                          item.num *
                          user.membertype.project_disc
                        ).toFixed(2)
                  }}</span>
                  <span v-if="item.type == 3">{{
                    item.vip
                      ? "品项"
                      : "￥" +
                        (
                          item.pirce *
                          item.num *
                          user.membertype.product_disc
                        ).toFixed(2)
                  }}</span>
                  <!-- <span v-else>{{
                  "￥" +
                    (
                      item.pirce *
                      item.num *
                      user.membertype.product_disc
                    ).toFixed(2)
                }}</span> -->
                </div>
                <div v-if="!user.name" style="display:inline-block;">
                  <span v-if="item.discount">{{
                    (item.pirce * 1).toFixed(2)
                  }}</span>
                  <span v-if="!item.discount">{{
                    (item.pirce - 0).toFixed(2)
                  }}</span>
                </div>
              </div>
            </div>
            <div
              class="billing-right-cont-item-list"
              v-if="!item.people.length"
            >
              <div class="billing-right-cont-item-list-noneText">
                未设置服务人员
              </div>
            </div>
            <div
              class="billing-right-cont-item-lists"
              v-if="item.people.length"
            >
              <div v-for="(items, indexs) in item.people" :key="indexs">
                <div v-if="items">
                  <img :src="items.photo" alt="" v-if="items.photo" />
                  <img
                    v-else
                    src="https://static.bokao2o.com/8db17acb4b96eb06c5df7a1d6f8750fd.jpg?imageView2/1/w/200/h/200/interlace/1/q/100"
                    alt=""
                  />
                  <span>{{ items.name }}（{{ items.workID }}）</span>
                  <!-- <em>{{ items.workID }}</em> -->
                  <span></span>
                  <!-- <span>编号：{{ items.workID }}</span> -->
                </div>
              </div>
              <!-- <div v-for="(items, indexs) in item.people" :key="indexs">
              <div v-if="items">
                <img :src="items.img" alt="" v-if="items.img" />
                <img
                  v-else
                  src="https://static.bokao2o.com/8db17acb4b96eb06c5df7a1d6f8750fd.jpg?imageView2/1/w/200/h/200/interlace/1/q/100"
                  alt=""
                />
                <span>{{ items.name }}</span>
                <em>{{ items.type[0] }}</em>
                <span>{{ items.peopel }}</span>
              </div>
            </div> -->
            </div>
          </div>
          <div class="billing-right-cont-item-lists-other" v-show="item.other">
            备注：{{ item.other }}
          </div>
          <button @click.stop="removePeopel(index)"></button>
        </div>
      </div>
      <div class="billing-right-cont" v-if="!getOrderData.data.length"></div>
      <div class="billing-right-foot">
        <div class="billing-right-foot-other-box">
          <div class="billing-right-foot-other" @click="othersStatus">
            <span class="billing-right-foot-other-remark" v-if="getOrderData.other.length">{{getOrderData.other}}</span>
            <span v-else>单据备注</span>
            <!-- {{ getOrderData.other || "单据备注" }} -->
          </div>
        </div>
        <div class="billing-right-foot-btn">
          <button class="billing-right-foot-btn-b" @click="orderStatus(0)">
            挂单
          </button>
          <button class="billing-right-foot-btn-r" @click="orderStatus(1)">
            结账
          </button>
        </div>
        <div class="billing-right-foot-btn" v-if="0">
          <el-popconfirm title="确定要挂单吗？" @onConfirm="orderStatus(0)">
            <el-button slot="reference"
              ><button class="billing-right-foot-btn-b">挂单</button></el-button
            >
          </el-popconfirm>
          <el-popconfirm title="确定要结账吗？" @onConfirm="orderStatus(1)">
            <el-button slot="reference"
              ><button class="billing-right-foot-btn-r">
                结账
              </button></el-button
            >
          </el-popconfirm>
        </div>
      </div>
    </div>
    <wei :key="temKey + 1" />
    <selectVips :key="temKey + 2" />
    <VipInfo :key="temKey + 3" />
    <personnel
      :orderDatasIndex="orderDatasIndex"
      :vipData="vipData"
      :key="temKey + 4"
    />
    <pricekeys :orderDatasIndex="orderDatasIndex" :key="temKey + 5" />
    <others :orderDatasIndex="orderDatasIndex" :key="temKey + 6" />
    <pays :key="temKey + 7" :orders="orders" :removeVip="removeVip" />
  </div>
</template>

<script>
import wei from "./wei.vue";
import selectVips from "./selectVip.vue";
import VipInfo from "./vipInfo.vue";
// import VipInfo from "./vipInfo.vue";
import personnel from "./personnel.vue";
import pricekeys from "./priceKey.vue";
import others from "./other.vue";
import pays from "./pays.vue";
export default {
  name: "billing",
  components: { wei, selectVips, VipInfo, personnel, pricekeys, others, pays },
  data() {
    return {
      temKey: new Date().getTime(), //获取时间戳，保持组件更新
      toptabIndex: 0, //头部tab、
      toptabIndexs: 0, //点击会员
      tabArr: [], //所以tab数据
      tabStatus: false, //展开分类标识
      tabIndex: 0, //点击分类下标
      date: "", //获取当前时间
      searchStatus: false, //搜索标识
      searchKey: "", //搜索关键字
      searItemStatus: false, //价格首字母搜索标识
      removeVip: 0,
      priceArr: [
        "50以下",
        "50-100",
        "100-150",
        "150-200",
        "200-250",
        "250-300",
        "300-350",
        "350-400",
        "400-500",
        "500-600",
        "600-700",
        "700-800",
        "800-1000",
        "1000-1500",
        "1500-2000",
        "2000以上",
      ],
      priceArrIndex: -1,
      nameArr: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
      nameArrIndex: -1,
      sex: -1, //用户性别
      orderData: {
        id: "",
        order: "",
        name: "",
        data: [],
        pirce: "",
        other: "",
        time: "",
        start_time: "",
        end_time: "",
        time_type: 0,
        source: "",
        customer: "",
        customerNum: "",
        status: 1,
        gerge: false,
        gergrStatus: false,
      }, //订单初始数据
      orderDatasIndex: "", //订单项目下标
      priceInfo: false, //价格信息
      orders: [], //订单数据
      yuyueItem: "", //预约订单
      yuyueID: "", //预约选中订单
      vipData: {}, //会员项目
    };
  },
  methods: {
    /* 关闭开单界面 */
    hide() {
      this.$store.commit("billShowS", false);
      let d = {
        id: "",
        order: "",
        name: "",
        data: [],
        pirce: "",
        other: "",
        time: "",
        start_time: "",
        end_time: "",
        time_type: 0,
        source: "",
        customer: "",
        customerNum: "",
        status: 1,
        gerge: false,
        gergrStatus: false,
      };
      this.getOrderData.data = [];
      this.$store.commit("SetLoadOrder", d);
      this.$store.commit("setUser", []);
      this.toptabIndex = 0;
      this.toptabIndexs = 0;
      this.tabIndex = 0;
    },
    /* 头部tab切换 */
    tab(index) {
      if (index < 0) {
        this.toptabIndexs = index;
        this.toptabIndex = 0;
        return false;
      }
      this.toptabIndex = index;
      this.toptabIndexs = index;
      this.tabIndex = 0;
      if (this.priceArrIndex != -1 || this.nameArrIndex != -1) {
        this.tabArr = JSON.parse(JSON.stringify(this.$store.state.tabData.tab));
      }
    },
    /* 分类tab切换 */
    tabItem(index) {
      this.tabIndex = index;
      if (this.priceArrIndex != -1 || this.nameArrIndex != -1) {
        this.tabArr = JSON.parse(JSON.stringify(this.$store.state.tabData.tab));
      }
    },
    /* 多条件查询 */
    searchTab(index, type) {
      /* 价格 */
      if (!type) {
        this.priceArrIndex = index;
        return false;
      }
      /* 字母 */
      this.nameArrIndex = index;
    },
    /* 清空多条件查询选中类型 */
    searchTabRemove() {
      this.priceArrIndex = -1;
      this.nameArrIndex = -1;
    },
    /* 确定多条件查询 */
    searchTabSuccess() {
      this.searItemStatus = !this.searItemStatus;
      this.search();
      if (this.priceArrIndex == -1 && this.nameArrIndex == -1) {
        this.tabArr = JSON.parse(JSON.stringify(this.$store.state.tabData.tab));
      }
    },
    /* 搜索 */
    search() {
      console.log(
        this.$store.state.tabData.tab[this.toptabIndex],
        this.toptabIndex
      );
      let key = this.searchKey;
      let data = this.$store.state.tabData.tab[this.toptabIndex].data;
      let len = data.length;
      let arr = [];

      // 不存在 多条件
      if (this.priceArrIndex < 0 && this.nameArrIndex < 0) {
        for (let i = 0; i < len; i++) {
          if (data[i].projects && i > 0) {
            for (let k = 0; k < data[i].projects.length; k++) {
              if (data[i].projects[k].name.includes(key)) {
                // console.log(data[i].data)
                arr.push(data[i].projects[k]);
                // console.log(1)
              }
            }
          }
        }
      }
      // 存在多条件
      let p = "";
      /* 价格搜索 */
      if (this.priceArrIndex != -1 && this.nameArrIndex == -1) {
        if (!this.priceArr[this.priceArrIndex].includes("-")) {
          p = [parseInt(this.priceArr[this.priceArrIndex])];
        }
        if (this.priceArr[this.priceArrIndex].includes("-")) {
          p = this.priceArr[this.priceArrIndex].split("-");
        }
        for (let i = 0; i < len; i++) {
          for (let k = 0; k < data[i].projects.length; k++) {
            if (p.length >= 2) {
              if (
                data[i].projects[k].price - 0 >= p[0] - 0 &&
                data[i].projects[k].price - 0 <= p[1] - 0
              ) {
                arr.push(data[i].projects[k]);
              }
            }

            if (p.length == 1) {
              if (p[0] - 0 == 50) {
                if (data[i].projects[k].price - 0 <= p[0] - 0) {
                  arr.push(data[i].projects[k]);
                }
              }
              if (p[0] - 0 == 2000) {
                if (data[i].projects[k].price - 0 >= p[0] - 0) {
                  arr.push(data[i].projects[k]);
                }
              }
            }
          }
        }
      }
      /* 字母搜索 */
      if (this.nameArrIndex != -1 && this.priceArrIndex == -1) {
        for (let i = 0; i < len; i++) {
          for (let k = 0; k < data[i].projects.length; k++) {
            data[i].projects[k].ping = data[i].projects[
              k
            ].ping.toLocaleUpperCase(); //转换成大写
            if (data[i].projects[k].ping == this.nameArr[this.nameArrIndex]) {
              arr.push(data[i].projects[k]);
            }
          }
        }
      }

      if (this.priceArrIndex != -1 && this.nameArrIndex != -1) {
        if (!this.priceArr[this.priceArrIndex].includes("-")) {
          p = [parseInt(this.priceArr[this.priceArrIndex])];
        }
        if (this.priceArr[this.priceArrIndex].includes("-")) {
          p = this.priceArr[this.priceArrIndex].split("-");
        }
        for (let i = 0; i < len; i++) {
          for (let k = 0; k < data[i].projects.length; k++) {
            data[i].projects[k].ping = data[i].projects[
              k
            ].ping.toLocaleUpperCase(); //转换成大写
            if (p.length >= 2) {
              if (data[i].projects[k].ping == this.nameArr[this.nameArrIndex]) {
                if (
                  data[i].projects[k].price - 0 >= p[0] - 0 &&
                  data[i].projects[k].price - 0 <= p[1] - 0
                ) {
                  arr.push(data[i].projects[k]);
                }
              }
            }

            if (p.length == 1) {
              if (p[0] - 0 == 50) {
                if (
                  data[i].projects[k].price - 0 <= p[0] - 0 &&
                  data[i].projects[k].ping == this.nameArr[this.nameArrIndex]
                ) {
                  arr.push(data[i].projects[k]);
                }
              }
              if (p[0] - 0 == 2000) {
                if (
                  data[i].projects[k].price - 0 >= p[0] - 0 &&
                  data[i].projects[k].ping == this.nameArr[this.nameArrIndex]
                ) {
                  arr.push(data[i].projects[k]);
                }
              }
            }
          }
        }
      }

      /* 价格加字母搜索 */

      console.log(arr);

      this.tabArr[this.toptabIndex].data[this.tabIndex].projects = arr;
    },
    /* 取消搜索 */
    searchClear(type) {
      // 清除关键字不切换搜索状态
      if (!type) {
        this.searchStatus = !this.searchStatus;
      }
      this.tabArr = JSON.parse(JSON.stringify(this.$store.state.tabData.tab));
    },
    /* 选择性别 */
    userSex(index) {
      this.sex = index;
      console.log(this.$store.state.order.user);
    },
    /* 显示微店核销等内容 */
    weiShow() {
      console.log(0);
      this.$store.commit("weiShowS", true);
    },
    /* 显示搜索会员 */
    selectVip() {
      this.$store.commit("selectVipShows", true);
    },
    /* 删除会员 */
    removeUser() {
      let d = this.$store.state.order.loadorder;
      let len = d.data.length;
      this.$confirm("确定放弃使用会员卡吗, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
      .then(() => {
        /* 去除会员项目 */
        let arr = [];
        for (let i = 0; i < len; i++) {
          if (!d.data[i].vip && d.data[i].type > 0) {
            arr.push(d.data[i]);
          }
        }
        d.data = arr;
        this.$store.commit("SetLoadOrder", d);
        this.$store.commit("setUser", []);
        this.toptabIndex = 0;
        this.toptabIndexs = 0;
        this.tabIndex = 0;
        this.removeVip = this.removeVip + 1;
        console.log('removeUser');
      })
      .catch(() => {});
    },
    /* 显示会员卡详情 */
    showVipInfo(index) {
      this.$store.commit("vipInfos", true);
      this.$store.commit(
        "showVipInfo",
        this.$store.state.order.user.vip[index]
      );
      console.log(this.$store.state.order.user.vip[index]);
    },
    /* 添加服务类型（服务项目） */
    addPeople(objs, index) {
      this.vipData = objs;
      if (objs.vip) {
        if (objs.balance_count) {
          // objs.balance_count -= 1;
          console.log("正常...");
        } else {
          this.$message.error("剩余次数为已用完，请更换其他或者重新购买");
          return false;
        }
      }
      let obj = JSON.parse(JSON.stringify(objs));
      // console.log(obj.category_id, obj, obj.id, index, this.user.vip);
      console.log(obj, "会员价格");
      let indexs = null;
      if (index > -1) {
        indexs = !index ? 2 : 3;
      } else {
        indexs = -1;
      }

      /* 会员价格 */
      let price = 0;
      if (this.user.name) {
        // price = obj.member_price_status ? obj.member_price : obj.price;
        price = obj.price;
      } else {
        price = obj.price;
      }

      let d = {
        id: obj.id,
        category_id: obj.id,
        project_id: obj.project_id,
        product_id: obj.product_id,
        picture: obj.picture,
        name: obj.name,
        people: [],
        pirce: price || 0,
        price: price || 0,
        num: 1,
        type: indexs,
        Newpirce: "",
        oldpirce: price || 0,
        oldpirce1: obj.price || 0, //保存不是会员价格
        member_price_status: obj.member_price_status, //会员价格
        member_price: obj.member_price || 0, //会员价格
        discount: "",
        other: "",
        vip: obj.vip || false,
        balance_count: obj.balance_count || 9999999, //剩余次数
        member_card_id: obj.member_card_id,
        treat_id: obj.treat_id,
        card_id: obj.treat_id,
      };

      // d.id = this.getOrderData.data.length
      //   ? this.getOrderData.data.length - 1
      //   : 0;
      let s = this.getOrderData;
      console.log(d, 333333333, 'getOrderData', s);
      s.data.push(d);

      this.$store.commit("peopelStatusShow", true); //显示选择工作人员界面
      this.orderDatasIndex = s.data.length - 1;

      this.$store.commit("SetLoadOrder", s);
    },
    /* 删除服务类型 */
    removePeopel(index) {
      // console.log(index, this.getOrderData);
      let d = this.getOrderData;
      this.$confirm(
        "确定要删除" + d.data[index].name + "吗, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          // console.log(111111,d.data)
          /* 同步会员项目数量 */
          if ((d.data[index].id, d.data[index].vip)) {
            this.setNum(d.data[index].id, d.data[index].num);
          }
          /* 延迟删除，避免数据获取不到 */
          setTimeout(() => {
            d.data.splice(index, 1);
          }, 300);
        })
        .catch(() => {});
      this.$store.commit("SetLoadOrder", d);
    },
    /* 同步会员项目数量 */
    setNum(id, num) {
      let order = this.user;
      let d = order.vip;
      let len = d.length;

      for (let i = 0; i < len; i++) {
        if (id == d[i].id) {
          d[i].balance_count += num;
        }
      }
      this.$store.commit("setUser", order);
    },
    /* 点击服务项目 */
    selectItem(index) {
      this.orderDatasIndex = index;
      this.$store.commit("peopelStatusShow", true); //显示选择工作人员界面
      // console.log(index,"是的发生的");
    },
    /* 显示价格详细的数量加减 */
    priceInfoNum(val) {
      this.getOrderData.data[this.orderDatasIndex].num = val;
      // this.$store.commit("SetLoadOrder", s);
    },
    /* 显示修改价格数字键盘 */
    priceKey() {
      this.$store.commit("priceKeyStatus", true);
    },
    /* 确定挂单 结算 */
    orderStatus(obj) {
      if (!this.getOrderData.data.length) {
        this.$message({
          message: "请先选择相关产品",
          type: "warning",
        });
        return false;
      }
      let d = this.$store.state.order.loadorder;
      //  console.log(d,obj,11111,d.data);
      let user = this.$store.state.order.user;
      let _this = this;
      // let s = JSON.parse(JSON.stringify(this.orderData));
      // let arr = this.$store.state.order.orderArr;
      // console.log("订单数据：", d.data);
      let arrData = [[], [], []];
      for (let i = 0; i < d.data.length; i++) {
        console.log('订单数据：', d.data, "图片数据：", d.data[i].picture);
        let arrs = {
          project_id: d.data[i].project_id || d.data[i].id || "",
          product_id: d.data[i].product_id || d.data[i].id || "",
          treat_id: d.data[i].treat_id || "",
          card_id: d.data[i].treat_id || "",
          num: d.data[i].num,
          name: d.data[i].name,
          member_price_status: d.data[i].member_price_status, //会员价格
          member_price: d.data[i].member_price, //会员价格
          pirce:
            d.data[i].member_price_status && this.user.name
              ? d.data[i].member_price
              : d.data[i].pirce || 0,
          price: d.data[i].pirce || 0,
          remark: obj.other || "",
          picture: d.data[i].picture || "",
          type: d.data[i].type,
          status: true,
          staff: [],
          specify: [],
        };
        for (let k = 0; k < d.data[i].people.length; k++) {
          if (d.data[i].people[k]) {
            arrs.staff.push(d.data[i].people[k].id);
            arrs.specify.push(d.data[i].people[k].specify ? 1 : 0);
          }
        }
        // arr.projects.push(arrs);
        // console.log(d.data[i].type)
        if (obj) {
          arrData[0].push(arrs);
          continue;
        }

        if (d.data[i].type == 2) {
          arrData[0].push(arrs);
        }
        if (d.data[i].type == 3) {
          arrData[1].push(arrs);
        }

        if (d.data[i].type == -1) {
          if (arrs.project_id != arrs.treat_id) {
            // d[i].price = (d[i].pirce * this.users.membertype.project_disc).toFixed(2);
            arrData[0].push(arrs);
          }
          if (arrs.product_id != arrs.treat_id) {
            arrData[1].push(arrs);
          }
        }

        console.log(arrData);
      }

      let arr = {
        member_id: user.id || "0",
        products: arrData[1],
        projects: arrData[0],
        cards: arrData[2],
        pays: "[]",
        serial: "",
        picture: d.picture,
        remark: d.other || "",
        car_id: d.id || "",
      };
      // console.log(arr,1111111111111111111111);
      this.orders = arr;

      if (!obj) {
        console.log(arr);
        let href = d.edit
          ? "/api/app/meiye/payment/car_edit"
          : "/api/app/meiye/payment/car_add";

        arr.projects = JSON.stringify(arr.projects);
        arr.products = JSON.stringify(arr.products);
        arr.cards = JSON.stringify(arr.cards);
        /* 编辑 */
        if (d.edit) {
          arr.id = d.id;
          arr.member_id = user.id || d.member_id;
        }
        this.$axios({
          href: href,
          data: arr,
        }).then((res) => {
          console.log(res);
          setyueye();
          _this.hide();
          _this.getLIst();
        });
        return false;
      }

      function setyueye() {
        let d = _this.yuyueItem;
        if (!d) {
          return false;
        }
        // console.log(d)
        d.status = "1";
        _this
          .$axios({
            href: "/api/app/meiye/yue/edit",
            data: d,
          })
          .then((res) => {
            _this.yuyueItem = "";
            _this.etyue();
            console.log(res);
          });
      }

      console.log(d);
      _this.$store.commit("payStatus", true);
    },
    /* 获取挂单列表 */
    getLIst() {
      let _this = this;
      this.$axios({
        href: "/api/app/meiye/payment/car_store",
      }).then((res) => {
        let len = res.data.length;
        let d = res.data;
        for (let i = 0; i < len; i++) {
          d[i].list = [];
          // d[i].list = d[i].products.concat(d[i].projects); //合并商品和项目（两个是一样的）

          for (let l = 0; l < d[i].products.length; l++) {
            d[i].products[l].oldpirce = d[i].products[l].price;
            d[i].products[l].type = 3;

            if (d[i].products[l].treat_id) {
              d[i].products[l].type = -1;
            }
            d[i].list.push(d[i].products[l]);

            console.log(111);
          }
          for (let l = 0; l < d[i].projects.length; l++) {
            d[i].projects[l].projects = d[i].projects[l].price;
            d[i].projects[l].type = 2;

            if (d[i].projects[l].treat_id) {
              d[i].projects[l].type = -1;
            }
            d[i].list.push(d[i].projects[l]);
            console.log(2222);
          }

          //  for(let l = 0; l < d[i].cards.length;l++){
          //     d[i].projects[l].type = -1;
          //     d[i].list.push(d[i].cards[l]);
          //      console.log(3333)
          // }
        }
        _this.$store.commit("setDataOrder", d);
      });
    },
    /* 获取预约列表 */
    getyue() {
      console.log('开单弹窗父级Tab列表', this.tabArr);

      let date = new Date();
      let time =
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      this.$axios({
        href: "/api/app/meiye/yue/store",
        data: { date: time, status: 0 },
      }).then((res) => {
        let arr = [];
        let d = res.data.items;
        let len = d.length;
        /* 去掉已经过期的预约订单 */
        for (let i = 0; i < len; i++) {
          if (new Date(d[i].time_end).getTime() >= new Date().getTime()) {
            arr.push(d[i]);
          }
        }
        this.tabArr[2].data = arr;
      });
    },
    /* 显示添加备注 */
    othersStatus() {
      console.log(11111);
      this.$store.commit("othersStatus", true);
    },
    getProject() {
      let _this = this;
      let arr = this.$store.state.tabData.tab;
      arr[0].data = [{ id: 0, name: "全部", projects: [] }]; //项目
      arr[1].data = [{ id: 0, name: "全部", products: [] }]; //商品
      this.$axios({
        href: "/api/app/meiye/home/item_store",
        data: {},
        loading: true,
      }).then((res) => {
        // arr[0].data = res.data.project_types; //项目
        // arr[1].data = res.data.product_types; //商品
        if (res.data && res.data.project_types) {
          arr[0].data = arr[0].data.concat(res.data.project_types); //项目
          arr[1].data = arr[1].data.concat(res.data.product_types); //商品
        }

        console.log(arr);

        let d = res.data.product_types;
        let len = d.length;

        for (let i = 0; i < len; i++) {
          arr[1].data[i].projects = [];
          for (let k = 0; k < arr[1].data[i].products.length; k++) {
            arr[1].data[i].projects.push(arr[1].data[i].products[k]);
          }
        }

        let len1 = arr[0].data.length;
        let len2 = arr[1].data.length;
        for (let l = 0; l < len1; l++) {
          if (arr[0].data[l] && arr[0].data[l].projects) {
            arr[0].data[0].projects = arr[0].data[0].projects.concat(
              arr[0].data[l].projects
            );
          }
        }

        for (let j = 0; j < len2; j++) {
          if (arr[1].data[0] && arr[1].data[0].projects) {
            arr[1].data[0].projects = arr[1].data[0].projects.concat(
              arr[1].data[j].products
            );
          }
        }
        _this.$store.commit("setTab", arr);
        _this.tabArr = arr;
      });
    },
    /* 预约结算（到店） */
    setyue(obj) {
      console.log(obj);
      // 选中预约订单
      if (this.yuyueID != obj.id) {
        this.yuyueItem = obj;
        this.yuyueID = obj.id;
      } else {
        this.yuyueID = "";
        this.yuyueItem = null;
        this.$message({
          message: "已取消关联预约信息",
          type: "warning",
        });
        this.$store.commit("setUser", []);
        return false;
      }

      this.$axios({
        href: "/api/app/meiye/member/store",
        data: { keyword: obj.phone },
      }).then((res) => {
        if (res.data.items.length) {
          let len = res.data.items.length;
          for (let i = 0; i < len; i++) {
            if (res.data.items[i].phone == obj.phone) {
              setUser(res.data.items[i].id);
              this.$message({
                message: "选择会员卡成功~",
                type: "success",
              });
            }
          }
        } else {
          this.$message({
            message: "没有查询到对应的会员信息",
            type: "warning",
          });
          if (this.user.name) {
            this.$confirm("是否放弃使用会员卡吗, 是否继续?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                _this.$store.commit("setUser", []);
              })
              .catch(() => {});
          }
        }
      });

      let _this = this;
      function setUser(id) {
        _this
          .$axios({
            href: "/api/app/meiye/member/index",
            data: { id: id },
            loading: true,
          })
          .then((res) => {
            let user = res.data.member;
            user.vip = [];
            res.data.name = res.data.member.name;
            // d.name = res.data.member.name;

            for (let i = 0; i < res.data.card.length; i++) {
              for (let k = 0; k < res.data.card[i].treats.length; k++) {
                res.data.card[i].treats[k].type = res.data.card[i].name;
                res.data.card[i].treats[k].vip = true;
                res.data.card[i].treats[k].treat_id =
                  res.data.card[i].treats[k].id;
                user.vip.push(res.data.card[i].treats[k]);
              }
            }
            for (let l = 0; l < res.data.treat.length; l++) {
              res.data.treat[l].vip = true;
              res.data.treat[l].treat_id = res.data.treat[l].id;
              user.vip.push(res.data.treat[l]);
            }

            user.membertype = res.data.member.membertype;

            user.membertype.product_disc =
              res.data.member.membertype.product_disc / 100;
            user.membertype.project_disc =
              res.data.member.membertype.project_disc / 100;
            console.log(user);

            _this.$store.commit("setUser", user);
          });
      }
    },
  },
  computed: {
    billShow: {
      get() {
        return this.$store.state.order.bill;
      },
      set() {
        return this.$store.state.order.bill;
      },
    },
    user: {
      get() {
        return this.$store.state.order.user;
      },
      set() {
        return this.$store.state.order.user;
      },
    },
    vipInfoStatus: {
      get() {
        return this.$store.state.order.vipInfo;
      },
      set() {
        return this.$store.state.order.vipInfo;
      },
    },
    getOrderData: {
      get() {
        return this.$store.state.order.loadorder;
      },
      set() {
        return this.$store.state.order.loadorder;
      },
    },
  },
  created() {
    let _this = this;
    _this.getProject();
    this.tabArr = JSON.parse(JSON.stringify(this.$store.state.tabData.tab));
    let d = new Date();
    this.dates = d.getMonth() + 1 + "月" + d.getDate() + "日" + " 今天";

    /* 同步初始订单 */
    // this.orderDatas = JSON.parse(JSON.stringify(this.orderData));
    this.$store.commit("SetLoadOrder", this.orderData);
    this.getyue();
  },
  watch: {
    getOrderData() {
      this.getyue();
      console.log("修改了订单...");
    },
    user(val) {
      /* 会员价格 */
      let price = 0;

      let d = JSON.parse(JSON.stringify(this.orderData.data));
      this.orderData.data = [];

      let len = d.length;
      for (let i = 0; i < len; i++) {
        if (val) {
          price =
            d[i].member_price_status && this.user.name
              ? d[i].member_price
              : d[i].oldpirce1;
          console.log(d[i], "修改中的订单");
        } else {
          price = d[i].oldpirce1;
          console.log(d[i], "修改中的订单");
        }
        d[i].price = price;
        d[i].pirce = price;
        d[i].oldpirce = price;
        this.orderData.data.push(d[i]);
        this.$store.commit("SetLoadOrder", this.orderData);
        // console.log(price);
      }
      // this.toptabIndexs = this.user.name?-1:!this.user.name&&this.toptabIndex>=0?this.toptabIndex:0;
      // this.$store.commit("setUser",val);
      let _this = this;
      let time = null;
      clearInterval(time);
      time = setInterval(() => {
        if (_this.user.vip) {
          if (_this.user.name) {
            _this.tab(-1);
          } else {
            _this.tab(_this.toptabIndex);
          }
        }
        clearInterval(time);
        if (this.toptabIndexs != -1) {
          clearInterval(time);
        }
        // console.log(1111)
      }, 1000);
      console.log(val, "修改了会员...", this.orderData, this.toptabIndexs);
    },
  },
};

/* 会员添加没有价格显示 */
</script>

<style lang="scss" scoped>
.billing {
  position: fixed;
  top: 150%;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  display: flex;
  background: #ffffff;
  transition: top 1.5s, opacity 1.5s;
  font-family: PingFangSC-Regular;
  z-index: 1000;
  &-left {
    width: 100%;
    height: 100%;
    padding-right: 500px;
    box-sizing: border-box;

    &-head {
      position: relative;
      background: #fff;
      width: 100%;
      height: 80px;
      box-sizing: border-box;
      padding: 25px 0 15px 2px;
      display: flex;
      &-l {
        width: 40px;
        height: 40px;
        background-image: url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_NavBack.png);
        background-position: left center;
        background-size: 28px;
        background-repeat: no-repeat;
        cursor: pointer;
      }
      &-c {
        width: 100%;
        height: 100%;
        font-family: PingFangSC-Medium;
        display: flex;
        button {
          background: transparent;
          border: none;
          outline: none;
          box-sizing: border-box;
          cursor: pointer;
          padding: 0;
          margin-top: -8px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          color: #5a5a5a;
          padding: 0 20px;
          transition: all 0.5s;
        }
        &-btn {
          font-size: 20px !important;
          color: #28282d !important;
        }
      }
      &-r {
        width: 200px;
        height: 100%;
        button {
          width: 56px;
          height: 40px;
          background: transparent;
          border: none;
          outline: none;
          box-sizing: border-box;
          cursor: pointer;
          padding: 0;
        }
        button:first-child {
          background: #fff
            url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Search.png)
            center / 28px no-repeat;
        }
        button:last-child {
          background: #fff
            url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Filter.png)
            center / 28px no-repeat;
        }
        &-btns {
          background: #fff
            url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_FLCX.png)
            center / 28px no-repeat !important;
        }
      }
    }

    &-cont {
      width: 100%;
      height: 100%;
      position: relative;
      &-head {
        height: auto;
        width: 100%;
        position: relative;
        padding: 0 15px 15px 15px;
        z-index: 10;
        box-sizing: border-box;
        .ul {
          margin: 0;
          position: relative;
          height: 44px;
          line-height: 44px;
          padding: 0 40px 0 10px;
          border-radius: 5px;
          background: #f8f8f8;
          overflow: hidden;

          .ul-button {
            position: absolute;
            top: 0;
            right: 0;
            width: 40px;
            height: 44px;
            background: transparent
              url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_ArrowUp.png)
              center / 28px no-repeat;
            -webkit-transition: -webkit-transform 0.5s;
            transform: rotate(180deg);
            border: none;
            outline: none;
            box-sizing: border-box;
            cursor: pointer;
            padding: 0;
          }
          .ul-button-active {
            transform: rotate(360deg);
          }

          .li {
            width: auto;
            height: 100%;
            position: relative;
            padding: 0 18px;
            color: #5a5a5a;
            cursor: pointer;
            float: left;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.3s;
          }

          .li-active {
            color: #47bf7c;
          }
        }
        .ul-active {
          height: auto;
        }
        .uls {
          display: flex;
          .lis {
            flex: 1;
            float: none;
          }
          .lis:nth-of-type(1) {
            flex: 2;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            margin-right: 55px;
          }
        }

        &-search {
          position: relative;
          background: #f4f4f4
            url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Search.png)
            8px center / 24px no-repeat;
          line-height: 44px;
          border-radius: 6px;
          height: 44px;
          padding: 0 60px 0 38px;
          overflow: hidden;
          input {
            display: block;
            width: 100%;
            height: 44px;
            line-height: 30px;
            padding: 12px 0;
            font-size: 14px;
            color: #28282d;
            background: transparent;
            box-sizing: border-box;
            border: none;
            outline: none;
          }

          .el-input__inner:focus {
            border-color: #409eff;
            outline: 0;
          }
          button {
            position: absolute;
            top: 0;
            right: 0;
            background: #28282d;
            font-size: 14px;
            color: #fff;
            text-align: center;
            width: 60px;
            line-height: 44px;
            height: 44px;
            padding: 0;
            cursor: pointer;
            border: none;
            outline: none;
            box-sizing: border-box;
          }
        }
      }

      &-box {
        position: relative;
        background: #fff;
        overflow-x: hidden;
        overflow-y: auto;
        height: 807px;
        padding: 5px 0 25px 18px;
        &-item:hover {
          border: 1px solid #409eff;
          box-shadow: 0 2px 2px 1px #ddd;
        }
        &-item {
          position: relative;
          cursor: pointer;
          float: left;
          width: 180px;
          height: 240px;
          padding: 10px;
          box-sizing: border-box;
          border-radius: 6px;
          background: #ffffff;
          border: 1px solid #f2f2f2;
          text-align: center;
          margin: 0 15px 15px 0;
          transition: all 0.5s;
          &-img {
            width: 160px;
            height: 160px;
            img {
              width: 100%;
              border-radius: 5px;
              height: 100%;
            }
          }
          &-title {
            height: 71px;
            font-size: 15px;
            color: #28282d;
            line-height: 20px;
            padding: 23px 5px 0 5px;
            overflow: hidden;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
          }
          &-num {
            height: 34px;
            width: 100%;
            line-height: 34px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #000000;
            font-size: 14px;
            text-align: left;
            font-family: DINAlternate-Bold;
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
          }
        }
        &-vip {
          height: 770px;
          padding: 10px 18px 25px 5px;
          overflow-x: hidden;
          overflow-y: auto;

          &-list {
            position: relative;
            display: flex;
            padding: 16px 30px 16px 15px;
            line-height: 20px;
            font-size: 14px;
            color: #28282d;
            background: #fff
              url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Next.png)
              right center / 28px no-repeat;
            cursor: pointer;
            box-shadow: 0 0 3px 1px rgba(220, 220, 220, 0.4);
            margin-bottom: 15px;
            border-radius: 5px;

            div {
              flex: 1;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            div:nth-of-type(1) {
              flex: 2;
              text-align: left;
            }
          }

          button {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 120px;
            background: transparent;
            border: none;
            outline: none;
            box-sizing: border-box;
            cursor: pointer;
          }
        }
      }
      &-box::after {
        content: '';
        display: block;
        clear: both;
      }
      &-box-none {
        font-size: 13px;
        font-family: PingFangSC-Medium;
        color: #8a8a8a;
        text-align: center;
        line-height: 120px;
      }
    }
  }
  &-right {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 500px;
    z-index: 10;
    background: #f8f8f8;

    &-head {
      position: relative;
      background-image: linear-gradient(180deg, #474747 0%, #242424 100%);
      box-shadow: inset 1px 1px 1px 0 rgba(135, 135, 135, 0.5);
      border-radius: 6px 6px 0 0;
      height: 84px;
      padding: 0 10px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      &-title {
        min-width: 60px;
        height: 40px;
        line-height: 40px;
        font-size: 20px;
        color: #fff;
        font-family: PingFangSC-Medium;
        vertical-align: top;
        display: inline-block;
      }
      &-vip {
        width: 168px;
        display: inline-flex;
        background: #d6c361
          url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_VIP_Small.png)
          90% center / 82px no-repeat;
        height: 40px;
        border-radius: 6px;
        padding: 0 6px;
        cursor: pointer;
        margin-right: 5px;
        &-img {
          margin: 6px 8px 6px 0;
          width: 28px;
          height: 28px;
          border: 0.5px solid #fff;
          border-radius: 50%;
          overflow: hidden;
          img {
            width: 28px;
            height: 28px;
            background: #fff;
            border-radius: 50%;
          }
        }
        &-name {
          width: 100px;
          height: 100%;
          flex: 1;
          padding: 10px 0;
          color: #604e2a;
          font-size: 14px;
          line-height: 20px;
          font-family: PingFangSC-Medium;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: left;
        }
        button {
          min-width: 36px;
          width: 36px;
          height: 40px;
          background: transparent
            url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_VipDel.png)
            center / 28px no-repeat;
          cursor: pointer;
          border: none;
          outline: none;
          box-sizing: border-box;
        }
      }
      &-sex {
        width: 100px;
        display: inline-flex;
        vertical-align: top;
        button {
          width: 40px;
          height: 40px;
          border: none;
          outline: none;
          box-sizing: border-box;
          cursor: pointer;
          padding: 0;
        }
        &-g {
          background: transparent
            url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Female_N.png)
            center / 30px no-repeat;
        }
        &-g-active {
          background: transparent
            url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Female_S.png)
            center / 30px no-repeat;
        }
        &-b {
          background: transparent
            url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Male_N.png)
            center / 30px no-repeat;
        }
        &-b-active {
          background: transparent
            url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Male_S.png)
            center / 30px no-repeat;
        }
      }
      &-selectvip {
        width: 100px;
        display: inline-flex;
        vertical-align: top;
        button {
          width: 100px;
          height: 40px;
          background: rgba(255, 255, 255, 0.1)
            url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_OrderItemVipFlag.png)
            5px center / 28px no-repeat;
          border-radius: 6px;
          color: #fff;
          font-size: 14px;
          padding-left: 34px;
          text-align: left;
          border: none;
          outline: none;
          box-sizing: border-box;
          cursor: pointer;
        }
      }
      &-more {
        border: none;
        outline: none;
        box-sizing: border-box;
        cursor: pointer;
        padding: 0;
        position: absolute;
        top: 7px;
        right: 10px;
        width: 40px;
        height: 40px;
        background: transparent
          url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_More_White.png)
          right center / 28px no-repeat;
      }
    }
    &-cont {
      flex: 1;
      padding: 10px 10px 0 10px;
      overflow-x: hidden;
      overflow-y: auto;
      box-sizing: border-box;
      &-item {
        width: 480px;
        height: 120px;
        background: #ffffff;
        font-family: Microsoft YaHei, Microsoft YaHei-Normal;
        color: #777777;
        font-size: 14px;
        position: relative;
        margin-bottom: 10px;
        background: #fff;
        border-radius: 6px;
        cursor: pointer;
        display: flex;

        &-l {
          width: 28%;
          height: 120px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            border-radius: 5px;
            width: 90px;
            height: 93px;
          }
        }

        &-r {
          width: 78%;
          height: 100%;
          &-title {
            width: 100%;
            height: 30px;
            display: flex;
            &-num {
              width: 20%;
              height: 35px;
              display: flex;
              align-items: center;
              justify-content: flex-start;
            }
          }

          p {
            max-width: 80%;
            height: 30px;
            line-height: 40px;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 0;
            margin: 0;
          }
          &-price {
            width: 80%;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }
          &-work {
            width: 80%;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            span:last-child {
              color: #b2b2b2;
            }
            span:first-child {
              color: #8088a8;
            }
          }
        }

        &-title {
          display: flex;
          line-height: 40px;
          font-size: 15px;
          padding-right: 23px;
          &-left {
            flex: 2;
            color: #8a8a8a;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: left;
          }
          &-right {
            text-align: right;
            font-family: DINAlternate-Bold;
            span {
              font-size: 14px;
              color: #000000;
              border-radius: 3px;
            }
            em {
              color: #edb339;
              line-height: 16px;
              padding: 2px 6px;
              border-radius: 3px;
              margin-right: 10px;
              font-size: 13px;
              background: #876c37;
              text-align: right;
              font-style: normal;
              font-family: DINAlternate-Bold;
            }
          }
        }
        button {
          position: absolute;
          top: 0;
          right: 0;
          width: 28px;
          height: 28px;
          background: transparent
            url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_ServiceDel.png)
            right top / 28px no-repeat;
          border: none;
          outline: none;
          box-sizing: border-box;
          cursor: pointer;
          padding: 0;
        }
        &-list,
        &-lists {
          width: 430px;
          height: 28px;
          padding: 2px 10px;
          background: #f8f8f8;
          border-radius: 3px;
          &-noneText {
            color: red;
            text-align: left;
            display: flex;
            padding: 2px 0;
            line-height: 24px;
            font-size: 13px;
          }
        }
        &-lists {
          width: 430px;
          min-height: 32px;
          height: 100%;
          padding: 2px 10px;
          line-height: 24px;
          font-size: 13px;
          color: #8a8a8a;
          div {
            width: 100%;
            height: 28px;
            display: flex;
            padding: 2px 0;
            line-height: 24px;
            font-size: 13px;
            color: #8a8a8a;
          }
          &-other {
            padding: 14px 0 5px 0;
            line-height: 18px;
            font-size: 12px;
            color: #ccc;
            text-align: left;
          }
          img {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            border: 1px solid #fff;
            margin-right: 10px;
          }
          span {
            flex: 1.5;
            color: #5a5a5a;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          span:last-child {
            flex: 1;
            text-align: right;
            color: #28282d;
          }
          em {
            text-align: center;
            color: #fff;
            font-size: 13px;
            width: 24px;
            min-width: 24px;
            max-width: 24px;
            height: 24px;
            border-radius: 4px;
            background: #886666;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-style: normal;
          }
        }
      }
      &-item:last-child {
        margin: 0;
      }
    }
    &-foot {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      box-sizing: border-box;
      &-other-box {
        padding: 10px;
      }
      &-other {
        overflow: hidden;
        padding: 12px 12px 12px 35px;
        line-height: 20px;
        border-radius: 5px;
        background: #fff
          url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Edit_Gray.png)
          8px center / 24px no-repeat;
        font-size: 14px;
        color: #ccc;
        text-align: left;
        cursor: pointer;
        white-space: nowrap;
        text-overflow: ellipsis;
        &-remark {
          color: #000000;
        }
      }
      &-btn {
        display: flex;
        span {
          display: inline-block;
          flex: 1.5;
          height: 58px;
          border-radius: 0;
        }
        &-b {
          padding: 0 10px;
          line-height: 40px;
          background: #28282d;
          font-size: 15px;
          color: #fff;
          border: none;
          outline: none;
          box-sizing: border-box;
        }
        &-r {
          padding: 0 10px;
          line-height: 40px;
          background: #f44e4e;
          font-size: 15px;
          color: #fff;
          border: none;
          outline: none;
          box-sizing: border-box;
        }
      }
      button {
        border: none;
        outline: none;
        box-sizing: border-box;
        width: 100%;
        height: 58px;
        border-radius: 0;
        font-family: PingFangSC-Medium;
        cursor: pointer;
      }
    }
  }
  &-search-box {
    position: absolute;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: -1;
    transition: all 0.8s;
    &-cont {
      position: absolute;
      top: 62px;
      right: 15px;
      width: 560px;
      height: 582px;
      background: #fff;
      overflow-x: hidden;
      overflow-y: auto;
      border-radius: 6px;
      padding: 0 15px 20px 15px;
      box-shadow: 0 0 5px 0 rgba(187, 187, 187, 0.5);
      &-item {
        width: 100%;
        height: 275px;
        &-title {
          line-height: 40px;
          font-size: 14px;
          text-align: left;
          color: #5a5a5a;
        }
        &-box {
          width: 100%;
          height: auto;
          &-list {
            float: left;
            cursor: pointer;
            height: 44px;
            line-height: 44px;
            font-size: 14px;
            color: #28282d;
            text-align: center;
            border: 0.5px solid #ddd;
            border-radius: 5px;
            margin-bottom: 11px;
            width: 120px;
            margin-right: 11px;
            transition: all 0.3s;
          }
          &-list-active {
            background: #47bf7c;
            color: #fff;
            border: 0.5px solid #47bf7c;
          }
        }
      }
    }
  }
  &-search-box-active {
    opacity: 1;
    z-index: 10;
    left: 0;
  }
}
.billing-active {
  top: 0%;
  opacity: 1;
}

.price-info {
  position: fixed;
  top: 0;
  left: -100%;
  width: 60%;
  height: 100%;
  opacity: 0;
  background: #f8f8f8;
  border-right: 0.5px solid rgba(220, 220, 220, 0.7);
  z-index: -1;
  transition: all 0.8s;
  &-head {
    width: 100%;
    height: 40px;
    position: relative;
    padding: 25px 0 15px 0;
    text-align: center;
    line-height: 40px;
    background: #fff;
    border-bottom: 0.5px solid rgba(220, 220, 220, 0.7);
    font-size: 20px;
    color: #28282d;
    button {
      border: none;
      outline: none;
      box-sizing: border-box;
      cursor: pointer;
      position: absolute;
      right: 20px;
      top: 25px;
      width: 40px;
      height: 40px;
      background: transparent
        url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_X_Black.png)
        right center / 24px no-repeat;
    }
  }
  &-cont {
    width: 97.5%;
    height: 857px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 12px 10px 35px 10px;
    &-item {
      width: 96%;
      height: 54px;
      background: #fff;
      border-radius: 5px;
      margin-bottom: 20px;
      padding: 0 15px;
      color: #28282d;
      display: flex;
      span {
        padding: 17px 0;
        line-height: 20px;
        font-size: 15px;
        min-width: 100px;
      }
      &-num {
        position: relative;
        flex: 1;
        text-align: right;
        padding: 7px 15px 17px 0;
        line-height: 20px;
        font-size: 15px;
      }
    }
    &-text {
      padding-bottom: 5px;
      line-height: 20px;
      font-size: 12px;
      color: #8a8a8a;
      letter-spacing: -0.29px;
      text-align: left;
    }
    &-list {
      background: #fff;
      width: 96%;
      min-height: 54px;
      border-radius: 5px;
      margin-bottom: 20px;
      padding: 0 15px;
      color: #28282d;
      &-item {
        width: 100%;
        height: 54px;
        position: relative;
        display: flex;
        border-bottom: 0.5px solid rgba(220, 220, 220, 0.7);
        span {
          padding: 17px 0;
          line-height: 20px;
          font-size: 15px;
          font-family: PingFangSC-Medium;
          min-width: 100px;
        }
        &-cont {
          position: relative;
          flex: 1;
          text-align: right;
          padding: 17px 15px 17px 0;
          line-height: 20px;
          font-size: 15px;
          cursor: pointer;
        }
        &-cont-next::after {
          position: absolute;
          content: "";
          width: 28px;
          height: 28px;
          background: transparent
            url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Right.png)
            right center / 28px no-repeat;
          right: -10px;
          top: 0;
          bottom: 0;
          margin: auto;
        }
      }
    }
  }
}
.price-info-active {
  opacity: 1;
  left: 0;
  z-index: 2000;
}
</style>

<style>
.billing .el-input__inner {
  border: none;
  background-color: #f4f4f4;
}
.billing-search-box-cont .el-button {
  width: 135px;
  height: 42px;
}
.billing-search-box-cont .el-button--primary {
  color: #fff;
  background: #28282d;
  border-color: #28282d;
}
.billing-search-box-cont .el-button--primary:focus,
.billing-search-box-cont .el-button--primary:hover {
  color: #fff;
  background: #28282d;
  border-color: #28282d;
}

.price-info .el-input-number__decrease,
.price-info .el-input-number__increase {
  background: #ffffff;
  font-size: 25px;
}

.billing-right-foot-btn .el-button {
  padding: 0;
}
</style>

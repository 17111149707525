var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"peopel",class:_vm.shows ? 'peopel-active' : '',attrs:{"orderDatasIndex":_vm.orderDatasIndex},on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.orderSuccess($event)}}},[(_vm.getOrderData.data.length)?_c('div',{staticClass:"peopel-box",class:_vm.shows ? 'peopel-box-active' : ''},[_c('div',{staticClass:"peopel-box-head"},[(0)?_c('div',{staticClass:"peopel-box-head-img",on:{"click":_vm.more}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.mores),expression:"!mores"}],attrs:{"src":"https://static.bokao2o.com/wisdomDesk/images/Def_Icon_OrderAddSer_Job.png","alt":""}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.mores),expression:"mores"}],attrs:{"src":"https://static.bokao2o.com/wisdomDesk/images/Def_Icon_OrderAddSer_Queue.png","alt":""}})]):_vm._e(),(_vm.getOrderData)?_c('div',{staticClass:"peopel-box-head-type",staticStyle:{"margin-left":"10px"}},[_c('button',{on:{"click":function($event){return _vm.hide(1)}}},[_vm._v("删除")]),(_vm.getOrderData.data[_vm.orderIndex])?_c('span',[_vm._v(_vm._s(_vm.getOrderData.data[_vm.orderIndex].name))]):_vm._e()]):_vm._e(),(0)?_c('div',{staticClass:"peopel-box-head-tab"},[_c('span',[_vm._v("服务于：")]),_vm._l((_vm.tab),function(item,index){return _c('button',{key:index,class:_vm.tabIndex == index ? 'peopel-box-head-tab-btn' : ''},[_vm._v(" "+_vm._s(item)+" ")])})],2):_vm._e()]),_c('div',{staticClass:"peopel-box-cont"},[_c('div',{staticClass:"peopel-box-cont-box"},[(!_vm.mores)?_c('div',{staticClass:"peopel-box-cont-box-left",staticStyle:{"width":"100%","max-width":"100%"}},[_c('div',{staticClass:"peopel-box-cont-box-left-tab",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"peopel-box-cont-box-left-tab-item",class:_vm.itemIndex == -1
                  ? 'peopel-box-cont-box-left-tab-item-active'
                  : '',on:{"click":function($event){return _vm.itemClick(-1)}}},[_vm._v(" 全部 ")]),(_vm.item && _vm.item.length)?_c('div',_vm._l((_vm.item),function(item,index){return _c('div',{key:index,staticClass:"peopel-box-cont-box-left-tab-item",class:_vm.itemIndex == index
                    ? 'peopel-box-cont-box-left-tab-item-active'
                    : '',on:{"click":function($event){return _vm.itemClick(index)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0):_vm._e()]),(_vm.itemIndex != -1 && !_vm.mores)?_c('div',{staticClass:"peopel-box-cont-box-left-cont"},[(_vm.item[_vm.itemIndex] && _vm.item[_vm.itemIndex].data.length)?_c('div',_vm._l((_vm.item[_vm.itemIndex].data),function(item,index){return _c('div',{key:index,staticClass:"peopel-box-cont-box-left-cont-list",class:item.cheacks
                    ? 'peopel-box-cont-box-left-cont-list-active'
                    : '',on:{"click":function($event){return _vm.itemDataIndexClick(index, item)}}},[_c('div',{staticClass:"peopel-box-cont-box-left-cont-list-img"},[_c('img',{attrs:{"src":item.photo
                        ? item.photo
                        : 'https://static.bokao2o.com/images/bg200.png?imageView2/1/w/200/h/200/interlace/1/q/100',"alt":item.name}})]),_c('div',{staticClass:"peopel-box-cont-box-left-cont-list-name"},[_c('div',{staticClass:"specify"},[_vm._v(" "+_vm._s(item.name)+" "),_c('div',{class:item.specify ? 'specify-tags' : 'specify-tag',on:{"click":function($event){$event.stopPropagation();return _vm.specify(index, item)}}},[(!item.specify)?_c('span',[_vm._v("非指定")]):_vm._e(),(item.specify)?_c('span',[_vm._v("指定")]):_vm._e(),_c('i',{staticClass:"el-icon-sort"})])]),_c('div',{staticStyle:{"margin-top":"10px"}},[_vm._v(" 工号："+_vm._s(item.workID)+" ")])])])}),0):_vm._e()]):_vm._e(),(_vm.itemIndex == -1 && !_vm.mores)?_c('div',{staticClass:"peopel-box-cont-box-left-cont"},[(_vm.itemArr && _vm.itemArr.length)?_c('div',_vm._l((_vm.itemArr),function(item,index){return _c('div',{key:index,staticClass:"peopel-box-cont-box-left-cont-list",class:item.cheacks
                    ? 'peopel-box-cont-box-left-cont-list-active'
                    : '',staticStyle:{"width":"231px"},attrs:{"data-index":index},on:{"click":function($event){return _vm.itemDataIndexClick(index, item)}}},[_c('div',{staticClass:"peopel-box-cont-box-left-cont-list-img"},[_c('img',{attrs:{"src":item.photo
                        ? item.photo
                        : 'https://static.bokao2o.com/images/bg200.png?imageView2/1/w/200/h/200/interlace/1/q/100',"alt":item.name}})]),_c('div',{staticClass:"peopel-box-cont-box-left-cont-list-name"},[_c('div',{staticClass:"specify"},[_vm._v(" "+_vm._s(item.name)+" "),_c('div',{class:item.specify ? 'specify-tags' : 'specify-tag',on:{"click":function($event){$event.stopPropagation();return _vm.specify(index, item)}}},[(!item.specify)?_c('span',[_vm._v("非指定")]):_vm._e(),(item.specify)?_c('span',[_vm._v("指定")]):_vm._e(),_c('i',{staticClass:"el-icon-sort"})])]),_c('div',{staticStyle:{"margin-top":"10px"}},[_vm._v(" 工号："+_vm._s(item.workID)+" ")])])])}),0):_vm._e()]):_vm._e()]):_vm._e(),(_vm.mores)?_c('div',{staticClass:"peopel-box-cont-box-left"},[_vm._m(0),_c('div',{staticClass:"peopel-box-cont-box-left-tab"},[(_vm.typeArr[_vm.typeArrIndex] && _vm.typeArr[_vm.typeArrIndex].tabName.length)?_c('div',_vm._l((_vm.typeArr[_vm.typeArrIndex].tabName),function(item,index){return _c('div',{key:index,staticClass:"peopel-box-cont-box-left-tab-item",class:_vm.itemIndex == index
                    ? 'peopel-box-cont-box-left-tab-item-active'
                    : '',on:{"click":function($event){return _vm.itemClick(index)}}},[_vm._v(" "+_vm._s(item)+" ")])}),0):_vm._e()])]):_vm._e(),(0)?_c('div',{staticClass:"peopel-box-cont-box-right"},[(_vm.typeArr && _vm.typeArr.length)?_c('div',_vm._l((_vm.typeArr),function(item,index){return _c('div',{key:index,staticClass:"peopel-box-cont-box-right-item",class:_vm.typeArrIndex == index
                  ? 'peopel-box-cont-box-right-item-active'
                  : '',on:{"click":function($event){return _vm.typeArrIndexClick(index)}}},[(!item.peopel)?_c('div',{staticClass:"peopel-box-cont-box-right-item-name"},[_vm._v(" "+_vm._s(item.name)+" "),_c('span',[_vm._v("(选填)")])]):_vm._e(),(item.peopel)?_c('div',{staticClass:"peopel-box-cont-box-right-item-name"},[_c('div',{staticClass:"peopel-box-cont-box-right-item-name-text"},[_vm._v(" "+_vm._s(item.peopel)+" ")]),(_vm.peopelArr && _vm.peopelArr.length)?_c('div',{staticClass:"peopel-box-cont-box-right-item-name-input"},[_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(item.type),callback:function ($$v) {_vm.$set(item, "type", $$v)},expression:"item.type"}},_vm._l((_vm.peopelArr),function(items){return _c('el-option',{key:items,attrs:{"label":items,"value":items}})}),1)],1):_vm._e()]):_vm._e()])}),0):_vm._e()]):_vm._e()])]),(_vm.getOrderData && _vm.getOrderData.data && _vm.getOrderData.data[_vm.orderIndex] && _vm.getOrderData.data[_vm.orderDatasIndex])?[_c('div',{staticClass:"peopel-box-foot"},[_c('div',{staticClass:"peopel-box-foot-left"},[_c('span',[_vm._v("数量：")]),_c('el-input-number',{key:"2",attrs:{"min":1,"max":_vm.getOrderData.data[_vm.orderDatasIndex].vip ? _vm.getOrderData.data[_vm.orderDatasIndex].balance_count : 999999,"step":1,"label":"描述文字"},on:{"change":_vm.handleChange},model:{value:(_vm.getOrderData.data[_vm.orderIndex].num),callback:function ($$v) {_vm.$set(_vm.getOrderData.data[_vm.orderIndex], "num", $$v)},expression:"getOrderData.data[orderIndex].num"}})],1),(_vm.getOrderData.data[_vm.orderDatasIndex].vip)?_c('div',{staticClass:"peopel-box-foot-price",class:_vm.user.name ? 'peopel-box-foot-price-color' : ''},[_vm._v(" 品项扣除 ")]):_vm._e(),(_vm.getOrderData && !_vm.getOrderData.data[_vm.orderDatasIndex].vip && !_vm.getOrderData.data[_vm.orderDatasIndex].member_price_status
          )?_c('div',{staticClass:"peopel-box-foot-price"}):_vm._e(),(_vm.getOrderData && _vm.getOrderData.data[_vm.orderDatasIndex].member_price_status && !_vm.getOrderData.data[_vm.orderDatasIndex].vip
          )?_c('div',{staticClass:"peopel-box-foot-price"}):_vm._e(),_c('button',{staticClass:"peopel-box-foot-btn",on:{"click":_vm.orderSuccess}},[_vm._v("确认")])])]:_vm._e(),_c('button',{staticClass:"peopel-box-close",on:{"click":_vm.orderSuccess}})],2):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"status"},[_c('div',{staticClass:"status-left"},[_c('div',{staticClass:"status-left-item"},[_c('span'),_vm._v(" 点牌 ")]),_c('div',{staticClass:"status-left-item"},[_c('span'),_vm._v(" 轮牌 ")]),_c('div',{staticClass:"status-left-item"},[_c('span'),_vm._v(" 待客 ")]),_c('div',{staticClass:"status-left-item"},[_c('span'),_vm._v(" 休息 ")])])])}]

export { render, staticRenderFns }
<template>
<!-- 时间计时 -->
  <div class="time" :timers="timers" :timersEen="timersEen" :status="status">
    <div class="time-run" v-if="status">
      <span :style="{ color: color ? 'red' : '' }">服务计时：</span>
      <span>{{ timeFun() }}</span>
    </div>
    <div class="time-run" v-if="!status">
      <span>服务计时：</span>
      <span>{{timeEnd()}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "timer",
  props: ["timers", "status","timersEen"],
  data() {
    return {
      time: new Date().getTime(),
      color: false,
    };
  },
  created() {
    let _this = this;
    setInterval(() => {
      _this.time = new Date().getTime();
    }, 1000);
  },
  methods: {
    timeEnd() {
      if(!this.timersEen){
        return false;
      }
      let i = this.timers; //开始时间
      let e = this.timersEen; //结束时间
      let num = Math.floor((e - i) / 1000); //秒
      let s = Math.floor(num % 60); //秒
      let m = Math.floor((num / 60) % 60); //分钟
      let t = Math.floor((num / 60 / 60)); //时
      let d = `${t < 10 ? "0" + t : t}时${m < 10 ? "0" + m : m}分${
        s < 10 ? "0" + s : s
      }秒`; //计时
    return d 

    },
    timeFun() {
      if(!this.timers){
        return false;
      }
      let i = this.time; //当前时间
      let e = this.timers; //父级开始时间
      let nums = 120 * 60 * 1000; //倒计时默认120分钟
      let num = Math.floor((i - e) / 1000); //秒
      num =
        Math.floor(num / 60) > 120
          ? Math.floor((i - e) / 1000)
          : Math.floor((e + nums - i) / 1000);

      let ms = Math.floor(num / 60); //总的分钟
      let s = Math.floor(num % 60); //秒
      let m = Math.floor((num / 60) % 60); //分钟
      let t = Math.floor((num / 60 / 60)); //时

      let d = `${t < 10 ? "0" + t : t}时${m < 10 ? "0" + m : m}分${
        s < 10 ? "0" + s : s
      }秒`; //计时
      let d1 = `${ms < 10 ? "0" + ms : ms}分${s < 10 ? "0" + s : s}秒`; //倒计时

      this.color = ms > 120 ? true : false;

      return ms > 120 ? d : d1;
    },
  },
  computed: {
    t() {
      return this.timeFun();
    }
  },
};
</script>

<template>
  <div class="v" :class="shows ? 'v-active' : ''" @click.self="hides">
    <div class="wei" :class="shows ? 'wei-active' : ''" @click.self="hides">
      <div class="wei-item">
        <button @click="writeoff">微店核销</button>
        <button @click="delOrder" v-if="orders.edit">删除单据</button>
      </div>
    </div>
    <div class="weiorder" :class="show ? 'weiorder-active' : ''">
      <button class="weiorder-close" @click="hideWeiOrder"></button>
      <div class="weiorder-title">
        微店订单核销
      </div>
      <div class="weiorder-err" v-show="weitype == 1">
        <div class="weiorder-err-img">
          <img
            src="https://static.bokao2o.com/wisdomCashier/images/Def_Icon_Warning.png"
            alt=""
          />
        </div>
        <div class="weiorder-err-title">
          核销失败
        </div>
        <div class="weiorder-err-info">
          没有查询到对应订单
        </div>
        <div class="weiorder-err-button">
          <button @click="weitype = 0">重新核销</button>
        </div>
      </div>
      <div class="weiorder-cont" v-show="weitype == 0">
        <div class="weiorder-cont-info">
          请输入核销码或使用扫码盒
        </div>
        <div class="weiorder-cont-input">
          <el-input v-model="input" placeholder="请输入内容"></el-input>
        </div>
        <div class="weiorder-cont-input" style="margin-top:30px">
          <button @click="weiOrderType">确认</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "wei",
  data() {
    return {
      show: false,
      weitype: 0,
      input: "",
      orderData: {
        id: "",
        order: "",
        name: "",
        data: [],
        pirce: "",
        other: "",
        time: "",
        start_time: "",
        end_time: "",
        time_type: 0,
        source: "",
        customer: "",
        customerNum: "",
        status: 1,
        gerge: false,
        gergrStatus: false,
      },
    };
  },
  methods: {
    writeoff(){
      // this.show = !this.show;
       this.$router.replace({
        path:"/writeoff"
      })
    },
    hides() {
      this.$store.commit("weiShowS", false);
    },
    delOrder() {
      let _this = this;
      this.$confirm("是否作废该订单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios({
            href: "/api/app/meiye/payment/car_del",
            data: { id: _this.orders.id },
          }).then((res) => {
            console.log(_this.orders.id, res);
            _this.$store.commit("billShowS", false);
            _this.$store.commit("weiShowS", false);
            _this.$store.commit("SetLoadOrder", _this.orderData);
            _this.getLIst();
            _this.$message({
              type: "success",
              message: "删除成功!",
            });
          });
        })
        .catch(() => {});
    },
    getLIst() {
      let _this = this;
      this.$axios({
        href: "/api/app/meiye/payment/car_store",
      }).then((res) => {
        // console.log(res);
        let len = res.data.length;
        let d = res.data;
        for (let i = 0; i < len; i++) {
          d[i].list = [];
          d[i].list = d[i].products.concat(d[i].projects); //合并商品和项目（两个是一样的）
        }
        _this.$store.commit("setDataOrder", d);
      });
    },
    weiOrderType() {
      if (!this.input) {
        this.$message({
          message: "请填写核销码",
          type: "warning",
        });
        return false;
      }
      if (this.input == 1) {
        this.weitype = 1;
      }
    },
    hideWeiOrder() {
      this.show = !this.show;
      this.weitype = 0;
      this.input = "";
    },
  },
  computed: {
    shows: {
      get() {
        return this.$store.state.order.wei;
      },
      set() {
        return this.$store.state.order.wei;
      },
    },
    orders: {
      get() {
        return this.$store.state.order.loadorder;
      },
      set() {
        return this.$store.state.order.loadorder;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.v {
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
  z-index: -11;
  transition: background 0.5s;
  //   background: red;
}
.v-active {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  opacity: 1;
  background: rgba(0, 0, 0, 0.5);
  z-index: 3000;
}
.wei {
  position: absolute;
  top: 110px;
  right: -200px;
  width: 155px;
  min-height: 70px;
  box-shadow: rgba(51, 51, 51, 0.3) 0px 0px 8px 0px;
  background: #fff;
  border-radius: 8px;
  transition: right 0.5s;
  &-item {
    padding: 5px 20px;
    box-sizing: border-box;
  }
  button {
    display: block;
    width: 100%;
    height: 60px;
    line-height: 60px;
    font-size: 15px;
    font-family: PingFangSC-Semibold;
    color: #28282d;
    text-align: left;
    border-bottom: 0.5px solid rgba(154, 154, 154, 0.5);
    padding-left: 45px;
    border: none;
    outline: none;
    box-sizing: border-box;
    cursor: pointer;
    // padding: 0;
  }
  button:first-child {
    background: #fff
      url(https://static.bokao2o.com/wisdomDesk/images/Def_Verif_BKStore.png)
      8px center / 28px no-repeat;
  }
  button:nth-child(2) {
    background: #fff
      url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Delete.png) 8px
      center / 28px no-repeat;
  }
  button:nth-child(even) {
    border-top: 1px solid #ccc;
  }
}
.wei-active {
  right: 40px;
}

.weiorder {
  position: absolute;
  border-radius: 6px;
  left: 0;
  right: 0;
  top: -50%;
  margin: auto;
  width: 420px;
  min-height: 250px;
  z-index: -1;
  transition: top 0.5s;
  &-close {
    position: absolute;
    top: 10px;
    right: 5px;
    width: 40px;
    height: 40px;
    background: url(https://static.bokao2o.com/wisdomCashier/images/Icon_X_Gray.png)
      center / 28px 28px no-repeat;
    cursor: pointer;
    border: none;
    outline: none;
    box-sizing: border-box;
    padding: 0;
  }
  &-title {
    line-height: 40px;
    font-family: PingFangSC-Semibold;
    font-size: 17px;
    color: #333333;
    text-align: center;
    padding: 10px 0 5px 0;
  }
  &-cont {
    padding: 0 20px 30px 20px;
    text-align: center;
    &-info {
      font-size: 14px;
      color: #999;
      line-height: 25px;
    }
    &-input {
      margin-top: 20px;
      font-size: 18px;
      font-family: PingFangSC-Semibold;
      color: #28282d;
      border-radius: 6px;
      button {
        width: 100%;
        line-height: 44px;
        height: 44px;
        padding: 0 10px;
        line-height: 40px;
        height: 40px;
        background: #28282d;
        border-radius: 5px;
        font-size: 15px;
        color: #fff;
        border: none;
        outline: none;
        box-sizing: border-box;
        cursor: pointer;
      }
    }
  }
}

.weiorder-err {
  text-align: center;
  padding: 0 20px 30px 20px;
  line-height: 25px;
  &-img {
    margin-top: 15px;
    height: 48px;
    text-align: center;
    box-sizing: border-box;
    line-height: 25px;
    img {
      width: 48px;
      height: 48px;
    }
  }
  &-title {
    margin-top: 20px;
    line-height: 30px;
    font-size: 18px;
    color: #28282d;
    font-family: PingFangSC-Medium;
  }
  &-info {
    font-size: 15px;
    color: #8a8a8a;
  }
  &-button {
    margin-top: 35px;
    display: flex;
    button {
      background: #e7480a;
      color: #fff;
      flex: 1;
      border-radius: 6px;
      line-height: 44px;
      font-size: 16px;
      cursor: pointer;
      border: none;
      outline: none;
      box-sizing: border-box;
    }
  }
}

.weiorder-active {
  top: 20%;
  width: 420px;
  max-height: 80%;
  border-radius: 6px;
  background: rgb(255, 255, 255);
  overflow: auto;
  box-shadow: rgb(51, 51, 51) 0px 0px 20px 0px;
  transition: top 0.5s;
}
</style>

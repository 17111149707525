<template>
  <!-- <div
    class="peopel"
    :class="shows ? 'peopel-active' : ''"
    :orderDatasIndex="orderDatasIndex"
    @click.self="hide(0)"
  > -->

  <div
    class="peopel"
    :class="shows ? 'peopel-active' : ''"
    :orderDatasIndex="orderDatasIndex"
    @click.self="orderSuccess"
  >
    <div
      class="peopel-box"
      :class="shows ? 'peopel-box-active' : ''"
      v-if="getOrderData.data.length"
    >
      <div class="peopel-box-head">
        <div class="peopel-box-head-img" @click="more" v-if="0">
          <img
            v-show="!mores"
            src="https://static.bokao2o.com/wisdomDesk/images/Def_Icon_OrderAddSer_Job.png"
            alt=""
          />
          <img
            v-show="mores"
            src="https://static.bokao2o.com/wisdomDesk/images/Def_Icon_OrderAddSer_Queue.png"
            alt=""
          />
        </div>
        <div
          class="peopel-box-head-type"
          v-if="getOrderData"
          style="margin-left:10px;"
        >
          <button @click="hide(1)">删除</button>
          <!-- {{getOrderData.data}} -->
          <span v-if="getOrderData.data[orderIndex]">{{
            getOrderData.data[orderIndex].name
          }}</span>
        </div>
        <div class="peopel-box-head-tab" v-if="0">
          <span>服务于：</span>
          <button
            v-for="(item, index) in tab"
            :key="index"
            :class="tabIndex == index ? 'peopel-box-head-tab-btn' : ''"
          >
            {{ item }}
          </button>
        </div>
      </div>
      <div class="peopel-box-cont">
        <div class="peopel-box-cont-box">
          <div
            v-if="!mores"
            class="peopel-box-cont-box-left"
            style="width:100%;max-width:100%;"
          >
            <div class="peopel-box-cont-box-left-tab" style="width:100%;">
              <div
                class="peopel-box-cont-box-left-tab-item"
                @click="itemClick(-1)"
                :class="
                  itemIndex == -1
                    ? 'peopel-box-cont-box-left-tab-item-active'
                    : ''
                "
              >
                全部
              </div>
              <div v-if="item && item.length">
                <div
                  class="peopel-box-cont-box-left-tab-item"
                  @click="itemClick(index)"
                  :class="
                    itemIndex == index
                      ? 'peopel-box-cont-box-left-tab-item-active'
                      : ''
                  "
                  v-for="(item, index) in item"
                  :key="index"
                >
                  {{ item.name }}
                </div>
              </div>
            </div>

            <div
              class="peopel-box-cont-box-left-cont"
              v-if="itemIndex != -1 && !mores"
            >
              <div v-if="item[itemIndex] && item[itemIndex].data.length">
                <div
                  class="peopel-box-cont-box-left-cont-list"
                  v-for="(item, index) in item[itemIndex].data"
                  :key="index"
                  :class="
                    item.cheacks
                      ? 'peopel-box-cont-box-left-cont-list-active'
                      : ''
                  "
                  @click="itemDataIndexClick(index, item)"
                >
                  <div class="peopel-box-cont-box-left-cont-list-img">
                    <img
                      :src="
                        item.photo
                          ? item.photo
                          : 'https://static.bokao2o.com/images/bg200.png?imageView2/1/w/200/h/200/interlace/1/q/100'
                      "
                      :alt="item.name"
                    />
                  </div>
                  <div class="peopel-box-cont-box-left-cont-list-name">
                    <div class="specify">
                      {{ item.name }}
                      <div
                        @click.stop="specify(index, item)"
                        :class="item.specify ? 'specify-tags' : 'specify-tag'"
                      >
                        <span v-if="!item.specify">非指定</span>
                        <span v-if="item.specify">指定</span>
                        <i class="el-icon-sort"></i>
                      </div>
                    </div>
                    <div class="" style="margin-top:10px;">
                      工号：{{ item.workID }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="peopel-box-cont-box-left-cont"
              v-if="itemIndex == -1 && !mores"
            >
              <div v-if="itemArr && itemArr.length">
                <div
                  class="peopel-box-cont-box-left-cont-list"
                  style="width:231px;"
                  v-for="(item, index) in itemArr"
                  :key="index"
                  :data-index="index"
                  :class="
                    item.cheacks
                      ? 'peopel-box-cont-box-left-cont-list-active'
                      : ''
                  "
                  @click="itemDataIndexClick(index, item)"
                >
                  <div class="peopel-box-cont-box-left-cont-list-img">
                    <img
                      :src="
                        item.photo
                          ? item.photo
                          : 'https://static.bokao2o.com/images/bg200.png?imageView2/1/w/200/h/200/interlace/1/q/100'
                      "
                      :alt="item.name"
                    />
                  </div>
                  <div class="peopel-box-cont-box-left-cont-list-name">
                    <div class="specify">
                      {{ item.name }}
                      <div
                        @click.stop="specify(index, item)"
                        :class="item.specify ? 'specify-tags' : 'specify-tag'"
                      >
                        <span v-if="!item.specify">非指定</span>
                        <span v-if="item.specify">指定</span>
                        <i class="el-icon-sort"></i>
                      </div>
                    </div>
                    <div class="" style="margin-top:10px;">
                      工号：{{ item.workID }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="mores" class="peopel-box-cont-box-left">
            <div class="status">
              <div class="status-left">
                <div class="status-left-item">
                  <span></span>
                  点牌
                </div>
                <div class="status-left-item">
                  <span></span>
                  轮牌
                </div>
                <div class="status-left-item">
                  <span></span>
                  待客
                </div>
                <div class="status-left-item">
                  <span></span>
                  休息
                </div>
              </div>
            </div>
            <div class="peopel-box-cont-box-left-tab">
              <div v-if="typeArr[typeArrIndex] && typeArr[typeArrIndex].tabName.length">
                <div
                  class="peopel-box-cont-box-left-tab-item"
                  @click="itemClick(index)"
                  :class="
                    itemIndex == index
                      ? 'peopel-box-cont-box-left-tab-item-active'
                      : ''
                  "
                  v-for="(item, index) in typeArr[typeArrIndex].tabName"
                  :key="index"
                >
                  {{ item }}
                </div>
              </div>
            </div>

            <!-- <div
              class="peopel-box-cont-box-left-cont"
              v-if="itemIndex != -1 && mores"
            >
              <div
                class="peopel-box-cont-box-left-cont-list"
                v-for="(item, index) in typeArr[typeArrIndex].data"
                :key="index"
                :class="
                  item.cheacks
                    ? 'peopel-box-cont-box-left-cont-list-active'
                    : ''
                "
                @click="itemDataIndexClick(index, item)"
              >
                <div class="peopel-box-cont-box-left-cont-list-img">
                  <img
                    :src="
                      item.img
                        ? item.img
                        : 'https://static.bokao2o.com/images/bg200.png?imageView2/1/w/200/h/200/interlace/1/q/100'
                    "
                    :alt="item.name"
                  />
                </div>
                <div class="peopel-box-cont-box-left-cont-list-name">
                  <div class="">{{ item.name }}</div>
                  <div class="">{{ item.num }}</div>
                </div>
              </div>
            </div> -->
          </div>

          <div class="peopel-box-cont-box-right" v-if="0">
            <div v-if="typeArr && typeArr.length">
              <div
                class="peopel-box-cont-box-right-item"
                @click="typeArrIndexClick(index)"
                v-for="(item, index) in typeArr"
                :key="index"
                :class="
                  typeArrIndex == index
                    ? 'peopel-box-cont-box-right-item-active'
                    : ''
                "
              >
                <div
                  class="peopel-box-cont-box-right-item-name"
                  v-if="!item.peopel"
                >
                  {{ item.name }} <span>(选填)</span>
                </div>
                <div
                  class="peopel-box-cont-box-right-item-name"
                  v-if="item.peopel"
                >
                  <div class="peopel-box-cont-box-right-item-name-text">
                    {{ item.peopel }}
                  </div>
                  <div class="peopel-box-cont-box-right-item-name-input" v-if="peopelArr && peopelArr.length">
                    <el-select v-model="item.type" placeholder="请选择">
                      <el-option
                        v-for="items in peopelArr"
                        :key="items"
                        :label="items"
                        :value="items"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div v-if="mores" class="peopel-box-cont-box">2</div> -->
      </div>
      <template v-if="getOrderData && getOrderData.data && getOrderData.data[orderIndex] && getOrderData.data[orderDatasIndex]">
        <div class="peopel-box-foot">
          <div class="peopel-box-foot-left">
            <span>数量：</span>
            <!-- <el-input-number
              v-model="orderNum"
              @change="handleChange"
              :min="1"
              :max="999"
            ></el-input-number> -->
            <el-input-number
              key="2"
              v-model="getOrderData.data[orderIndex].num"
              @change="handleChange"
              :min="1"
              :max="getOrderData.data[orderDatasIndex].vip ? getOrderData.data[orderDatasIndex].balance_count : 999999"
              :step="1"
              label="描述文字"
            ></el-input-number>
          </div>
          <div
            class="peopel-box-foot-price"
            v-if="getOrderData.data[orderDatasIndex].vip"
            :class="user.name ? 'peopel-box-foot-price-color' : ''"
          >
            品项扣除
          </div>
          <div
            class="peopel-box-foot-price"
            v-if="getOrderData && !getOrderData.data[orderDatasIndex].vip && !getOrderData.data[orderDatasIndex].member_price_status
            "
          >
            <!-- 1￥
            {{
              orderDatasIndex - 0 > -1
                ? getOrderData.data[orderDatasIndex].pirce
                : getOrderData.data[getOrderData.data.length - 1].pirce
            }} -->
          </div>
          <div
            class="peopel-box-foot-price"
            v-if="getOrderData && getOrderData.data[orderDatasIndex].member_price_status && !getOrderData.data[orderDatasIndex].vip
            "
          >
            <!-- 2￥
            {{
              orderDatasIndex - 0 > -1
                ? getOrderData.data[orderDatasIndex].member_price
                : getOrderData.data[getOrderData.data.length - 1].member_price
            }} -->
          </div>
          <button class="peopel-box-foot-btn" @click="orderSuccess">确认</button>
        </div>
      </template>
      <!-- <button class="peopel-box-close" @click="hide(0)"></button> -->
      <button class="peopel-box-close" @click="orderSuccess"></button>
    </div>
  </div>
</template>

<script>
export default {
  name: "personnel",
  props: ["orderDatasIndex", "vipData"],
  data() {
    return {
      mores: false, //切换显示所工作人员
      tab: ["客A", "客B", "客C"],
      tabIndex: 0,
      item: [],
      itemIndex: -1, //tab下标
      itemArr: [], //全部工作人员
      itemDataIndex: -1, //工作人员下标
      itemDataName: "", //工作人员姓名
      typeArr: [
        {
          name: "发型师",
          type: "指定",
          peopel: "",
          img: "",
          tabName: ["创意总监", "首席总监"],
          data: [],
        },
        {
          name: "助理",
          type: "指定",
          peopel: "",
          img: "",
          tabName: ["A级助理", "B级助理"],
          data: [],
        },
        {
          name: "染发技师",
          type: "指定",
          peopel: "",
          img: "",
          tabName: ["A级染发师", "B级染发师"],
          data: [],
        },
        {
          name: "美容师",
          type: "指定",
          peopel: "",
          img: "",
          tabName: ["美容师"],
          data: [],
        },
      ], //右侧类型tab
      typeArrIndex: 0, //右侧类型tab下标
      peopelArr: ["指定", "轮牌"],
    };
  },
  methods: {
    hide(obj) {
      console.log('orderDatasIndex', this.orderDatasIndex, obj);
      /* 删除服务项目 */
      let d = this.getOrderData;
      if (obj) {
        this.$confirm("此操作将" + this.orderName + "删除, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            d.data.splice(this.orderDatasIndex, 1);
          })
          .catch(() => {
            this.$store.commit("peopelStatusShow", true); //恢复选择工作人员界面
          });
        this.$store.commit("peopelStatusShow", false); //关闭选择工作人员界面
        this.$store.commit("SetLoadOrder", d);

        return false;
      }
      this.$store.commit("peopelStatusShow", false); //关闭选择工作人员界面
    },
    /* 切换工作人员显示 */
    more() {
      this.mores = !this.mores;
      if (this.mores) {
        this.itemIndex = 0;
      } else {
        this.itemIndex = -1;
      }
    },
    /* 数量加减 */
    handleChange(value, value1) {
      // this.orderNum = value;
      let d = this.getOrderData;
      // console.log(d.data[this.orderDatasIndex].balance_count);
      /* 同步会员项目数量 */
      if (d) {
        let num =
          JSON.stringify(d.data[this.orderDatasIndex].balance_count) - 0;
        if (d.data[this.orderDatasIndex].vip) {
          if (
            value > d.data[this.orderDatasIndex].balance_count ||
            !d.data[this.orderDatasIndex].balance_count
          ) {
            this.$message.error("剩余次数为已用完，请更换其他或者重新购买");
            d.data[this.orderDatasIndex].num = num;
            this.setVipData(
              d.data[this.orderDatasIndex].id,
              this.vipData.balance_count,
              true
            );
            // return false;
          } else {
            d.data[this.orderDatasIndex].num = value;
            console.log(34234234234234,value1, value)
            this.setVipData(d.data[this.orderDatasIndex].id, value1 - value);
          }
          // d.data[this.orderDatasIndex].balance_count += (value1 - value1);
          // alert(1)
        } else {
          d.data[this.orderDatasIndex].num = value;
        }
      } else {
        d.data[d.data.length - 1].num = value;
      }
      //  console.log(d.data[this.orderDatasIndex]);
      // if (d) {
      //   d.data[this.orderDatasIndex].num = value;
      // } else {
      //   d.data[d.data.length - 1].num = value;
      // }
      this.$store.commit("SetLoadOrder", d);
      console.log(
        d.data[this.orderDatasIndex].balance_count,
        value,
        this.getNum(d.data[this.orderDatasIndex].id)
      );
    },
    /* 获取项目剩余次数 */
    getNum(id) {
      let d = this.user.vip||[];
      let len = d.length;
      for (let i = 0; i < len; i++) {
        if (id == d[i].id) {
          return d[i].balance_count;
        }
      }
    },
    /* 同步会员项目数量 */
    setVipData(id, num, type) {
      let order = this.user;
      let d = order.vip;
      let len = d.length;

      for (let i = 0; i < len; i++) {
        if (id == d[i].id) {
          if (type) {
            d[i].balance_count = 0;
          } else {
            console.log(num,"是打法胜多负少的")
            d[i].balance_count = (num +  d[i].balance_count) > 0 ?(num +  d[i].balance_count):(num +  d[i].balance_count)*-1;
          }
        }
      }
      this.$store.commit("setUser", order);
    },
    /* 设置会员项目数量 数字加减的时候不同步 */
    setNum(id, num,) {
      let order = this.user;
      let d = order.vip || [];
      let len = d.length;

      for (let i = 0; i < len; i++) {
        if (id == d[i].id) {
           d[i].balance_count = num;
        }
      }
      this.$store.commit("setUser", order);
    },
    /* 切换tab选项 */
    itemClick(index) {
      console.log(index);
      this.itemIndex = index;
      // this.itemDataIndex = -1;
    },
    /* 切换指定非指定 */
    specify(index, obj) {
      let arr = [];
      let data = JSON.parse(JSON.stringify(this.item));

      let items = data;
      let len = items.length;

      for (let i = 0; i < len; i++) {
        for (let k = 0; k < items[i].data.length; k++) {
          if (obj.id == items[i].data[k].id) {
            items[i].data[k].specify = !items[i].data[k].specify;
          }
          arr.push(items[i].data[k]);
        }
      }
      this.itemArr = arr;
      this.item = data;
    },
    /* 选中工作人员 index, obj*/
    itemDataIndexClick(index, obj) {
      let arr = [];
      let data = JSON.parse(JSON.stringify(this.item));

      let items = data;
      let len = items.length;

      for (let i = 0; i < len; i++) {
        for (let k = 0; k < items[i].data.length; k++) {
          if (obj.id == items[i].data[k].id) {
            items[i].data[k].cheacks = !items[i].data[k].cheacks;
          }
          arr.push(items[i].data[k]);
        }
      }
      this.itemArr = arr;
      this.item = data;

      //  this.item[this.itemIndex].data[index].cheacks = !this.item[this.itemIndex].data[index].cheacks;

      //  obj.cheacks = !obj.cheacks;

      //  this.itemArr = this.itemArr;

      // if (index == this.itemDataIndex) {
      //   this.itemDataIndex = -1;
      //   this.itemDataName = "";
      //   let indexs = this.typeArrIndex;
      //   this.typeArr[indexs].peopel = "";
      //   this.typeArr[indexs].img = "";
      // } else {
      //   this.itemDataIndex = index;
      //   this.itemDataName = obj.name;
      //   let indexs = this.typeArrIndex;
      //   this.typeArr[indexs].peopel = obj.name;
      //   this.typeArr[indexs].id = obj.id;
      //   this.typeArr[indexs].img = obj.img;
      // }
      // console.log(index,obj,this.typeArrIndex);
    },
    /* 获取全部工作人员的数据 */
    getItemArr() {
      let data = this.item;
      let len = data.length;
      for (let i = 0; i < len; i++) {
        for (let k = 0; k < data[i].data.length; k++) {
          // console.log(data[i].data[k])
          data[i].data[k].cheacks = false;
          data[i].data[k].specify = 0; //默认非指定
          this.itemArr.push(data[i].data[k]);
        }
      }
    },
    /* 选中工作人员 */
    typeArrIndexClick(index) {
      if (this.mores) {
        this.itemIndex = 0;
      }

      this.typeArrIndex = index;
      let data =
        this.itemIndex != -1 ? this.item[this.itemIndex].data : this.itemArr;
      let len = data.length;
      /* 点击显示选中的工作人员 */
      if (this.typeArr[index].peopel) {
        for (let i = 0; i < len; i++) {
          if (this.typeArr[index].peopel == data[i].name) {
            this.itemDataName = data[i].name;
            this.itemDataIndex = i;
          }
        }
      } else {
        this.itemDataName = "";
        this.itemDataIndex = -1;
      }
    },
    orderSuccess() {
      // console.log(this.orderIndex)
      let len = this.itemArr.length;
      let d = this.getOrderData;
      // let peopelData = JSON.parse(JSON.stringify(this.typeArr));

      d.data[this.orderDatasIndex].people = [];

      for (let l = 0; l < len; l++) {
        if (this.itemArr[l].cheacks) {
          d.data[this.orderDatasIndex].people.push(this.itemArr[l]);
          this.itemArr[l].cheacks = false;
        }
      }

      // for (let i = 0; i < peopelData.length; i++) {
      //   if (peopelData[i].peopel) {
      //     peopelData[i].index = i;
      //     // d.data[this.orderDatasIndex].people.push(peopelData[i]);

      //     for(let l = 0; l < len; l++){
      //        console.log(l)
      //       if(this.itemArr[l].cheacks){
      //         console.log(l)
      //         d.data[this.orderDatasIndex].people.push(this.itemArr[l]);
      //       }
      //     }

      //   }
      // }

      this.itemIndex = -1;
      /* 同步会员项目数量 */
      if (this.getNum(d.data[this.orderDatasIndex].id) <= 0) {
        this.setNum(d.data[this.orderDatasIndex].id, 0);
      } else {
        this.setNum(d.data[this.orderDatasIndex].id, d.data[this.orderDatasIndex].balance_count-d.data[this.orderDatasIndex].num);
      }

      // d.start_time = new Date().getTime();
      this.$store.commit("SetLoadOrder", d);
      this.$store.commit("peopelStatusShow", false); //关闭选择工作人员界面
      console.log(d, 11111111,this.getNum(d.data[this.orderDatasIndex].id) );
    },
    /* 显示选中的员工 */
    showWork() {
      console.log('getOrderData', this.getOrderData)
      let d = this.getOrderData;
      let len = d.data[this.orderDatasIndex].people.length;
      console.log(d.data[this.orderDatasIndex].people, 111111111111);
      for (let l = 0; l < len; l++) {
        this.itemDataIndexClick(l, d.data[this.orderDatasIndex].people[l]);
        console.log(l);
      }
    },
  },
  computed: {
    shows: {
      get() {
        return this.$store.state.order.peopelStatus;
      },
      set() {
        return this.$store.state.order.peopelStatus;
      },
    },
    user: {
      get() {
        return this.$store.state.order.user;
      },
      set() {
        return this.$store.state.order.user;
      },
    },
    getOrderData: {
      get() {
        return this.$store.state.order.loadorder;
      },
      set() {
        return this.$store.state.order.loadorder;
      },
    },
    orderNum: {
      get() {
        return this.getOrderData.data[this.orderIndex].num;
      },
      set() {
        return this.getOrderData.data[this.orderIndex].num;
      },
    },
    orderIndex: {
      get() {
        return this.orderDatasIndex;
      },
      set() {
        return this.orderDatasIndex;
      },
    },
    orderName: {
      get() {
        return this.orderIndex
          ? this.getOrderData.data[this.orderIndex].name
          : this.getOrderData.data[this.getOrderData.data.length - 1].name;
      },
      set() {
        return this.orderIndex
          ? this.getOrderData.data[this.orderIndex].name
          : this.getOrderData.data[this.getOrderData.data.length - 1].name;
      },
    },
  },
  watch: {
    shows(obj) {
      if (obj) {
        this.showWork();
      }
    },
  },
  created() {
    let _this = this;
    let time = null;
    if (!sessionStorage.getItem("Staff")) {
      clearInterval(time);
      time = setInterval(() => {
        _this.item = sessionStorage.getItem("Staff") ? JSON.parse(sessionStorage.getItem("Staff")).data : '';
        if (_this.item.length) {
          _this.getItemArr();
          clearInterval(time);
        }
      }, 500);
    } else {
      _this.item = JSON.parse(sessionStorage.getItem("Staff")).data;
      if (_this.item.length) {
        _this.getItemArr();
      }
    }
    //  setInterval(()=>{
    //    _this.showWork();
    //  },300)
  },
};
</script>

<style lang="scss" scoped>
.peopel {
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: -1;
  transition: opacity 0.5s;
  &-box {
    width: 1024px;
    height: 768px;
    border-radius: 6px;
    background: rgb(255, 255, 255);
    overflow: auto;
    box-shadow: rgb(51, 51, 51) 0px 0px 20px 0px;
    top: -200%;
    opacity: 0;
    transition: all 0.8s;

    &-head {
      display: flex;
      height: 34px;
      padding: 35px 20px 17px 0;
      line-height: 32px;
      box-shadow: 0 0 5px 0 rgba(187, 187, 187, 0.5);
      z-index: 10;
      &-img {
        padding: 3px 18px 3px 20px;
        height: 34px;
        border-right: 0.5px solid rgba(220, 220, 220, 0.7);
        margin-right: 15px;
        cursor: pointer;
        img {
          width: 28px;
          height: 28px;
          transition: all 0.5s;
        }
      }
      &-type {
        max-width: 40%;
        min-width: 120px;
        font-family: PingFangSC-Semibold;
        font-size: 15px;
        color: #28282d;
        button {
          background: transparent;
          border: none;
          outline: none;
          box-sizing: border-box;
          cursor: pointer;
          padding: 0;
        }
        button {
          margin-top: 3px;
          margin-right: 8px;
          width: 42px;
          height: 28px;
          padding: 0;
          line-height: 28px;
          border-radius: 3px;
          background: #f44e4e;
          font-size: 13px;
          color: #fff;
        }
      }
      &-tab {
        margin-left: 25px;
        text-align: left;
        font-size: 15px;
        color: #8a8a8a;
        button {
          margin-left: 10px;
          line-height: 20px;
          padding: 6px 20px;
          font-size: 15px;
          color: #28282d;
          border-radius: 6px;
          border: 1px solid rgba(154, 154, 154, 0.5);
          background: #ffffff;
          cursor: pointer;
          outline: none;
          transition: all 0.5s;
        }
        &-btn {
          border: 1px solid #28282d !important;
          background: #28282d !important;
          color: #fff !important;
        }
      }
    }
    &-cont {
      min-height: 597px;
      position: relative;
      box-sizing: border-box;
      &-box {
        position: relative;
        display: flex;
        height: 597px;
        margin-bottom: 20px;
        &-left {
          flex: 1;
          height: 100%;
          background: #f4f4f4;
          max-width: 814px;
          &-tab {
            position: relative;
            width: 814px;
            height: 44px;
            overflow-x: auto;
            overflow-y: hidden;
            white-space: nowrap;
            padding: 10px 0;
            display: flex;
            &-item {
              position: relative;
              display: inline-block;
              text-align: center;
              padding: 0 18px;
              line-height: 44px;
              color: #28282d;
              font-size: 15px;
              cursor: pointer;
            }
            &-item-active {
              position: relative;
            }
            &-item-active::after {
              position: absolute;
              content: "";
              left: 0;
              right: 0;
              bottom: -3px;
              width: 24px;
              height: 2px;
              background: #28282d;
              margin: auto;
            }
          }
          &-cont {
            height: 543px;
            position: relative;
            z-index: 10;
            margin-top: 0px;
            background: #f4f4f4;
            overflow-x: hidden;
            overflow-y: auto;
            padding: 20px 0 20px 20px;
            text-align: left;
            &-list {
              display: inline-flex;
              background: #fff
                url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Check_N.png)
                200px center / 28px no-repeat;
              padding: 17px 40px 17px 15px;
              border-radius: 6px;
              width: 240px;
              height: 74px;
              margin: 0 20px 16px 0;
              cursor: pointer;
              box-sizing: border-box;
              &-img {
                width: 40px;
                height: 40px;
                margin-right: 12px;
                img {
                  width: 40px;
                  height: 40px;
                  border-radius: 50%;
                }
              }
              &-name {
                flex: 1;
                line-height: 20px;
                color: #28282d;
                div:last-child {
                  font-size: 14px;
                  color: #8a8a8a;
                }
              }
            }
            &-list-active {
              background: #fff
                url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Check_S.png)
                200px center / 28px no-repeat;
            }
          }
        }
        &-right {
          height: 597px;
          width: 210px;
          min-width: 210px;
          &-item {
            position: relative;
            padding: 22px 12px 22px 22px;
            line-height: 24px;
            height: 68px;
            overflow: hidden;
            cursor: pointer;
            text-align: left;
            box-sizing: border-box;
            &-name {
              font-family: PingFangSC-Semibold;
              font-size: 15px;
              color: #28282d;
              display: flex;
              span {
                margin-left: 12px;
                font-size: 13px;
                color: #b0b0b0;
              }
              &-text {
                flex: 3;
                font-family: PingFangSC-Medium;
                font-size: 15px;
                color: #47bf7c;
                // text-align: center;
                line-height: 40px;
              }
              &-input {
                border-left: 0.5px solid rgba(154, 154, 154, 0.5);
                padding-left: 10px;
                flex: 3;
              }
            }
          }
          &-item-active::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: 4px;
            background: #28282d;
          }
        }
      }
    }
    &-foot {
      width: 984px;
      height: 40px;
      position: relative;
      z-index: 10;
      display: flex;
      border-top: 0.5px solid rgba(154, 154, 154, 0.5);
      padding: 10px 20px;
      line-height: 40px;
      background: #fff;
      &-left {
        margin-top: 2px;
        height: 36px;
        line-height: 36px;
        font-size: 14px;
        color: #8a8a8a;
      }
      &-price {
        text-align: right;
        flex: 1;
        font-size: 14px;
        color: red;
        vertical-align: middle;
      }
      &-price-color {
        color: rgb(88, 216, 185);
      }
      &-btn {
        margin-left: 30px;
        width: 110px;
        height: 40px;
        line-height: 40px;
        color: #fff;
        font-size: 16px;
        font-family: PingFangSC-Semibold;
        background: #28282d;
        border-radius: 6px;
        cursor: pointer;
        border: none;
        outline: none;
        box-sizing: border-box;
        padding: 0;
      }
    }
    &-close {
      position: absolute;
      top: 35px;
      right: 5px;
      width: 40px;
      height: 40px;
      z-index: 10;
      background: url(https://static.bokao2o.com/wisdomCashier/images/Icon_X_Gray.png)
        center / 28px 28px no-repeat;
      cursor: pointer;
      border: none;
      outline: none;
      box-sizing: border-box;
    }
  }
  &-box-active {
    position: absolute;
    top: 0%;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    min-height: 200px;
    opacity: 1;
    transition: all 1s;
  }
}
.peopel-active {
  width: 100vw;
  height: 100vh;
  opacity: 1;
  z-index: 3000;
  transition: all 0.5;
}
</style>

<style>
.peopel .el-input-number__decrease,
.el-input-number__increase {
  position: absolute;
  z-index: 1;
  top: 1px;
  width: 40px;
  height: auto;
  text-align: center;
  background: #ffffff;
  color: #000000;
  cursor: pointer;
  /* font-size: 25px; */
}
.peopel .el-input-number__increase {
  border: none;
}
.peopel .el-input__inner {
  border: none;
  background-color: #f4f4f4 !important;
}
</style>
<style lang="scss" scoped>
.status {
  position: absolute;
  top: 15px;
  right: 213px;
  display: flex;
  width: 230px;
  height: 32px;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  border-radius: 5px;
  overflow: hidden;
  &-left {
    display: flex;
    flex: 1;
    padding-left: 12px;
    &-item {
      flex: 1;
      position: relative;
      padding-left: 5px;
      line-height: 32px;
      font-size: 12px;
      span {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 50%;
      }
    }
    &-item:nth-of-type(1) span {
      background: rgb(237, 179, 57);
    }
    &-item:nth-of-type(2) span {
      background: rgb(244, 78, 78);
    }
    &-item:nth-of-type(3) span {
      background: rgb(71, 191, 124);
    }
    &-item:nth-of-type(4) span {
      background: rgb(221, 221, 221);
    }
  }
  &-line {
    position: absolute;
    right: 36px;
    top: 6px;
    height: 20px;
    content: "";
    width: 1px;
    background: #fff;
  }
  &-right {
    width: 36px;
    height: 32px;
    background: transparent
      url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Refresh_White.png)
      center / 26px no-repeat;
  }
  &-right-active {
    transition: all 0.7s;
    transform: rotate(360deg);
  }
}
.specify {
  position: relative;
  &-tag {
    position: absolute;
    top: -5px;
    right: 0;
    border-radius: 15px;
    background: #8088a8;
    font-size: 14px;
    font-family: Microsoft YaHei, Microsoft YaHei-Normal;
    font-weight: Normal;
    padding: 2px 5px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s;
    color: #ffffff !important;
  }
  &-tags {
    position: absolute;
    top: -5px;
    right: 0;
    border-radius: 15px;
    background: #f57665;
    font-size: 14px;
    font-family: Microsoft YaHei, Microsoft YaHei-Normal;
    font-weight: Normal;
    padding: 2px 5px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s;
    color: #ffffff !important;
  }
}
</style>
